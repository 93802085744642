// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Inline$Pos from "../../resources/layout-and-structure/Inline.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Navigation$Pos from "../../primitives/Navigation.bs.js";
import * as LegacyRouter$Pos from "../../core/LegacyRouter.bs.js";
import * as CatalogProductEditForm$Pos from "./CatalogProductEditForm.bs.js";
import * as CatalogVariantEditUrlState$Pos from "./CatalogVariantEditUrlState.bs.js";
import * as CatalogProductEditFormMutations$Pos from "./CatalogProductEditFormMutations.bs.js";

function CatalogProductEditFormPageActions(Props) {
  var editionMode = Props.editionMode;
  var updateProduct = CatalogProductEditFormMutations$Pos.useUpdateProduct(undefined);
  var navigate = Navigation$Pos.useNavigate(undefined);
  var onPressNextStep = React.useCallback((function (param) {
          return function (state) {
            Curry._3(navigate, undefined, Caml_option.some(CatalogVariantEditUrlState$Pos.CreateVariantFromProduct.encode(state)), LegacyRouter$Pos.routeToPathname(/* Catalog */2) + "/create");
            return Future.value({
                        TAG: /* Ok */0,
                        _0: undefined
                      });
          };
        }), []);
  return React.createElement(Inline$Pos.make, {
              children: editionMode ? React.createElement(CatalogProductEditForm$Pos.SubmitButton.make, {
                      variation: "success",
                      size: "medium",
                      onSubmit: updateProduct,
                      text: Intl$Pos.t("Save")
                    }) : React.createElement(CatalogProductEditForm$Pos.SubmitButton.make, {
                      variation: "success",
                      size: "medium",
                      onSubmit: onPressNextStep,
                      text: Intl$Pos.t("Continue")
                    }),
              space: "small"
            });
}

var make = React.memo(CatalogProductEditFormPageActions);

var Mutations;

export {
  Mutations ,
  make ,
}
/* make Not a pure module */
