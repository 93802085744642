// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Select$Pos from "../../resources/selection-and-input/Select.bs.js";
import * as FuturePromise from "rescript-future/src/FuturePromise.bs.js";
import * as Client from "@apollo/client";
import * as OverlayTriggerView$Pos from "../../resources/overlays/OverlayTriggerView.bs.js";
import * as ReasonMLCommunity__ApolloClient from "rescript-apollo-client/src/ReasonMLCommunity__ApolloClient.bs.js";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";

function arrayKeepUniqueBy(collection, predicate) {
  return Belt_Array.keepWithIndex(collection, (function (current, index) {
                return Belt_Array.getIndexBy(collection, (function (element) {
                              return Curry._2(predicate, element, current);
                            })) === index;
              }));
}

var Raw = {};

var query = Client.gql(["query PricesQuery($after: String)  {\nprices(first: 50, after: $after)  {\n__typename  \npageInfo  {\n__typename  \nendCursor  \nhasNextPage  \n}\n\nedges  {\n__typename  \nnode  {\n__typename  \nid  \nname  \nenableByDefault  \n}\n\n}\n\n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.prices;
  var value$2 = value$1.pageInfo;
  var value$3 = value$2.endCursor;
  var value$4 = value$2.hasNextPage;
  var value$5 = value$1.edges;
  return {
          prices: {
            __typename: value$1.__typename,
            pageInfo: {
              __typename: value$2.__typename,
              endCursor: !(value$3 == null) ? value$3 : undefined,
              hasNextPage: !(value$4 == null) ? value$4 : undefined
            },
            edges: Js_array.map((function (value) {
                    var value$1 = value.node;
                    return {
                            __typename: value.__typename,
                            node: {
                              __typename: value$1.__typename,
                              id: value$1.id,
                              name: value$1.name,
                              enableByDefault: value$1.enableByDefault
                            }
                          };
                  }), value$5)
          }
        };
}

function serialize(value) {
  var value$1 = value.prices;
  var value$2 = value$1.edges;
  var edges = Js_array.map((function (value) {
          var value$1 = value.node;
          var value$2 = value$1.enableByDefault;
          var value$3 = value$1.name;
          var value$4 = value$1.id;
          var value$5 = value$1.__typename;
          var node = {
            __typename: value$5,
            id: value$4,
            name: value$3,
            enableByDefault: value$2
          };
          var value$6 = value.__typename;
          return {
                  __typename: value$6,
                  node: node
                };
        }), value$2);
  var value$3 = value$1.pageInfo;
  var value$4 = value$3.hasNextPage;
  var hasNextPage = value$4 !== undefined ? value$4 : null;
  var value$5 = value$3.endCursor;
  var endCursor = value$5 !== undefined ? value$5 : null;
  var value$6 = value$3.__typename;
  var pageInfo = {
    __typename: value$6,
    endCursor: endCursor,
    hasNextPage: hasNextPage
  };
  var value$7 = value$1.__typename;
  var prices = {
    __typename: value$7,
    pageInfo: pageInfo,
    edges: edges
  };
  return {
          prices: prices
        };
}

function serializeVariables(inp) {
  var a = inp.after;
  return {
          after: a !== undefined ? a : undefined
        };
}

function makeVariables(after, param) {
  return {
          after: after
        };
}

function makeDefaultVariables(param) {
  return {
          after: undefined
        };
}

var PricesQuery_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  makeDefaultVariables: makeDefaultVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var PricesQuery_refetchQueryDescription = include.refetchQueryDescription;

var PricesQuery_use = include.use;

var PricesQuery_useLazy = include.useLazy;

var PricesQuery_useLazyWithVariables = include.useLazyWithVariables;

var PricesQuery = {
  PricesQuery_inner: PricesQuery_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  makeDefaultVariables: makeDefaultVariables,
  refetchQueryDescription: PricesQuery_refetchQueryDescription,
  use: PricesQuery_use,
  useLazy: PricesQuery_useLazy,
  useLazyWithVariables: PricesQuery_useLazyWithVariables
};

function makePriceNamesDataset(prices) {
  var uniquePriceNames = arrayKeepUniqueBy(Belt_Array.map(prices, (function (param) {
              return param.node.name;
            })), (function (a, b) {
          return a === b;
        }));
  var defaultPriceName = Belt_Array.reduce(prices, [
          undefined,
          0
        ], (function (acc, param) {
            var price = param.node;
            var occurrences = Belt_Array.keep(prices, (function (param) {
                    var current = param.node;
                    if (current.name === price.name && current.enableByDefault) {
                      return price.enableByDefault;
                    } else {
                      return false;
                    }
                  })).length;
            if (occurrences > acc[1]) {
              return [
                      price.name,
                      occurrences
                    ];
            } else {
              return acc;
            }
          }))[0];
  return {
          priceNames: uniquePriceNames,
          defaultPriceName: defaultPriceName
        };
}

function runScanPrices(pricesFetch, cursor, dataOpt, onDone, param) {
  var data = dataOpt !== undefined ? dataOpt : [];
  Future.mapOk(FuturePromise.fromPromise(Curry._1(pricesFetch, cursor)), undefined, (function (response) {
          if (response.TAG !== /* Ok */0) {
            return Curry._1(onDone, /* Error */1);
          }
          var match = response._0;
          var match$1 = match.data.prices;
          var match$2 = match$1.pageInfo;
          var match$3 = match$2.hasNextPage;
          if (match$3 !== undefined && match$3) {
            if (match.error !== undefined) {
              return Curry._1(onDone, /* Error */1);
            } else {
              return runScanPrices(pricesFetch, match$2.endCursor, Belt_Array.concat(data, match$1.edges), onDone, undefined);
            }
          }
          if (match.error !== undefined) {
            return Curry._1(onDone, /* Error */1);
          } else {
            return Curry._1(onDone, /* Success */{
                        _0: Belt_Array.concat(data, match$1.edges)
                      });
          }
        }));
}

function PromotionEditPriceNameSelect(Props) {
  var requiredOpt = Props.required;
  var errorMessage = Props.errorMessage;
  var value = Props.value;
  var onChange = Props.onChange;
  var required = requiredOpt !== undefined ? requiredOpt : false;
  var apolloClient = ReasonMLCommunity__ApolloClient.React.useApolloClient(undefined);
  var match = React.useState(function () {
        return /* Loading */0;
      });
  var setStatus = match[1];
  var status = match[0];
  React.useEffect((function () {
          var pricesFetch = function (after) {
            return Curry._6(apolloClient.rescript_query, {
                        query: query,
                        Raw: Raw,
                        parse: parse,
                        serialize: serialize,
                        serializeVariables: serializeVariables
                      }, undefined, undefined, /* NetworkOnly */2, undefined, {
                        after: after
                      });
          };
          runScanPrices(pricesFetch, undefined, undefined, (function (result) {
                  Curry._1(setStatus, (function (param) {
                          return result;
                        }));
                }), undefined);
        }), []);
  var match$1 = React.useMemo((function () {
          if (typeof status === "number") {
            return [
                    [],
                    undefined
                  ];
          }
          var result = makePriceNamesDataset(status._0);
          return [
                  result.priceNames,
                  result.defaultPriceName
                ];
        }), [status]);
  var defaultPriceName = match$1[1];
  var priceNames = match$1[0];
  React.useEffect((function () {
          if (value === "") {
            if (defaultPriceName !== undefined) {
              Curry._1(onChange, defaultPriceName);
            }
            
          }
          
        }), [defaultPriceName]);
  var renderTriggerView = function (children, param, hovered, active, focused) {
    return React.createElement(OverlayTriggerView$Pos.make, {
                children: children,
                preset: {
                  NAME: "inputField",
                  VAL: {
                    required: required,
                    errorMessage: errorMessage
                  }
                },
                label: Intl$Pos.t("Price list"),
                hovered: hovered,
                active: active,
                focused: focused,
                disabled: priceNames.length === 0
              });
  };
  var items = Belt_Array.map(priceNames, (function (priceName) {
          return {
                  key: priceName,
                  value: priceName,
                  label: priceName
                };
        }));
  return React.createElement(Select$Pos.make, {
              placeholder: priceNames.length !== 0 ? Intl$Pos.t("Select a price list") : Intl$Pos.t("Loading..."),
              disabled: priceNames.length === 0,
              sections: [{
                  items: items,
                  title: Intl$Pos.t("Options")
                }],
              preset: "filter",
              renderTriggerView: renderTriggerView,
              value: value,
              onChange: onChange
            });
}

var make = React.memo(PromotionEditPriceNameSelect);

export {
  arrayKeepUniqueBy ,
  PricesQuery ,
  makePriceNamesDataset ,
  runScanPrices ,
  make ,
}
/* query Not a pure module */
