// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Auth$Pos from "../../bundles/Auth/Auth.bs.js";
import * as Scalar$Pos from "../../core/Scalar.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Client from "@apollo/client";
import * as Navigation$Pos from "../../primitives/Navigation.bs.js";
import * as Placeholder$Pos from "../../resources/layout-and-structure/Placeholder.bs.js";
import * as LegacyRouter$Pos from "../../core/LegacyRouter.bs.js";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";

var Raw = {};

var query = Client.gql(["query variant($id: ID!)  {\nvariant(id: $id)  {\n__typename  \ncku  \n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.variant;
  return {
          variant: !(value$1 == null) ? ({
                __typename: value$1.__typename,
                cku: Scalar$Pos.CKU.parse(value$1.cku)
              }) : undefined
        };
}

function serialize(value) {
  var value$1 = value.variant;
  var variant;
  if (value$1 !== undefined) {
    var value$2 = value$1.cku;
    var value$3 = Scalar$Pos.CKU.serialize(value$2);
    var value$4 = value$1.__typename;
    variant = {
      __typename: value$4,
      cku: value$3
    };
  } else {
    variant = null;
  }
  return {
          variant: variant
        };
}

function serializeVariables(inp) {
  return {
          id: inp.id
        };
}

function makeVariables(id, param) {
  return {
          id: id
        };
}

var Query_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var useLazy = include.useLazy;

var Query_refetchQueryDescription = include.refetchQueryDescription;

var Query_use = include.use;

var Query_useLazyWithVariables = include.useLazyWithVariables;

var Query = {
  Query_inner: Query_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  refetchQueryDescription: Query_refetchQueryDescription,
  use: Query_use,
  useLazy: useLazy,
  useLazyWithVariables: Query_useLazyWithVariables
};

function CatalogVariantByIdPage(Props) {
  var id = Props.id;
  var navigate = Navigation$Pos.useNavigate(undefined);
  var activeShop = Auth$Pos.useActiveShop(undefined);
  var activeShopId = Belt_Option.map(activeShop, (function (shop) {
          return shop.id;
        }));
  var match = Curry.app(useLazy, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var queryResults = match[1];
  var executeQuery = match[0];
  React.useEffect((function () {
          Curry._3(executeQuery, undefined, undefined, {
                id: id
              });
        }), [activeShopId]);
  if (queryResults.TAG === /* Executed */0) {
    var match$1 = queryResults._0;
    var match$2 = match$1.data;
    var exit = 0;
    if (match$2 !== undefined) {
      var variant = match$2.variant;
      if (variant !== undefined) {
        Curry._3(navigate, true, undefined, LegacyRouter$Pos.routeToPathname(/* Catalog */2) + ("/" + variant.cku));
        return null;
      }
      exit = 2;
    } else {
      exit = 2;
    }
    if (exit === 2 && !match$1.loading) {
      return React.createElement(Placeholder$Pos.make, {
                  status: /* Error */2
                });
    }
    
  }
  return React.createElement(Placeholder$Pos.make, {
              status: /* Loading */0
            });
}

var make = React.memo(CatalogVariantByIdPage);

export {
  Query ,
  make ,
}
/* query Not a pure module */
