// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Form$Pos from "../../bundles/Form/Form.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as CatalogPriceLookUpInput$Pos from "./CatalogPriceLookUpInput.bs.js";

function get(values, field) {
  switch (field) {
    case /* Name */0 :
        return values.name;
    case /* CapacityUnit */1 :
        return values.capacityUnit;
    case /* CapacityValue */2 :
        return values.capacityValue;
    case /* CapacityPrecision */3 :
        return values.capacityPrecision;
    case /* Ean13 */4 :
        return values.ean13;
    case /* StockKeepingUnit */5 :
        return values.stockKeepingUnit;
    case /* PriceLookUpCode */6 :
        return values.priceLookUpCode;
    case /* InternalCode */7 :
        return values.internalCode;
    case /* AccountingCode */8 :
        return values.accountingCode;
    case /* AlcoholVolume */9 :
        return values.alcoholVolume;
    case /* Year */10 :
        return values.year;
    case /* Bulk */11 :
        return values.bulk;
    case /* PurchasePrice */12 :
        return values.purchasePrice;
    case /* SupplierId */13 :
        return values.supplierId;
    case /* VariantPrices */14 :
        return values.variantPrices;
    case /* InitialStockQuantity */15 :
        return values.initialStockQuantity;
    case /* InitialStockComment */16 :
        return values.initialStockComment;
    case /* MaxStockThreshold */17 :
        return values.maxStockThreshold;
    case /* MinStockThreshold */18 :
        return values.minStockThreshold;
    case /* StockOrderTriggerThreshold */19 :
        return values.stockOrderTriggerThreshold;
    
  }
}

function set(values, field, value) {
  switch (field) {
    case /* Name */0 :
        var newrecord = Caml_obj.obj_dup(values);
        newrecord.name = value;
        return newrecord;
    case /* CapacityUnit */1 :
        var newrecord$1 = Caml_obj.obj_dup(values);
        newrecord$1.capacityUnit = value;
        return newrecord$1;
    case /* CapacityValue */2 :
        var newrecord$2 = Caml_obj.obj_dup(values);
        newrecord$2.capacityValue = value;
        return newrecord$2;
    case /* CapacityPrecision */3 :
        var newrecord$3 = Caml_obj.obj_dup(values);
        newrecord$3.capacityPrecision = value;
        return newrecord$3;
    case /* Ean13 */4 :
        var newrecord$4 = Caml_obj.obj_dup(values);
        newrecord$4.ean13 = value;
        return newrecord$4;
    case /* StockKeepingUnit */5 :
        var newrecord$5 = Caml_obj.obj_dup(values);
        newrecord$5.stockKeepingUnit = value;
        return newrecord$5;
    case /* PriceLookUpCode */6 :
        var newrecord$6 = Caml_obj.obj_dup(values);
        newrecord$6.priceLookUpCode = value;
        return newrecord$6;
    case /* InternalCode */7 :
        var newrecord$7 = Caml_obj.obj_dup(values);
        newrecord$7.internalCode = value;
        return newrecord$7;
    case /* AccountingCode */8 :
        var newrecord$8 = Caml_obj.obj_dup(values);
        newrecord$8.accountingCode = value;
        return newrecord$8;
    case /* AlcoholVolume */9 :
        var newrecord$9 = Caml_obj.obj_dup(values);
        newrecord$9.alcoholVolume = value;
        return newrecord$9;
    case /* Year */10 :
        var newrecord$10 = Caml_obj.obj_dup(values);
        newrecord$10.year = value;
        return newrecord$10;
    case /* Bulk */11 :
        var newrecord$11 = Caml_obj.obj_dup(values);
        newrecord$11.bulk = value;
        return newrecord$11;
    case /* PurchasePrice */12 :
        var newrecord$12 = Caml_obj.obj_dup(values);
        newrecord$12.purchasePrice = value;
        return newrecord$12;
    case /* SupplierId */13 :
        var newrecord$13 = Caml_obj.obj_dup(values);
        newrecord$13.supplierId = value;
        return newrecord$13;
    case /* VariantPrices */14 :
        var newrecord$14 = Caml_obj.obj_dup(values);
        newrecord$14.variantPrices = value;
        return newrecord$14;
    case /* InitialStockQuantity */15 :
        var newrecord$15 = Caml_obj.obj_dup(values);
        newrecord$15.initialStockQuantity = value;
        return newrecord$15;
    case /* InitialStockComment */16 :
        var newrecord$16 = Caml_obj.obj_dup(values);
        newrecord$16.initialStockComment = value;
        return newrecord$16;
    case /* MaxStockThreshold */17 :
        var newrecord$17 = Caml_obj.obj_dup(values);
        newrecord$17.maxStockThreshold = value;
        return newrecord$17;
    case /* MinStockThreshold */18 :
        var newrecord$18 = Caml_obj.obj_dup(values);
        newrecord$18.minStockThreshold = value;
        return newrecord$18;
    case /* StockOrderTriggerThreshold */19 :
        var newrecord$19 = Caml_obj.obj_dup(values);
        newrecord$19.stockOrderTriggerThreshold = value;
        return newrecord$19;
    
  }
}

var Lenses = {
  get: get,
  set: set
};

var include = Form$Pos.Make(Lenses);

function validateMinStockThreshold(state) {
  if (state.maxStockThreshold === 0 && state.minStockThreshold === 0 && state.stockOrderTriggerThreshold === 0) {
    return {
            TAG: /* Ok */0,
            _0: undefined
          };
  }
  var minStockThreshold = state.minStockThreshold;
  if (minStockThreshold > state.stockOrderTriggerThreshold) {
    return {
            TAG: /* Error */1,
            _0: Intl$Pos.t("The minimum stock should be less or equal to the value triggering a new order.")
          };
  } else if (minStockThreshold >= state.maxStockThreshold) {
    return {
            TAG: /* Error */1,
            _0: Intl$Pos.t("The minimum stock should be less than the maximum stock.")
          };
  } else {
    return {
            TAG: /* Ok */0,
            _0: undefined
          };
  }
}

function validateStockOrderTriggerThreshold(state) {
  var exit = 0;
  if (state.maxStockThreshold !== 0 || state.minStockThreshold !== 0) {
    exit = 1;
  } else {
    if (state.stockOrderTriggerThreshold === 0) {
      return {
              TAG: /* Ok */0,
              _0: undefined
            };
    }
    exit = 1;
  }
  if (exit === 1) {
    var maxStockThreshold = state.maxStockThreshold;
    var exit$1 = 0;
    if (maxStockThreshold !== 0) {
      exit$1 = 2;
    } else {
      if (state.stockOrderTriggerThreshold > 0) {
        return {
                TAG: /* Error */1,
                _0: Intl$Pos.t("You have to define the maximum stock beforehand.")
              };
      }
      exit$1 = 2;
    }
    if (exit$1 === 2) {
      if (state.stockOrderTriggerThreshold >= maxStockThreshold) {
        return {
                TAG: /* Error */1,
                _0: Intl$Pos.t("This value should be less than maximum stock value.")
              };
      } else {
        return {
                TAG: /* Ok */0,
                _0: undefined
              };
      }
    }
    
  }
  
}

function validatePriceLookupCode(state) {
  var match = state.priceLookUpCode;
  var value = match.value;
  if (value === undefined) {
    return {
            TAG: /* Ok */0,
            _0: undefined
          };
  }
  if (value > CatalogPriceLookUpInput$Pos.pluRange[1]) {
    return {
            TAG: /* Error */1,
            _0: Intl$Pos.t("The PLU code must be between 1 and 9997.")
          };
  }
  if (!Belt_Array.some(match.invalidValues, (function (plu) {
            return plu === value;
          }))) {
    return {
            TAG: /* Ok */0,
            _0: undefined
          };
  }
  var match$1 = CatalogPriceLookUpInput$Pos.makeValue(state.priceLookUpCode.invalidValues, undefined, undefined);
  if (match$1.TAG === /* Ok */0) {
    return {
            TAG: /* Error */1,
            _0: Intl$Pos.t("This PLU code is used by another product.")
          };
  } else {
    return {
            TAG: /* Error */1,
            _0: Intl$Pos.t("There is no more PLU code available.")
          };
  }
}

var schema = [
  {
    TAG: /* StringNotEmpty */1,
    _0: /* Name */0
  },
  {
    TAG: /* Custom */7,
    _0: /* MinStockThreshold */18,
    _1: validateMinStockThreshold
  },
  {
    TAG: /* Custom */7,
    _0: /* StockOrderTriggerThreshold */19,
    _1: validateStockOrderTriggerThreshold
  },
  {
    TAG: /* Custom */7,
    _0: /* PriceLookUpCode */6,
    _1: validatePriceLookupCode
  }
];

var Core = include.Core;

var Schema = include.Schema;

var FormLegacyProvider = include.FormLegacyProvider;

var FormProvider = include.FormProvider;

var InputText = include.InputText;

var InputSuggestionComboBox = include.InputSuggestionComboBox;

var InputSelect = include.InputSelect;

var InputTextArea = include.InputTextArea;

var InputPassword = include.InputPassword;

var InputNumber = include.InputNumber;

var InputOptionalNumber = include.InputOptionalNumber;

var InputSegmentedControls = include.InputSegmentedControls;

var InputRadioGroup = include.InputRadioGroup;

var InputPhone = include.InputPhone;

var InputCheckbox = include.InputCheckbox;

var InputToggleSwitch = include.InputToggleSwitch;

var InputDate = include.InputDate;

var InputDateRange = include.InputDateRange;

var SubmitButton = include.SubmitButton;

var CancelButton = include.CancelButton;

var ResetButton = include.ResetButton;

var ControlEnterKey = include.ControlEnterKey;

var AutoSave = include.AutoSave;

var useFormPropState = include.useFormPropState;

var useField = include.useField;

var useFormState = include.useFormState;

var useFormDispatch = include.useFormDispatch;

var validate = include.validate;

export {
  Lenses ,
  Core ,
  Schema ,
  FormLegacyProvider ,
  FormProvider ,
  InputText ,
  InputSuggestionComboBox ,
  InputSelect ,
  InputTextArea ,
  InputPassword ,
  InputNumber ,
  InputOptionalNumber ,
  InputSegmentedControls ,
  InputRadioGroup ,
  InputPhone ,
  InputCheckbox ,
  InputToggleSwitch ,
  InputDate ,
  InputDateRange ,
  SubmitButton ,
  CancelButton ,
  ResetButton ,
  ControlEnterKey ,
  AutoSave ,
  useFormPropState ,
  useField ,
  useFormState ,
  useFormDispatch ,
  validate ,
  validateMinStockThreshold ,
  validateStockOrderTriggerThreshold ,
  validatePriceLookupCode ,
  schema ,
}
/* include Not a pure module */
