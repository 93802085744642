// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Auth$Pos from "../../bundles/Auth/Auth.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Inline$Pos from "../../resources/layout-and-structure/Inline.bs.js";
import * as Scalar$Pos from "../../core/Scalar.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Notifier$Pos from "../../bundles/Notifier/Notifier.bs.js";
import * as TextStyle$Pos from "../../resources/typography/TextStyle.bs.js";
import * as Navigation$Pos from "../../primitives/Navigation.bs.js";
import * as Placeholder$Pos from "../../resources/layout-and-structure/Placeholder.bs.js";
import * as LegacyRouter$Pos from "../../core/LegacyRouter.bs.js";
import * as CatalogVariantEditForm$Pos from "../../modules/Catalog/CatalogVariantEditForm.bs.js";
import * as CatalogVariantEditPage$Pos from "./CatalogVariantEditPage.bs.js";
import * as CatalogVariantEdit__Config$Pos from "./CatalogVariantEdit__Config.bs.js";
import * as CatalogVariantEdit__Queries$Pos from "./CatalogVariantEdit__Queries.bs.js";
import * as CatalogPlaceholderShopPicker$Pos from "../../modules/Catalog/CatalogPlaceholderShopPicker.bs.js";

function CatalogVariantEditContainer(Props) {
  var cku = Props.cku;
  var mode = Props.mode;
  var match = Curry.app(CatalogVariantEdit__Queries$Pos.ProductsQuery.useLazy, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var productsQueryResults = match[1];
  var executeProductsQuery = match[0];
  var match$1 = Curry.app(CatalogVariantEdit__Queries$Pos.VariantQuery.useLazy, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var executeVariantQuery = match$1[0];
  var match$2 = Curry.app(CatalogVariantEdit__Queries$Pos.ProductQuery.useLazy, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var executeProductQuery = match$2[0];
  var onGoBack = Navigation$Pos.useGoBack(undefined)[1];
  var navigate = Navigation$Pos.useNavigate(undefined);
  var notifier = Notifier$Pos.use(/* KeepOnHistoryChanges */1, undefined);
  var shops = Auth$Pos.useShops(undefined);
  var activeShop = Auth$Pos.useActiveShop(undefined);
  var activeShopId = Belt_Option.map(activeShop, (function (shop) {
          return shop.id;
        }));
  React.useEffect((function () {
          if (cku !== undefined && shops.length > 1) {
            Curry._3(executeProductsQuery, undefined, undefined, CatalogVariantEdit__Queries$Pos.ProductsQuery.makeVariables(CatalogVariantEdit__Queries$Pos.ProductsQuery.makeInputObjectInputProductsQueryFilter(undefined, undefined, undefined, undefined, CatalogVariantEdit__Queries$Pos.ProductsQuery.makeInputObjectInFilter([cku], undefined), undefined, "INCLUDED", undefined), undefined));
          }
          
        }), []);
  var disabledShopIds = React.useMemo((function () {
          if (productsQueryResults.TAG !== /* Executed */0) {
            return [];
          }
          var match = productsQueryResults._0.data;
          if (match === undefined) {
            return [];
          }
          var products = match.products.edges;
          return Belt_Array.keepMap(shops, (function (param) {
                        var id = param.id;
                        var match = Belt_Array.getBy(products, (function (param) {
                                return param.node.shop.id === id;
                              }));
                        if (match !== undefined) {
                          return ;
                        } else {
                          return id;
                        }
                      }));
        }), [productsQueryResults]);
  var renderShopSelectionItem = React.useCallback((function (shop) {
          var shopName = Belt_Option.mapWithDefault(shop.value, "", (function (shop) {
                  return shop.name;
                }));
          var match = Belt_Option.isSome(shop.value);
          var match$1 = Belt_Option.getWithDefault(shop.disabled, false);
          if (match) {
            if (match$1) {
              return React.createElement(Inline$Pos.make, {
                          children: null,
                          align: "spaceBetween",
                          alignY: "top",
                          grow: true
                        }, React.createElement(TextStyle$Pos.make, {
                              children: shopName,
                              variation: "placeholder"
                            }), React.createElement(Inline$Pos.make, {
                              children: React.createElement(TextStyle$Pos.make, {
                                    children: Intl$Pos.t("Non existing product"),
                                    variation: "subdued",
                                    size: "xsmall",
                                    lineHeight: "large"
                                  }),
                              align: "end",
                              grow: true
                            }));
            } else {
              return React.createElement(TextStyle$Pos.make, {
                          children: shopName
                        });
            }
          } else {
            return React.createElement(TextStyle$Pos.make, {
                        children: Intl$Pos.t("Select a shop"),
                        variation: "normal"
                      });
          }
        }), []);
  React.useEffect((function () {
          if (mode) {
            if (activeShop !== undefined && cku !== undefined) {
              Curry._3(executeVariantQuery, undefined, undefined, CatalogVariantEdit__Queries$Pos.VariantQuery.makeVariables(Scalar$Pos.CKU.serialize(cku), CatalogVariantEdit__Config$Pos.makeVariantFilterBy(activeShopId), undefined));
            }
            
          } else if (activeShop !== undefined && cku !== undefined) {
            Curry._3(executeProductQuery, undefined, undefined, CatalogVariantEdit__Queries$Pos.ProductQuery.makeVariables(Scalar$Pos.CKU.serialize(cku), CatalogVariantEdit__Config$Pos.makeProductFilterBy(activeShopId), undefined));
          }
          
        }), [activeShopId]);
  var onSubmitSuccess = React.useCallback((function (newVariantCku) {
          if (mode) {
            Curry._3(notifier.reset, {
                  TAG: /* Success */0,
                  _0: Intl$Pos.t("The variant information have been correctly updated.")
                }, undefined, undefined);
            return Curry._1(onGoBack, undefined);
          } else if (cku !== undefined) {
            return Curry._3(navigate, true, undefined, LegacyRouter$Pos.routeToPathname(/* Catalog */2) + "/product/" + cku);
          } else {
            return Curry._3(navigate, undefined, undefined, LegacyRouter$Pos.routeToPathname(/* Catalog */2) + "/product/" + Belt_Option.getWithDefault(newVariantCku, ""));
          }
        }), []);
  var onSubmitFailure = React.useCallback((function (message) {
          Curry._3(notifier.reset, {
                TAG: /* Error */1,
                _0: message
              }, undefined, undefined);
        }), []);
  var results = CatalogVariantEdit__Config$Pos.use(match$1[1], match$2[1]);
  var tmp;
  if (typeof results === "number") {
    tmp = results !== 0 ? React.createElement(Placeholder$Pos.make, {
            status: /* Loading */0
          }) : React.createElement(Placeholder$Pos.make, {
            status: /* Error */2
          });
  } else {
    var match$3 = results._0;
    tmp = React.createElement(CatalogVariantEditForm$Pos.Core.LegacyProvider.make, {
          initialValues: match$3.initialValues,
          schema: CatalogVariantEditForm$Pos.schema,
          children: React.createElement(CatalogVariantEditPage$Pos.make, {
                editionMode: mode === /* Edit */1,
                formattedName: match$3.formattedName,
                shopName: match$3.shopName,
                productTaxRate: match$3.productTaxRate,
                productKind: match$3.productKind,
                productValues: match$3.productValues
              }),
          id: match$3.id,
          onSubmitFailure: onSubmitFailure,
          onSubmitSuccess: onSubmitSuccess
        });
  }
  return React.createElement(CatalogPlaceholderShopPicker$Pos.make, {
              children: tmp,
              message: Intl$Pos.template(Intl$Pos.t("Please select the shop in which you would like to {{action}} the reference."), {
                    action: Intl$Pos.t(mode === /* Edit */1 ? "edit" : "create")
                  }, undefined),
              disabledIds: disabledShopIds,
              renderItemContent: renderShopSelectionItem
            });
}

var make = React.memo(CatalogVariantEditContainer);

var Config;

var ProductsQuery;

var VariantQuery;

var ProductQuery;

export {
  Config ,
  ProductsQuery ,
  VariantQuery ,
  ProductQuery ,
  make ,
}
/* make Not a pure module */
