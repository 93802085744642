// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Form$Pos from "../../bundles/Form/Form.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";

function get(values, field) {
  switch (field) {
    case /* Quantity */0 :
        return values.quantity;
    case /* SenderVariantId */1 :
        return values.senderVariantId;
    case /* RecipientVariantId */2 :
        return values.recipientVariantId;
    
  }
}

function set(values, field, value) {
  switch (field) {
    case /* Quantity */0 :
        return {
                quantity: value,
                senderVariantId: values.senderVariantId,
                recipientVariantId: values.recipientVariantId
              };
    case /* SenderVariantId */1 :
        return {
                quantity: values.quantity,
                senderVariantId: value,
                recipientVariantId: values.recipientVariantId
              };
    case /* RecipientVariantId */2 :
        return {
                quantity: values.quantity,
                senderVariantId: values.senderVariantId,
                recipientVariantId: value
              };
    
  }
}

var ProductLenses = {
  get: get,
  set: set
};

function get$1(values, field) {
  switch (field) {
    case /* SenderShopId */0 :
        return values.senderShopId;
    case /* RecipientShopId */1 :
        return values.recipientShopId;
    case /* Products */2 :
        return values.products;
    
  }
}

function set$1(values, field, value) {
  switch (field) {
    case /* SenderShopId */0 :
        return {
                senderShopId: value,
                recipientShopId: values.recipientShopId,
                products: values.products
              };
    case /* RecipientShopId */1 :
        return {
                senderShopId: values.senderShopId,
                recipientShopId: value,
                products: values.products
              };
    case /* Products */2 :
        return {
                senderShopId: values.senderShopId,
                recipientShopId: values.recipientShopId,
                products: value
              };
    
  }
}

var Lenses = {
  get: get$1,
  set: set$1
};

var initialValues_products = [];

var initialValues = {
  senderShopId: "",
  recipientShopId: "",
  products: initialValues_products
};

var include = Form$Pos.Make(Lenses);

function productsError(products) {
  return Belt_Array.keepMap(products, (function (product) {
                if (product.quantity <= 0) {
                  return {
                          key: product.senderVariantId,
                          message: Intl$Pos.t("Transferred quantity must be greater than 0.")
                        };
                }
                
              }));
}

var schema = [
  {
    TAG: /* StringNotEmpty */1,
    _0: /* SenderShopId */0
  },
  {
    TAG: /* StringNotEmpty */1,
    _0: /* RecipientShopId */1
  },
  {
    TAG: /* Custom */7,
    _0: /* Products */2,
    _1: (function (param) {
        if (param.products.length === 0) {
          return {
                  TAG: /* Error */1,
                  _0: Intl$Pos.t("You should have at least one product in cart.")
                };
        } else {
          return {
                  TAG: /* Ok */0,
                  _0: undefined
                };
        }
      })
  },
  {
    TAG: /* Custom */7,
    _0: /* Products */2,
    _1: (function (param) {
        if (productsError(param.products).length !== 0) {
          return {
                  TAG: /* Error */1,
                  _0: Intl$Pos.t("The cart contains products with an incorrect transferred quantity.")
                };
        } else {
          return {
                  TAG: /* Ok */0,
                  _0: undefined
                };
        }
      })
  }
];

var Core = include.Core;

var Schema = include.Schema;

var FormLegacyProvider = include.FormLegacyProvider;

var FormProvider = include.FormProvider;

var InputText = include.InputText;

var InputSuggestionComboBox = include.InputSuggestionComboBox;

var InputSelect = include.InputSelect;

var InputTextArea = include.InputTextArea;

var InputPassword = include.InputPassword;

var InputNumber = include.InputNumber;

var InputOptionalNumber = include.InputOptionalNumber;

var InputSegmentedControls = include.InputSegmentedControls;

var InputRadioGroup = include.InputRadioGroup;

var InputPhone = include.InputPhone;

var InputCheckbox = include.InputCheckbox;

var InputToggleSwitch = include.InputToggleSwitch;

var InputDate = include.InputDate;

var InputDateRange = include.InputDateRange;

var SubmitButton = include.SubmitButton;

var CancelButton = include.CancelButton;

var ResetButton = include.ResetButton;

var ControlEnterKey = include.ControlEnterKey;

var AutoSave = include.AutoSave;

var useFormPropState = include.useFormPropState;

var useField = include.useField;

var useFormState = include.useFormState;

var useFormDispatch = include.useFormDispatch;

var validate = include.validate;

export {
  ProductLenses ,
  Lenses ,
  initialValues ,
  Core ,
  Schema ,
  FormLegacyProvider ,
  FormProvider ,
  InputText ,
  InputSuggestionComboBox ,
  InputSelect ,
  InputTextArea ,
  InputPassword ,
  InputNumber ,
  InputOptionalNumber ,
  InputSegmentedControls ,
  InputRadioGroup ,
  InputPhone ,
  InputCheckbox ,
  InputToggleSwitch ,
  InputDate ,
  InputDateRange ,
  SubmitButton ,
  CancelButton ,
  ResetButton ,
  ControlEnterKey ,
  AutoSave ,
  useFormPropState ,
  useField ,
  useFormState ,
  useFormDispatch ,
  validate ,
  productsError ,
  schema ,
}
/* include Not a pure module */
