// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Box$Pos from "../resources/layout-and-structure/Box.bs.js";
import * as Nav$Pos from "../resources/navigation/Nav.bs.js";
import * as Page$Pos from "../resources/layout-and-structure/Page.bs.js";
import * as Banner$Pos from "../resources/feedback-indicators/Banner.bs.js";
import * as Inline$Pos from "../resources/layout-and-structure/Inline.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as PageBottomActionsBar$Pos from "../resources/layout-and-structure/PageBottomActionsBar.bs.js";

function ResourceDetailsPage$ActionsBar(Props) {
  var items = Props.items;
  return React.createElement(Inline$Pos.make, {
              children: items,
              space: "small"
            });
}

var ActionsBar = {
  make: ResourceDetailsPage$ActionsBar
};

function ResourceDetailsPage$NotificationBanner(Props) {
  var value = Props.value;
  var onRequestClose = Props.onRequestClose;
  var tmp;
  tmp = value.TAG === /* Ok */0 ? ({
        TAG: /* Success */0,
        _0: value._0
      }) : ({
        TAG: /* Danger */1,
        _0: value._0
      });
  return React.createElement(Banner$Pos.make, {
              textStatus: tmp,
              onRequestClose: onRequestClose
            });
}

var NotificationBanner = {
  make: ResourceDetailsPage$NotificationBanner
};

function ResourceDetailsPage(Props) {
  var variation = Props.variation;
  var title = Props.title;
  var subtitle = Props.subtitle;
  var actionsBar = Props.actionsBar;
  var notificationBanner = Props.notificationBanner;
  var statusBadge = Props.statusBadge;
  var children = Props.children;
  var match = Nav$Pos.Context.use(undefined);
  var renderActionsBar = Belt_Option.map(actionsBar, (function (element, param) {
          return element;
        }));
  var renderTitleEnd = Belt_Option.map(statusBadge, (function (element, param) {
          return element;
        }));
  var tmp = {
    children: null,
    title: title
  };
  if (variation !== undefined) {
    tmp.variation = Caml_option.valFromOption(variation);
  }
  if (subtitle !== undefined) {
    tmp.subtitle = subtitle;
  }
  if (renderTitleEnd !== undefined) {
    tmp.renderTitleEnd = Caml_option.valFromOption(renderTitleEnd);
  }
  if (renderActionsBar !== undefined) {
    tmp.renderActions = Caml_option.valFromOption(renderActionsBar);
  }
  return React.createElement(Page$Pos.make, tmp, notificationBanner !== undefined ? React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(Caml_option.valFromOption(notificationBanner)), undefined, undefined, "medium", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)) : null, React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(children), undefined, undefined, "large", undefined, undefined, undefined, true, undefined, undefined, undefined, undefined)), renderActionsBar !== undefined ? React.createElement(PageBottomActionsBar$Pos.make, {
                    renderEnd: renderActionsBar,
                    navOpened: match[0].opened
                  }) : null);
}

var make = ResourceDetailsPage;

export {
  ActionsBar ,
  NotificationBanner ,
  make ,
}
/* react Not a pure module */
