// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Icon$Pos from "../images-and-icons/Icon.bs.js";
import * as View$Pos from "../../primitives/View.bs.js";
import * as Hover$Pos from "../../primitives/Hover.bs.js";
import * as Style$Pos from "../../primitives/Style.bs.js";
import * as Colors$Pos from "../theme/Colors.bs.js";
import * as Spaces$Pos from "../theme/Spaces.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Spinner$Pos from "../feedback-indicators/Spinner.bs.js";
import * as TextInput$Pos from "../../primitives/TextInput.bs.js";
import * as Touchable$Pos from "../../primitives/Touchable.bs.js";
import * as IconButton$Pos from "../actions/IconButton.bs.js";
import * as AnimatedRender$Pos from "../../primitives/AnimatedRender.bs.js";
import * as TextFieldStyle$Pos from "./TextFieldStyle.bs.js";
import * as ReactDomElement$Pos from "../../primitives/ReactDomElement.bs.js";

function InputSearch$SearchPrepender(Props) {
  var loading = Props.loading;
  var onRequestInputFocus = Props.onRequestInputFocus;
  return React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(null), Caml_option.some(Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(18)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "relative", undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(16)), undefined, undefined)), undefined, undefined, undefined), React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(React.createElement(AnimatedRender$Pos.make, {
                              children: React.createElement(Spinner$Pos.make, {
                                    size: 18
                                  }),
                              displayed: loading,
                              animation: "fade",
                              duration: 250
                            })), Caml_option.some(Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(-1)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "absolute", undefined, undefined, undefined, undefined, undefined, undefined)), undefined, undefined, undefined)), React.createElement(AnimatedRender$Pos.make, {
                  children: React.createElement(Touchable$Pos.make, Touchable$Pos.makeProps(React.createElement(Icon$Pos.make, {
                                name: "search",
                                fill: Colors$Pos.neutralColor25,
                                size: 17.5,
                                stroke: Colors$Pos.neutralColor25
                              }), undefined, undefined, true, undefined, {
                            cursor: "default"
                          }, (function (param) {
                              Curry._1(onRequestInputFocus, undefined);
                            }), undefined, undefined, undefined)),
                  displayed: !loading,
                  animation: "fade",
                  duration: 100
                }));
}

function InputSearch$EraseAppender(Props) {
  var onRequestInputFocus = Props.onRequestInputFocus;
  var onRequestInputClear = Props.onRequestInputClear;
  return React.createElement(IconButton$Pos.make, IconButton$Pos.makeProps("close_medium", 17, undefined, Colors$Pos.neutralColor25, Colors$Pos.neutralColor50, undefined, undefined, (function (param) {
                    Curry._1(onRequestInputFocus, undefined);
                    Curry._1(onRequestInputClear, undefined);
                  }), undefined, undefined, undefined));
}

function style(focused, hovered, disabled, bordered) {
  return {
          backgroundColor: TextFieldStyle$Pos.backgroundColor(disabled),
          border: bordered ? "1px solid" : "none",
          borderColor: TextFieldStyle$Pos.borderColor(focused, hovered, false, disabled),
          display: "flex",
          height: "40px",
          overflow: "hidden",
          padding: "0 " + String(Spaces$Pos.normal) + "px",
          borderRadius: TextFieldStyle$Pos.borderRadiusPx,
          columnGap: String(Spaces$Pos.small) + "px",
          alignItems: "center",
          flexDirection: "row",
          boxSizing: "border-box"
        };
}

function textInputStyle(disabled) {
  return {
          color: TextFieldStyle$Pos.color(disabled),
          fontSize: TextFieldStyle$Pos.fontSizePx,
          height: "35px",
          width: "0",
          flex: "1",
          textOverflow: "ellipsis"
        };
}

function InputSearch(Props) {
  var loading = Props.loading;
  var onRequestClear = Props.onRequestClear;
  var disabledOpt = Props.disabled;
  var borderedOpt = Props.bordered;
  var focusedOpt = Props.focused;
  var placeholder = Props.placeholder;
  var autoFocus = Props.autoFocus;
  var containerRef = Props.containerRef;
  var inputRef = Props.inputRef;
  var ariaProps = Props.ariaProps;
  var valueOpt = Props.value;
  var onChange = Props.onChange;
  var onFocus = Props.onFocus;
  var onBlur = Props.onBlur;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var bordered = borderedOpt !== undefined ? borderedOpt : true;
  var focused = focusedOpt !== undefined ? focusedOpt : false;
  var value = valueOpt !== undefined ? valueOpt : "";
  var inputRef$1 = Belt_Option.getWithDefault(inputRef, React.useRef(null));
  var match = Hover$Pos.use(containerRef, undefined);
  var focusInput = function (param) {
    var input = inputRef$1.current;
    if (!(input == null)) {
      return ReactDomElement$Pos.focus(input);
    }
    
  };
  var style$1 = style(focused, match[1], disabled, bordered);
  var textInputStyle$1 = textInputStyle(disabled);
  var value$1 = Belt_Option.getWithDefault(Belt_Option.flatMap(ariaProps, (function (ariaProps) {
              return ariaProps.value;
            })), value);
  var tmp = {
    inputRef: inputRef$1,
    readOnly: disabled,
    style: textInputStyle$1,
    value: value$1
  };
  if (ariaProps !== undefined) {
    tmp.ariaProps = Caml_option.valFromOption(ariaProps);
  }
  if (placeholder !== undefined) {
    tmp.placeholder = placeholder;
  }
  if (autoFocus !== undefined) {
    tmp.autoFocus = autoFocus;
  }
  if (onChange !== undefined) {
    tmp.onChange = Caml_option.valFromOption(onChange);
  }
  if (onFocus !== undefined) {
    tmp.onFocus = Caml_option.valFromOption(onFocus);
  }
  if (onBlur !== undefined) {
    tmp.onBlur = Caml_option.valFromOption(onBlur);
  }
  return React.createElement("div", {
              ref: match[0],
              style: style$1
            }, React.createElement(InputSearch$SearchPrepender, {
                  loading: loading,
                  onRequestInputFocus: focusInput
                }), React.createElement(TextInput$Pos.make, tmp), value$1 !== "" && !disabled ? React.createElement(InputSearch$EraseAppender, {
                    onRequestInputFocus: focusInput,
                    onRequestInputClear: onRequestClear
                  }) : null);
}

var make = React.memo(InputSearch);

export {
  make ,
}
/* make Not a pure module */
