// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Field$Pos from "../layout-and-structure/Field.bs.js";
import * as Hover$Pos from "../../primitives/Hover.bs.js";
import * as Spaces$Pos from "../theme/Spaces.bs.js";
import * as ReactAria from "react-aria";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as TextArea$Pos from "../../primitives/TextArea.bs.js";
import * as TextFieldStyle$Pos from "./TextFieldStyle.bs.js";

function style(focused, hovered, errored, disabled) {
  return {
          backgroundColor: TextFieldStyle$Pos.backgroundColor(disabled),
          display: "flex",
          outline: "1px solid",
          outlineColor: TextFieldStyle$Pos.borderColor(focused, hovered, errored, disabled),
          borderRadius: TextFieldStyle$Pos.borderRadiusPx,
          outlineOffset: "-1px"
        };
}

function textAreaStyle(disabled) {
  return {
          backgroundColor: TextFieldStyle$Pos.backgroundColor(disabled),
          color: TextFieldStyle$Pos.color(disabled),
          fontSize: TextFieldStyle$Pos.fontSizePx,
          padding: "" + String(Spaces$Pos.xnormal) + "px " + String(Spaces$Pos.normal) + "px",
          flex: "1",
          boxSizing: "border-box"
        };
}

function useFocus(focused, onFocus, onBlur, param) {
  if (focused !== undefined && onFocus !== undefined && onBlur !== undefined) {
    return [
            focused,
            onFocus,
            onBlur
          ];
  }
  var match = React.useState(function () {
        return false;
      });
  var setFocused = match[1];
  var onFocus$1 = function (param) {
    Curry._1(setFocused, (function (param) {
            return true;
          }));
  };
  var onBlur$1 = function (param) {
    Curry._1(setFocused, (function (param) {
            return false;
          }));
  };
  return [
          match[0],
          onFocus$1,
          onBlur$1
        ];
}

function InputTextAreaField(Props) {
  var label = Props.label;
  var focused = Props.focused;
  var required = Props.required;
  var infotip = Props.infotip;
  var disabledOpt = Props.disabled;
  var errorMessage = Props.errorMessage;
  var ariaProps = Props.ariaProps;
  var value = Props.value;
  var onChange = Props.onChange;
  var onFocus = Props.onFocus;
  var onBlur = Props.onBlur;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var match = Hover$Pos.use(undefined, undefined);
  var match$1 = useFocus(focused, onFocus, onBlur, undefined);
  var match$2 = ReactAria.useLabel({
        label: label,
        "aria-label": label
      });
  var ariaProps$1 = ReactAria.mergeProps(ariaProps, match$2.fieldProps);
  var errored = Belt_Option.isSome(errorMessage);
  var style$1 = style(match$1[0], match[1], errored, disabled);
  var textAreaStyle$1 = textAreaStyle(disabled);
  var tmp = {
    ariaProps: ariaProps$1,
    readOnly: disabled,
    style: textAreaStyle$1,
    value: value,
    onFocus: match$1[1],
    onBlur: match$1[2]
  };
  if (onChange !== undefined) {
    tmp.onChange = Caml_option.valFromOption(onChange);
  }
  return React.createElement(Field$Pos.make, Field$Pos.makeProps(React.createElement("div", {
                      style: style$1
                    }, React.createElement(TextArea$Pos.make, tmp)), label, match$2.labelProps, infotip, errorMessage, undefined, required, undefined, match[0], undefined));
}

var make = React.memo(InputTextAreaField);

export {
  make ,
}
/* make Not a pure module */
