// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Env$Pos from "./Env.bs.js";
import * as Logrocket from "logrocket";
import * as PosthogJs from "posthog-js";

function updateUserFields(userId, name, email) {
  if (Env$Pos.context(undefined) === "production") {
    Logrocket.default.identify(userId, {
          name: name,
          email: email
        });
    PosthogJs.default.identify(userId, {
          name: name,
          email: email
        });
    return ;
  }
  
}

function onSession(handler) {
  if (Env$Pos.context(undefined) === "production") {
    Logrocket.default.getSessionURL(Curry.__1(handler));
    return ;
  }
  
}

var $$Event = {};

function captureEvent($$event) {
  if (Env$Pos.context(undefined) === "production") {
    PosthogJs.default.capture($$event);
    return ;
  }
  
}

export {
  $$Event ,
  updateUserFields ,
  onSession ,
  captureEvent ,
}
/* logrocket Not a pure module */
