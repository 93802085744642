// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Box$Pos from "../../resources/layout-and-structure/Box.bs.js";
import * as Nav$Pos from "../../resources/navigation/Nav.bs.js";
import * as Card$Pos from "../../resources/layout-and-structure/Card.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as View$Pos from "../../primitives/View.bs.js";
import * as Hover$Pos from "../../primitives/Hover.bs.js";
import * as Style$Pos from "../../primitives/Style.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Colors$Pos from "../../resources/theme/Colors.bs.js";
import * as Column$Pos from "../../resources/layout-and-structure/Column.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Columns$Pos from "../../resources/layout-and-structure/Columns.bs.js";
import * as Notifier$Pos from "../../bundles/Notifier/Notifier.bs.js";
import * as TableView$Pos from "../../resources/tables/TableView.bs.js";
import * as TextStyle$Pos from "../../resources/typography/TextStyle.bs.js";
import * as Touchable$Pos from "../../primitives/Touchable.bs.js";
import * as RoundButton$Pos from "../../resources/actions/RoundButton.bs.js";
import * as SpinnerModal$Pos from "../../resources/feedback-indicators/SpinnerModal.bs.js";
import * as InputNumberField$Pos from "../../resources/selection-and-input/InputNumberField.bs.js";
import * as StockTransferForm$Pos from "./StockTransferForm.bs.js";
import * as OverlayTriggerView$Pos from "../../resources/overlays/OverlayTriggerView.bs.js";
import * as PageBottomActionsBar$Pos from "../../resources/layout-and-structure/PageBottomActionsBar.bs.js";
import * as ProductStockTableCell$Pos from "../Product/ProductStockTableCell.bs.js";
import * as StockTransferFormActions$Pos from "./StockTransferFormActions.bs.js";
import * as ProductReferenceTableCell$Pos from "../Product/ProductReferenceTableCell.bs.js";
import * as StockTransferFormProducts$Pos from "./StockTransferFormProducts.bs.js";
import * as StockTransferFormProductPickerModalTable$Pos from "./StockTransferFormProductPickerModalTable.bs.js";

function StockTransferFormProductsCard$AddProductModalButton(Props) {
  var triggerPickerDisabled = Props.triggerPickerDisabled;
  var onRequestModalPickerOpen = Props.onRequestModalPickerOpen;
  var match = Hover$Pos.use(undefined, undefined);
  return React.createElement(Touchable$Pos.make, Touchable$Pos.makeProps(React.createElement(OverlayTriggerView$Pos.make, {
                      children: React.createElement(TextStyle$Pos.make, {
                            children: Intl$Pos.t("Add product"),
                            variation: "normal"
                          }),
                      preset: {
                        NAME: "inputField",
                        VAL: {
                          required: false
                        }
                      },
                      icon: "search",
                      hovered: match[1],
                      disabled: triggerPickerDisabled
                    }), undefined, triggerPickerDisabled, undefined, undefined, undefined, (function (param) {
                    Curry._1(onRequestModalPickerOpen, undefined);
                  }), undefined, match[0], undefined));
}

var AddProductModalButton = {
  make: StockTransferFormProductsCard$AddProductModalButton
};

var style = Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Colors$Pos.neutralColor05, Colors$Pos.neutralColor15, undefined, undefined, undefined, undefined, 1, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "solid", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(23)), undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var make = React.memo(function (Props) {
      var message = Props.message;
      var errorMessage = Props.errorMessage;
      return React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(null), Caml_option.some(style), undefined, undefined, undefined), React.createElement(TextStyle$Pos.make, {
                      children: message,
                      variation: "normal",
                      size: "small"
                    }), errorMessage !== undefined ? React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(TextStyle$Pos.make, {
                                    children: errorMessage,
                                    variation: "negative",
                                    size: "xsmall"
                                  })), undefined, undefined, "xsmall", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)) : null);
    });

var TablePlaceholder = {
  style: style,
  make: make
};

function keyExtractor(row) {
  return row.senderVariantId;
}

var Row = {
  keyExtractor: keyExtractor
};

function tableColumns(dispatch) {
  return [
          {
            key: "reference",
            name: Intl$Pos.t("Product"),
            render: (function (param) {
                var product = param.data;
                var tmp = {
                  id: product.senderVariantId,
                  name: product.name,
                  description: product.description,
                  openNewTab: true
                };
                if (product.stockKeepingUnit !== undefined) {
                  tmp.stockKeepingUnit = product.stockKeepingUnit;
                }
                if (param.errorMessage !== undefined) {
                  tmp.errorMessage = Caml_option.valFromOption(param.errorMessage);
                }
                return React.createElement(ProductReferenceTableCell$Pos.make, tmp);
              })
          },
          {
            key: "senderStock",
            name: Intl$Pos.t("Sender stock"),
            render: (function (param) {
                var product = param.data;
                var tmp = {
                  value: product.senderStockQuantity.toString(),
                  size: "xsmall"
                };
                if (product.senderStockState !== undefined) {
                  tmp.state = Caml_option.valFromOption(product.senderStockState);
                }
                return React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(ProductStockTableCell$Pos.make, tmp)), undefined, undefined, undefined, undefined, "normal", undefined, undefined, undefined, undefined, undefined, undefined));
              })
          },
          {
            key: "recipientStock",
            name: Intl$Pos.t("Recipient stock"),
            render: (function (param) {
                var product = param.data;
                var quantity = product.recipientStockQuantity;
                var tmp;
                if (quantity !== undefined) {
                  var tmp$1 = {
                    value: String(quantity),
                    size: "xsmall"
                  };
                  if (product.recipientStockState !== undefined) {
                    tmp$1.state = Caml_option.valFromOption(product.recipientStockState);
                  }
                  tmp = React.createElement(ProductStockTableCell$Pos.make, tmp$1);
                } else {
                  tmp = React.createElement(TextStyle$Pos.make, {
                        children: Intl$Pos.t("-"),
                        variation: "normal"
                      });
                }
                return React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(tmp), undefined, undefined, undefined, undefined, "normal", undefined, undefined, undefined, undefined, undefined, undefined));
              })
          },
          {
            key: "transferredQuantity",
            name: Intl$Pos.t("Transferred quantity"),
            render: (function (param) {
                var product = param.data;
                return React.createElement(InputNumberField$Pos.make, {
                            value: product.transferredQuantity,
                            minValue: 1,
                            precision: 0,
                            onChange: (function (quantity) {
                                Curry._1(dispatch, {
                                      TAG: /* ProductTransferredQuantityUpdateRequested */3,
                                      _0: product.cku,
                                      _1: quantity | 0
                                    });
                              })
                          });
              })
          },
          {
            key: "actions",
            name: Intl$Pos.t(""),
            render: (function (param) {
                var product = param.data;
                return React.createElement(Columns$Pos.make, {
                            children: React.createElement(Column$Pos.make, {
                                  children: React.createElement(RoundButton$Pos.make, RoundButton$Pos.makeProps(undefined, "delete_light", undefined, undefined, undefined, (function (param) {
                                              Curry._1(dispatch, {
                                                    TAG: /* ProductRemoveRequested */1,
                                                    _0: product.cku
                                                  });
                                            }), undefined, undefined, undefined)),
                                  width: "fluid"
                                }),
                            align: "end"
                          });
              })
          }
        ];
}

function StockTransferFormProductsCard(Props) {
  var productsRowErrors = Props.productsRowErrors;
  var match = Nav$Pos.Context.use(undefined);
  var match$1 = StockTransferFormProducts$Pos.use(undefined);
  var productsDispatch = match$1[1];
  var productsState = match$1[0];
  var match$2 = React.useState(function () {
        return false;
      });
  var setPickerOpened = match$2[1];
  var formState = Curry._1(StockTransferForm$Pos.useFormState, undefined);
  var notifier = Notifier$Pos.use(undefined, undefined);
  var match$3 = React.useMemo((function () {
          return Belt_Array.partition(productsState.products, (function (product) {
                        return Belt_Option.isSome(product.recipientStockQuantity);
                      }));
        }), [productsState.products]);
  var nonCommonProducts = match$3[1];
  React.useEffect((function () {
          var nonCommonProductsLength = nonCommonProducts.length;
          if (!productsState.processing && nonCommonProductsLength > 0) {
            Curry._3(notifier.reset, {
                  TAG: /* Warning */2,
                  _0: Intl$Pos.template(Intl$Pos.t(Intl$Pos.isPlural(nonCommonProductsLength) ? "{{count}} products could not be added to the transfer because they are not common to the two selected shops." : "{{count}} product could not be added to the transfer because it is not common to the two selected shops."), {
                        count: nonCommonProductsLength
                      }, undefined)
                }, Belt_Array.map(nonCommonProducts, (function (product) {
                        return product.name + Belt_Option.mapWithDefault(product.stockKeepingUnit, "", (function (sku) {
                                      return ", " + sku;
                                    }));
                      })), undefined);
            Belt_Array.forEach(nonCommonProducts, (function (product) {
                    Curry._1(productsDispatch, {
                          TAG: /* ProductRemoveRequested */1,
                          _0: product.cku
                        });
                  }));
          }
          if (!productsState.processing && nonCommonProductsLength === 0) {
            Curry._1(notifier.clear, undefined);
          }
          
        }), [productsState.processing]);
  React.useEffect((function () {
          if (formState.values.products.length !== 0) {
            Curry._1(productsDispatch, /* ResetRequested */0);
            Curry._1(notifier.clear, undefined);
          }
          
        }), [
        formState.values.recipientShopId,
        formState.values.senderShopId
      ]);
  var renderActionsStart = function (param) {
    return React.createElement(StockTransferFormProductsCard$AddProductModalButton, {
                triggerPickerDisabled: productsState.processing,
                onRequestModalPickerOpen: (function (param) {
                    Curry._1(setPickerOpened, (function (param) {
                            return true;
                          }));
                  })
              });
  };
  var renderActionsEnd = function (param) {
    return React.createElement(StockTransferFormActions$Pos.make, {});
  };
  var columns = tableColumns(productsDispatch);
  var errors = formState.validation;
  var productsError;
  productsError = errors.TAG === /* Ok */0 ? undefined : Belt_Array.get(Belt_Array.keepMap(errors._0, (function (param) {
                if (param[0]._0 >= 2) {
                  return param[1];
                }
                
              })), 0);
  var match$4 = formState.submission;
  var tmp;
  tmp = productsError !== undefined && !(typeof match$4 === "number" || match$4.TAG !== /* Failed */0) ? Intl$Pos.t(productsError) : undefined;
  var tmp$1 = {
    message: Intl$Pos.t("Add products to complete your transfer")
  };
  if (tmp !== undefined) {
    tmp$1.errorMessage = Caml_option.valFromOption(tmp);
  }
  return React.createElement(React.Fragment, undefined, React.createElement(Card$Pos.make, {
                  children: null,
                  variation: "table"
                }, React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(StockTransferFormProductsCard$AddProductModalButton, {
                                  triggerPickerDisabled: productsState.processing,
                                  onRequestModalPickerOpen: (function (param) {
                                      Curry._1(setPickerOpened, (function (param) {
                                              return true;
                                            }));
                                    })
                                })), "large", undefined, undefined, "xlarge", undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(TableView$Pos.make, {
                      data: {
                        TAG: /* Done */1,
                        _0: {
                          TAG: /* Ok */0,
                          _0: match$3[0]
                        }
                      },
                      columns: columns,
                      keyExtractor: keyExtractor,
                      erroredRowsMap: productsRowErrors,
                      placeholderEmptyState: React.createElement(make, tmp$1)
                    })), React.createElement(StockTransferFormProductPickerModalTable$Pos.make, {
                  opened: match$2[0],
                  senderShopId: formState.values.senderShopId,
                  recipientShopId: formState.values.recipientShopId,
                  disabledIds: Belt_Array.map(productsState.products, (function (product) {
                          return product.senderVariantId;
                        })),
                  onCommit: (function (products) {
                      Curry._1(productsDispatch, {
                            TAG: /* ProductBulkAddRequested */0,
                            _0: products
                          });
                    }),
                  onRequestClose: (function (param) {
                      Curry._1(setPickerOpened, (function (param) {
                              return false;
                            }));
                    })
                }), React.createElement(SpinnerModal$Pos.make, {
                  title: Intl$Pos.t("Loading products"),
                  opened: productsState.processing
                }), React.createElement(PageBottomActionsBar$Pos.make, {
                  displayThreshold: 180,
                  renderStart: renderActionsStart,
                  renderEnd: renderActionsEnd,
                  navOpened: match[0].opened
                }));
}

var make$1 = React.memo(StockTransferFormProductsCard);

export {
  AddProductModalButton ,
  TablePlaceholder ,
  Row ,
  tableColumns ,
  make$1 as make,
}
/* style Not a pure module */
