// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Box$Pos from "../../resources/layout-and-structure/Box.bs.js";
import * as Env$Pos from "../../core/Env.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Auth$Pos from "../../bundles/Auth/Auth.bs.js";
import * as Form$Pos from "../../bundles/Form/Form.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Json$Pos from "../../primitives/Json.bs.js";
import * as View$Pos from "../../primitives/View.bs.js";
import * as Label$Pos from "../../primitives/Label.bs.js";
import * as Modal$Pos from "../../resources/overlays/Modal.bs.js";
import * as Stack$Pos from "../../resources/layout-and-structure/Stack.bs.js";
import * as Style$Pos from "../../primitives/Style.bs.js";
import * as Banner$Pos from "../../resources/feedback-indicators/Banner.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Button$Pos from "../../resources/actions/Button.bs.js";
import * as Colors$Pos from "../../resources/theme/Colors.bs.js";
import * as Inline$Pos from "../../resources/layout-and-structure/Inline.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Request$Pos from "../../core/Request.bs.js";
import * as TextStyle$Pos from "../../resources/typography/TextStyle.bs.js";
import * as IconButton$Pos from "../../resources/actions/IconButton.bs.js";
import * as StackFields$Pos from "../../resources/layout-and-structure/StackFields.bs.js";
import * as FieldsetLayoutPanel$Pos from "../../resources/layout-and-structure/FieldsetLayoutPanel.bs.js";
import * as ResourceDetailsPage$Pos from "../../core/ResourceDetailsPage.bs.js";

var endpoint = Env$Pos.gatewayUrl(undefined) + "/auth/password-update";

function encodeBody(currentPassword, newPassword) {
  return Json$Pos.encodeDict(Js_dict.fromArray([
                  [
                    "currentPassword",
                    Json$Pos.encodeString(currentPassword)
                  ],
                  [
                    "newPassword",
                    Json$Pos.encodeString(newPassword)
                  ]
                ]));
}

function decodeInvalidRequestFailure(error) {
  if (error.kind === "WrongUserPassword") {
    return /* WrongUserPassword */0;
  } else {
    return /* UnknownServerFailure */1;
  }
}

function make(currentPassword, newPassword) {
  return Future.mapError(Request$Pos.make("POST", Caml_option.some(encodeBody(currentPassword, newPassword)), undefined, undefined, endpoint), undefined, (function (error) {
                if (typeof error === "number" || error.TAG !== /* InvalidRequestFailures */2) {
                  return ;
                } else {
                  return Belt_Option.map(Belt_Array.get(error._0, 0), decodeInvalidRequestFailure);
                }
              }));
}

var UpdatePasswordRequest = {
  endpoint: endpoint,
  encodeBody: encodeBody,
  decodeInvalidRequestFailure: decodeInvalidRequestFailure,
  make: make
};

function get(values, field) {
  switch (field) {
    case /* CurrentPassword */0 :
        return values.currentPassword;
    case /* NewPassword */1 :
        return values.newPassword;
    case /* NewPasswordConfirmation */2 :
        return values.newPasswordConfirmation;
    
  }
}

function set(values, field, value) {
  switch (field) {
    case /* CurrentPassword */0 :
        return {
                currentPassword: value,
                newPassword: values.newPassword,
                newPasswordConfirmation: values.newPasswordConfirmation
              };
    case /* NewPassword */1 :
        return {
                currentPassword: values.currentPassword,
                newPassword: value,
                newPasswordConfirmation: values.newPasswordConfirmation
              };
    case /* NewPasswordConfirmation */2 :
        return {
                currentPassword: values.currentPassword,
                newPassword: values.newPassword,
                newPasswordConfirmation: value
              };
    
  }
}

var EditPasswordFormLenses = {
  get: get,
  set: set
};

var EditPasswordForm = Form$Pos.Make(EditPasswordFormLenses);

var schema = [
  {
    TAG: /* StringNotEmpty */1,
    _0: /* CurrentPassword */0
  },
  {
    TAG: /* Password */6,
    _0: /* NewPassword */1
  },
  {
    TAG: /* StringNotEmpty */1,
    _0: /* NewPasswordConfirmation */2
  },
  {
    TAG: /* CustomString */8,
    _0: /* NewPasswordConfirmation */2,
    _1: (function (value, values) {
        if (values.newPassword === value) {
          return {
                  TAG: /* Ok */0,
                  _0: undefined
                };
        } else {
          return {
                  TAG: /* Error */1,
                  _0: Intl$Pos.t("Password and its confirmation must be identical")
                };
        }
      })
  }
];

function SettingsUserPage$EditPasswordFormModal(Props) {
  var onRequestClose = Props.onRequestClose;
  var opened = Props.opened;
  var onNotification = Props.onNotification;
  var updatePasswordRequest = Props.updatePasswordRequest;
  var match = React.useState(function () {
        
      });
  var setError = match[1];
  var error = match[0];
  var onSubmit = function (param, param$1) {
    return Future.mapError(Future.mapOk(Curry._2(updatePasswordRequest, param$1.currentPassword, param$1.newPassword), undefined, (function (param) {
                      return Intl$Pos.t("Your user and account information has been successfully updated.");
                    })), undefined, (function (error) {
                  if (error !== undefined && !error) {
                    return Intl$Pos.t("Wrong user password provided.");
                  } else {
                    return Intl$Pos.t("An unexpected error occured. Please try again or contact the support.");
                  }
                }));
  };
  var onSubmitSuccess = function (param) {
    var success = Intl$Pos.t("Your user and account information has been successfully updated.");
    Curry._1(onNotification, {
          TAG: /* Success */0,
          _0: success
        });
    Curry._1(onRequestClose, undefined);
  };
  var onSubmitFailure = function (message) {
    Curry._1(setError, (function (param) {
            return message;
          }));
  };
  var formPropState = Curry._1(EditPasswordForm.useFormPropState, {
        initialValues: {
          currentPassword: "",
          newPassword: "",
          newPasswordConfirmation: ""
        },
        schema: schema,
        resetValuesAfterSubmission: true,
        onSubmitFailure: onSubmitFailure,
        onSubmitSuccess: onSubmitSuccess
      });
  return React.createElement(Modal$Pos.make, {
              children: React.createElement(EditPasswordForm.Core.Provider.make, {
                    children: null,
                    propState: formPropState
                  }, error !== undefined ? React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(Banner$Pos.make, {
                                      textStatus: {
                                        TAG: /* Danger */1,
                                        _0: error
                                      },
                                      onRequestClose: (function (param) {
                                          Curry._1(setError, (function (param) {
                                                  
                                                }));
                                        })
                                    })), "xlarge", undefined, "large", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)) : null, React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(null), "xlarge", undefined, "xlarge", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), React.createElement(StackFields$Pos.make, {
                            children: null
                          }, React.createElement(EditPasswordForm.InputPassword.make, {
                                field: /* CurrentPassword */0,
                                label: Intl$Pos.t("Current password"),
                                placeholder: Intl$Pos.t("Enter your current password"),
                                hideError: true,
                                hideRequired: true
                              }), React.createElement(EditPasswordForm.InputPassword.make, {
                                field: /* NewPassword */1,
                                label: Intl$Pos.t("New password"),
                                placeholder: Intl$Pos.t("Enter your new password"),
                                hideRequired: true,
                                showTypingValidation: true
                              }), React.createElement(EditPasswordForm.InputPassword.make, {
                                field: /* NewPasswordConfirmation */2,
                                label: Intl$Pos.t("New password confirmation"),
                                placeholder: Intl$Pos.t("Confirm your new password"),
                                hideRequired: true
                              })), React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(Inline$Pos.make, {
                                        children: null,
                                        space: "xmedium",
                                        align: "end"
                                      }, React.createElement(Button$Pos.make, Button$Pos.makeProps(Intl$Pos.t("Cancel"), undefined, "neutral", undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (param) {
                                                  Curry._1(onRequestClose, undefined);
                                                }), undefined, undefined, undefined)), React.createElement(EditPasswordForm.SubmitButton.make, {
                                            variation: "success",
                                            size: "normal",
                                            onSubmit: onSubmit,
                                            text: Intl$Pos.t("Save")
                                          }))), undefined, "xlarge", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)))),
              opened: opened,
              title: Intl$Pos.t("Update password"),
              hideFooter: true,
              onRequestClose: onRequestClose
            });
}

var make$1 = React.memo(SettingsUserPage$EditPasswordFormModal);

var EditPasswordFormModal = {
  schema: schema,
  make: make$1
};

function encodeBody$1(newUsername, password) {
  return Json$Pos.encodeDict(Js_dict.fromArray([
                  [
                    "newUsername",
                    Json$Pos.encodeString(newUsername)
                  ],
                  [
                    "password",
                    Json$Pos.encodeString(password)
                  ]
                ]));
}

var endpoint$1 = Env$Pos.gatewayUrl(undefined) + "/auth/username-update";

function decodeInvalidRequestFailure$1(error) {
  switch (error.kind) {
    case "DuplicateUserUsername" :
        return /* DuplicateUserUsername */0;
    case "WrongUserPassword" :
        return /* WrongUserPassword */1;
    default:
      return /* UnknownServerFailure */2;
  }
}

function make$2(newUsername, password) {
  return Future.mapError(Request$Pos.make("POST", Caml_option.some(encodeBody$1(newUsername, password)), undefined, undefined, endpoint$1), undefined, (function (error) {
                if (typeof error === "number" || error.TAG !== /* InvalidRequestFailures */2) {
                  return ;
                } else {
                  return Belt_Option.map(Belt_Array.get(error._0, 0), decodeInvalidRequestFailure$1);
                }
              }));
}

var UpdateEmailRequest = {
  encodeBody: encodeBody$1,
  endpoint: endpoint$1,
  decodeInvalidRequestFailure: decodeInvalidRequestFailure$1,
  make: make$2
};

function get$1(values, field) {
  if (field) {
    return values.password;
  } else {
    return values.newUsername;
  }
}

function set$1(values, field, value) {
  if (field) {
    return {
            newUsername: values.newUsername,
            password: value
          };
  } else {
    return {
            newUsername: value,
            password: values.password
          };
  }
}

var EditEmailFormLenses = {
  get: get$1,
  set: set$1
};

var EditEmailForm = Form$Pos.Make(EditEmailFormLenses);

var schema$1 = [
  {
    TAG: /* Email */5,
    _0: /* NewUsername */0
  },
  {
    TAG: /* StringNotEmpty */1,
    _0: /* Password */1
  }
];

function SettingsUserPage$EditEmailModal(Props) {
  var onRequestClose = Props.onRequestClose;
  var opened = Props.opened;
  var currentUsername = Props.currentUsername;
  var onNotification = Props.onNotification;
  var updateEmailRequest = Props.updateEmailRequest;
  var logUser = Auth$Pos.useLogUser(undefined, undefined);
  var jwt = Belt_Option.getWithDefault(Auth$Pos.getJwt(undefined), "");
  var match = React.useState(function () {
        
      });
  var setError = match[1];
  var error = match[0];
  var onSubmit = function (param, param$1) {
    return Future.mapError(Future.mapOk(Curry._2(updateEmailRequest, param$1.newUsername, param$1.password), undefined, (function (param) {
                      return Intl$Pos.t("Your email address has been updated successfully.");
                    })), undefined, (function (failure) {
                  if (failure === undefined) {
                    return Intl$Pos.t("An unexpected error occured. Please try again or contact the support.");
                  }
                  switch (failure) {
                    case /* DuplicateUserUsername */0 :
                        return Intl$Pos.t("This email address is already used.");
                    case /* WrongUserPassword */1 :
                        return Intl$Pos.t("Wrong user password provided.");
                    case /* UnknownServerFailure */2 :
                        return Intl$Pos.t("An unexpected error occured. Please try again or contact the support.");
                    
                  }
                }));
  };
  var onSubmitSuccess = function (param) {
    Curry._1(logUser, jwt);
    Curry._1(onNotification, {
          TAG: /* Warning */2,
          _0: Intl$Pos.t("You will receive a link by email to confirm the email address change within a few minutes.")
        });
    Curry._1(onRequestClose, undefined);
  };
  var onSubmitFailure = function (message) {
    Curry._1(setError, (function (param) {
            return message;
          }));
  };
  var formPropState = Curry._1(EditEmailForm.useFormPropState, {
        initialValues: {
          newUsername: currentUsername,
          password: ""
        },
        schema: schema$1,
        resetValuesAfterSubmission: true,
        onSubmitFailure: onSubmitFailure,
        onSubmitSuccess: onSubmitSuccess
      });
  return React.createElement(Modal$Pos.make, {
              children: React.createElement(EditEmailForm.Core.Provider.make, {
                    children: null,
                    propState: formPropState
                  }, error !== undefined ? React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(Banner$Pos.make, {
                                      textStatus: {
                                        TAG: /* Danger */1,
                                        _0: error
                                      },
                                      onRequestClose: (function (param) {
                                          Curry._1(setError, (function (param) {
                                                  
                                                }));
                                        })
                                    })), "xlarge", undefined, "large", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)) : null, React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(null), "xlarge", undefined, "xlarge", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), React.createElement(StackFields$Pos.make, {
                            children: null
                          }, React.createElement(EditEmailForm.InputText.make, {
                                field: /* NewUsername */0,
                                label: Intl$Pos.t("Email"),
                                hideRequired: true
                              }), React.createElement(EditEmailForm.InputPassword.make, {
                                field: /* Password */1,
                                label: Intl$Pos.t("Current password"),
                                placeholder: Intl$Pos.t("Enter your current password"),
                                hideError: true,
                                hideRequired: true
                              })), React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(Inline$Pos.make, {
                                        children: null,
                                        space: "xmedium",
                                        align: "end"
                                      }, React.createElement(Button$Pos.make, Button$Pos.makeProps(Intl$Pos.t("Cancel"), undefined, "neutral", undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (param) {
                                                  Curry._1(onRequestClose, undefined);
                                                }), undefined, undefined, undefined)), React.createElement(EditEmailForm.SubmitButton.make, {
                                            variation: "success",
                                            size: "normal",
                                            onSubmit: onSubmit,
                                            text: Intl$Pos.t("Save")
                                          }))), undefined, "xlarge", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)))),
              opened: opened,
              title: Intl$Pos.t("Update email"),
              hideFooter: true,
              onRequestClose: onRequestClose
            });
}

var make$3 = React.memo(SettingsUserPage$EditEmailModal);

var EditEmailModal = {
  schema: schema$1,
  make: make$3
};

function SettingsUserPage$EditEmailAndPasswordFieldset(Props) {
  var username = Props.username;
  var onNotification = Props.onNotification;
  var updateEmailRequest = Props.updateEmailRequest;
  var updatePasswordRequest = Props.updatePasswordRequest;
  var match = React.useState(function () {
        return false;
      });
  var setUpdatePasswordModalOpened = match[1];
  var match$1 = React.useState(function () {
        return false;
      });
  var setUpdateUsernameModalOpened = match$1[1];
  var handleEmailClick = function (param) {
    Curry._1(setUpdateUsernameModalOpened, (function (param) {
            return true;
          }));
    Curry._1(onNotification, undefined);
  };
  var handlePasswordClick = function (param) {
    Curry._1(setUpdatePasswordModalOpened, (function (param) {
            return true;
          }));
    Curry._1(onNotification, undefined);
  };
  return React.createElement(React.Fragment, undefined, React.createElement(FieldsetLayoutPanel$Pos.make, {
                  title: Intl$Pos.t("Login details"),
                  description: Intl$Pos.t("To update your login credentials, you will need to enter your current password and confirm the changes through a link sent to your current email inbox."),
                  children: React.createElement(Stack$Pos.make, {
                        children: null,
                        space: "small"
                      }, React.createElement(Stack$Pos.make, {
                            children: null,
                            space: "none"
                          }, React.createElement(Label$Pos.make, {
                                text: Intl$Pos.t("Email")
                              }), React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(React.createElement(Inline$Pos.make, {
                                            children: null
                                          }, React.createElement(TextStyle$Pos.make, {
                                                children: username
                                              }), React.createElement(IconButton$Pos.make, IconButton$Pos.makeProps("edit_light", undefined, "small", Colors$Pos.neutralColor100, Colors$Pos.brandColor60, undefined, undefined, handleEmailClick, undefined, undefined, undefined)))), Caml_option.some(Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(-8)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), undefined, undefined, undefined))), React.createElement(Stack$Pos.make, {
                            children: null,
                            space: "none"
                          }, React.createElement(Label$Pos.make, {
                                text: Intl$Pos.t("Password")
                              }), React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(React.createElement(Inline$Pos.make, {
                                            children: null
                                          }, React.createElement(TextStyle$Pos.make, {
                                                children: "● ● ● ● ● ● ● ● ● ● ● ● "
                                              }), React.createElement(IconButton$Pos.make, IconButton$Pos.makeProps("edit_light", undefined, "small", Colors$Pos.neutralColor100, Colors$Pos.brandColor60, undefined, undefined, handlePasswordClick, undefined, undefined, undefined)))), Caml_option.some(Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(-8)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), undefined, undefined, undefined))))
                }), React.createElement(make$3, {
                  onRequestClose: (function (param) {
                      Curry._1(setUpdateUsernameModalOpened, (function (param) {
                              return false;
                            }));
                    }),
                  opened: match$1[0],
                  currentUsername: username,
                  onNotification: onNotification,
                  updateEmailRequest: updateEmailRequest
                }), React.createElement(make$1, {
                  onRequestClose: (function (param) {
                      Curry._1(setUpdatePasswordModalOpened, (function (param) {
                              return false;
                            }));
                    }),
                  opened: match[0],
                  onNotification: onNotification,
                  updatePasswordRequest: updatePasswordRequest
                }));
}

var make$4 = React.memo(SettingsUserPage$EditEmailAndPasswordFieldset);

var EditEmailAndPasswordFieldset = {
  make: make$4
};

function get$2(values, field) {
  if (field) {
    return values.organizationName;
  } else {
    return values.name;
  }
}

function set$2(values, field, value) {
  if (field) {
    return {
            name: values.name,
            organizationName: value
          };
  } else {
    return {
            name: value,
            organizationName: values.organizationName
          };
  }
}

var EditUserNamesFormLenses = {
  get: get$2,
  set: set$2
};

var EditUserNamesForm = Form$Pos.Make(EditUserNamesFormLenses);

function encodeBody$2(name, organizationName) {
  return Json$Pos.encodeDict(Js_dict.fromArray([
                  [
                    "name",
                    Json$Pos.encodeString(name)
                  ],
                  [
                    "organizationName",
                    Json$Pos.encodeString(organizationName)
                  ]
                ]));
}

var endpoint$2 = Env$Pos.gatewayUrl(undefined) + "/user";

function make$5(name, organizationName) {
  return Request$Pos.make("PATCH", Caml_option.some(encodeBody$2(name, organizationName)), undefined, undefined, endpoint$2);
}

var UpdateUserNamesRequest = {
  encodeBody: encodeBody$2,
  endpoint: endpoint$2,
  make: make$5
};

var schema$2 = [];

function SettingsUserPage$EditUserNamesFieldset(Props) {
  var name = Props.name;
  var organizationName = Props.organizationName;
  var onNotification = Props.onNotification;
  var updateUserNamesRequest = Props.updateUserNamesRequest;
  var jwt = Belt_Option.getWithDefault(Auth$Pos.getJwt(undefined), "");
  var logUser = Auth$Pos.useLogUser(undefined, undefined);
  var onSubmitSuccess = function (param) {
    Curry._1(logUser, jwt);
    Curry._1(onNotification, {
          TAG: /* Success */0,
          _0: Intl$Pos.t("Your user and account information has been successfully updated.")
        });
  };
  var onSubmitFailure = function (error) {
    Curry._1(onNotification, {
          TAG: /* Danger */1,
          _0: error
        });
  };
  var formPropState = Curry._1(EditUserNamesForm.useFormPropState, {
        initialValues: {
          name: name,
          organizationName: organizationName
        },
        schema: schema$2,
        onSubmitFailure: onSubmitFailure,
        onSubmitSuccess: onSubmitSuccess
      });
  var onSubmit = function (param, param$1) {
    Curry._1(onNotification, undefined);
    return Future.mapError(Future.mapOk(Curry._2(updateUserNamesRequest, param$1.name, param$1.organizationName), undefined, (function (param) {
                      return Intl$Pos.t("Your user and account information has been successfully updated.");
                    })), undefined, (function (param) {
                  return Intl$Pos.t("An unexpected error occured. Please try again or contact the support.");
                }));
  };
  return React.createElement(FieldsetLayoutPanel$Pos.make, {
              title: Intl$Pos.t("Account name and Username"),
              description: Intl$Pos.t("The account name is the name that appears at the top of the interface menu. The username is your name that appears at the bottom of the menu."),
              children: React.createElement(EditUserNamesForm.Core.Provider.make, {
                    children: null,
                    propState: formPropState
                  }, React.createElement(EditUserNamesForm.ControlEnterKey.make, {
                        onSubmit: onSubmit
                      }), React.createElement(Stack$Pos.make, {
                        children: null,
                        space: "large"
                      }, React.createElement(EditUserNamesForm.InputText.make, {
                            field: /* OrganizationName */1,
                            label: Intl$Pos.t("Account name")
                          }), React.createElement(EditUserNamesForm.InputText.make, {
                            field: /* Name */0,
                            label: Intl$Pos.t("Username"),
                            hideError: true
                          }), React.createElement(Inline$Pos.make, {
                            children: React.createElement(EditUserNamesForm.SubmitButton.make, {
                                  variation: "success",
                                  size: "xsmall",
                                  onSubmit: onSubmit,
                                  text: "  " + Intl$Pos.t("Save") + "  "
                                }),
                            space: "small",
                            align: "end"
                          })))
            });
}

var make$6 = React.memo(SettingsUserPage$EditUserNamesFieldset);

var EditUserNamesFieldset = {
  schema: schema$2,
  make: make$6
};

function SettingsUserPage(Props) {
  var updateEmailRequest = Props.updateEmailRequest;
  var updatePasswordRequest = Props.updatePasswordRequest;
  var updateUserNamesRequest = Props.updateUserNamesRequest;
  var match = React.useState(function () {
        
      });
  var setNotification = match[1];
  var notification = match[0];
  var auth = Auth$Pos.useState(undefined);
  var match$1;
  if (typeof auth === "number" || auth.TAG === /* Logging */0) {
    match$1 = [
      "",
      "",
      ""
    ];
  } else {
    var match$2 = auth._0.user;
    match$1 = [
      match$2.username,
      match$2.name,
      match$2.organizationName
    ];
  }
  var handleNotification = function (notification) {
    Curry._1(setNotification, (function (param) {
            return notification;
          }));
  };
  var notificationBanner = notification !== undefined ? React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(Banner$Pos.make, {
                      textStatus: notification,
                      onRequestClose: (function (param) {
                          Curry._1(setNotification, (function (param) {
                                  
                                }));
                        })
                    })), undefined, undefined, "medium", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)) : null;
  return React.createElement(ResourceDetailsPage$Pos.make, {
              title: Intl$Pos.t("Your account"),
              notificationBanner: notificationBanner,
              children: React.createElement(Stack$Pos.make, {
                    children: null,
                    space: "xlarge"
                  }, React.createElement(make$4, {
                        username: match$1[0],
                        onNotification: handleNotification,
                        updateEmailRequest: updateEmailRequest,
                        updatePasswordRequest: updatePasswordRequest
                      }), React.createElement(make$6, {
                        name: match$1[1],
                        organizationName: match$1[2],
                        onNotification: handleNotification,
                        updateUserNamesRequest: updateUserNamesRequest
                      }))
            });
}

var updatePasswordRequest = make;

var updateEmailRequest = make$2;

var updateUserNamesRequest = make$5;

var make$7 = SettingsUserPage;

export {
  UpdatePasswordRequest ,
  updatePasswordRequest ,
  EditPasswordFormLenses ,
  EditPasswordForm ,
  EditPasswordFormModal ,
  UpdateEmailRequest ,
  updateEmailRequest ,
  EditEmailFormLenses ,
  EditEmailForm ,
  EditEmailModal ,
  EditEmailAndPasswordFieldset ,
  EditUserNamesFormLenses ,
  EditUserNamesForm ,
  UpdateUserNamesRequest ,
  updateUserNamesRequest ,
  EditUserNamesFieldset ,
  make$7 as make,
}
/* endpoint Not a pure module */
