// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Box$Pos from "../../resources/layout-and-structure/Box.bs.js";
import * as Auth$Pos from "../../bundles/Auth/Auth.bs.js";
import * as Form$Pos from "../../bundles/Form/Form.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Modal$Pos from "../../resources/overlays/Modal.bs.js";
import * as Stack$Pos from "../../resources/layout-and-structure/Stack.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Button$Pos from "../../resources/actions/Button.bs.js";
import * as Inline$Pos from "../../resources/layout-and-structure/Inline.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Client from "@apollo/client";
import * as ApolloHelpers$Pos from "../../helpers/ApolloHelpers.bs.js";
import * as StockActivityQuantity$Pos from "../StockActivity/StockActivityQuantity.bs.js";
import * as ApolloClient__React_Hooks_UseMutation from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseMutation.bs.js";

function get(values, field) {
  switch (field) {
    case /* ShopId */0 :
        return values.shopId;
    case /* DeviceId */1 :
        return values.deviceId;
    case /* VariantId */2 :
        return values.variantId;
    case /* VariantCapacityUnit */3 :
        return values.variantCapacityUnit;
    case /* VariantCapacityPrecision */4 :
        return values.variantCapacityPrecision;
    case /* Quantity */5 :
        return values.quantity;
    case /* Comment */6 :
        return values.comment;
    
  }
}

function set(values, field, value) {
  switch (field) {
    case /* ShopId */0 :
        return {
                shopId: value,
                deviceId: values.deviceId,
                variantId: values.variantId,
                variantCapacityUnit: values.variantCapacityUnit,
                variantCapacityPrecision: values.variantCapacityPrecision,
                quantity: values.quantity,
                comment: values.comment
              };
    case /* DeviceId */1 :
        return {
                shopId: values.shopId,
                deviceId: value,
                variantId: values.variantId,
                variantCapacityUnit: values.variantCapacityUnit,
                variantCapacityPrecision: values.variantCapacityPrecision,
                quantity: values.quantity,
                comment: values.comment
              };
    case /* VariantId */2 :
        return {
                shopId: values.shopId,
                deviceId: values.deviceId,
                variantId: value,
                variantCapacityUnit: values.variantCapacityUnit,
                variantCapacityPrecision: values.variantCapacityPrecision,
                quantity: values.quantity,
                comment: values.comment
              };
    case /* VariantCapacityUnit */3 :
        return {
                shopId: values.shopId,
                deviceId: values.deviceId,
                variantId: values.variantId,
                variantCapacityUnit: value,
                variantCapacityPrecision: values.variantCapacityPrecision,
                quantity: values.quantity,
                comment: values.comment
              };
    case /* VariantCapacityPrecision */4 :
        return {
                shopId: values.shopId,
                deviceId: values.deviceId,
                variantId: values.variantId,
                variantCapacityUnit: values.variantCapacityUnit,
                variantCapacityPrecision: value,
                quantity: values.quantity,
                comment: values.comment
              };
    case /* Quantity */5 :
        return {
                shopId: values.shopId,
                deviceId: values.deviceId,
                variantId: values.variantId,
                variantCapacityUnit: values.variantCapacityUnit,
                variantCapacityPrecision: values.variantCapacityPrecision,
                quantity: value,
                comment: values.comment
              };
    case /* Comment */6 :
        return {
                shopId: values.shopId,
                deviceId: values.deviceId,
                variantId: values.variantId,
                variantCapacityUnit: values.variantCapacityUnit,
                variantCapacityPrecision: values.variantCapacityPrecision,
                quantity: values.quantity,
                comment: value
              };
    
  }
}

var CatalogVariantDeliveryReportFormLenses = {
  get: get,
  set: set
};

var CatalogVariantDeliveryReportForm = Form$Pos.Make(CatalogVariantDeliveryReportFormLenses);

var Raw = {};

var query = Client.gql(["mutation CreateStockActivity($input: InputCreateStockActivity!)  {\ncreateStockActivity(input: $input)  {\n__typename  \nid  \n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.createStockActivity;
  return {
          createStockActivity: {
            __typename: value$1.__typename,
            id: value$1.id
          }
        };
}

function serialize(value) {
  var value$1 = value.createStockActivity;
  var value$2 = value$1.id;
  var value$3 = value$1.__typename;
  var createStockActivity = {
    __typename: value$3,
    id: value$2
  };
  return {
          createStockActivity: createStockActivity
        };
}

function serializeInputObjectInputCreateStockActivity(inp) {
  var a = inp.kind;
  var a$1 = inp.comment;
  var a$2 = inp.reason;
  var a$3 = inp.stockKeepingUnit;
  var a$4 = inp.capacityUnit;
  var a$5 = inp.capacityPrecision;
  return {
          kind: a === "DELIVERY_RECEIPT" ? "DELIVERY_RECEIPT" : (
              a === "RESET" ? "RESET" : (
                  a === "INCOMING_TRANSFER" ? "INCOMING_TRANSFER" : (
                      a === "DELIVERY" ? "DELIVERY" : (
                          a === "REFUND" ? "REFUND" : (
                              a === "LOSS" ? "LOSS" : (
                                  a === "CREDIT_MEMO" ? "CREDIT_MEMO" : (
                                      a === "SALE" ? "SALE" : (
                                          a === "RECEPTION" ? "RECEPTION" : "OUTGOING_TRANSFER"
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            ),
          quantity: inp.quantity,
          comment: a$1 !== undefined ? a$1 : undefined,
          reason: a$2 !== undefined ? (
              a$2 === "DAMAGE" ? "DAMAGE" : (
                  a$2 === "OUTDATED_DLUO" ? "OUTDATED_DLUO" : (
                      a$2 === "SALE_BY_GLASS" ? "SALE_BY_GLASS" : (
                          a$2 === "THEFT" ? "THEFT" : (
                              a$2 === "TASTING" ? "TASTING" : (
                                  a$2 === "LOSS" ? "LOSS" : (
                                      a$2 === "OTHER" ? "OTHER" : (
                                          a$2 === "TRADE_IN_SUPPLIER" ? "TRADE_IN_SUPPLIER" : "CORKY"
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            ) : undefined,
          stockKeepingUnit: a$3 !== undefined ? a$3 : undefined,
          capacityUnit: a$4 !== undefined ? a$4 : undefined,
          capacityPrecision: a$5 !== undefined ? a$5 : undefined,
          variantId: inp.variantId,
          deviceId: inp.deviceId,
          shopId: inp.shopId
        };
}

function serializeVariables(inp) {
  return {
          input: serializeInputObjectInputCreateStockActivity(inp.input)
        };
}

function makeVariables(input, param) {
  return {
          input: input
        };
}

function makeInputObjectInputCreateStockActivity(kind, quantity, comment, reason, stockKeepingUnit, capacityUnit, capacityPrecision, variantId, deviceId, shopId, param) {
  return {
          kind: kind,
          quantity: quantity,
          comment: comment,
          reason: reason,
          stockKeepingUnit: stockKeepingUnit,
          capacityUnit: capacityUnit,
          capacityPrecision: capacityPrecision,
          variantId: variantId,
          deviceId: deviceId,
          shopId: shopId
        };
}

var CreateStockActivity_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputCreateStockActivity: serializeInputObjectInputCreateStockActivity,
  makeVariables: makeVariables,
  makeInputObjectInputCreateStockActivity: makeInputObjectInputCreateStockActivity
};

var include = ApolloClient__React_Hooks_UseMutation.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var CreateStockActivity_useWithVariables = include.useWithVariables;

var CreateStockActivity = {
  CreateStockActivity_inner: CreateStockActivity_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputCreateStockActivity: serializeInputObjectInputCreateStockActivity,
  makeVariables: makeVariables,
  makeInputObjectInputCreateStockActivity: makeInputObjectInputCreateStockActivity,
  use: use,
  useWithVariables: CreateStockActivity_useWithVariables
};

function useDeliveryReportMutation(cku) {
  var match = Curry.app(use, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var result = match[1];
  var mutate = match[0];
  return React.useCallback((function (param) {
                return function (state) {
                  var shopId = state.shopId;
                  if (shopId === undefined) {
                    return Future.value({
                                TAG: /* Error */1,
                                _0: Intl$Pos.t("Something went wrong")
                              });
                  }
                  var deviceId = state.deviceId;
                  if (deviceId !== undefined) {
                    var variantId = state.variantId;
                    if (variantId !== undefined) {
                      var comment = state.comment;
                      var capacityPrecision = state.variantCapacityPrecision;
                      var input = makeInputObjectInputCreateStockActivity("DELIVERY", StockActivityQuantity$Pos.toRawValue(capacityPrecision, state.quantity), comment.length !== 0 ? comment : undefined, undefined, undefined, state.variantCapacityUnit, capacityPrecision, variantId, deviceId, shopId, undefined);
                      return Future.tapOk(Future.mapOk(ApolloHelpers$Pos.mutationPromiseToFutureResult(Curry._8(mutate, undefined, undefined, undefined, undefined, undefined, undefined, undefined, {
                                              input: input
                                            })), undefined, (function (param) {
                                        return Caml_option.some(cku);
                                      })), (function (param) {
                                    var match = result.client;
                                    if (match !== undefined) {
                                      Curry._1(match.rescript_resetStore, undefined);
                                      return ;
                                    }
                                    
                                  }));
                    }
                    
                  }
                  return Future.value({
                              TAG: /* Error */1,
                              _0: Intl$Pos.t("Something went wrong")
                            });
                };
              }), []);
}

var defaultFormValues = {
  shopId: undefined,
  deviceId: undefined,
  variantId: undefined,
  variantCapacityUnit: undefined,
  variantCapacityPrecision: undefined,
  quantity: 0,
  comment: ""
};

function makeFormValues(shopId, shopsVariantStock, shops, param) {
  var maybeVariantStock = Belt_Array.getBy(shopsVariantStock, (function (shopVariantStock) {
          if (shopId !== undefined) {
            return shopId === shopVariantStock.shopId;
          } else {
            return false;
          }
        }));
  if (maybeVariantStock === undefined) {
    return defaultFormValues;
  }
  var shopId$1 = maybeVariantStock.shopId;
  var maybeShop = Belt_Array.getBy(shops, (function (shop) {
          return shop.id === shopId$1;
        }));
  if (maybeShop !== undefined) {
    return {
            shopId: shopId$1,
            deviceId: maybeShop.activeWebDeviceId,
            variantId: maybeVariantStock.variantId,
            variantCapacityUnit: maybeVariantStock.capacityUnit,
            variantCapacityPrecision: maybeVariantStock.capacityPrecision,
            quantity: 0,
            comment: ""
          };
  } else {
    return defaultFormValues;
  }
}

var schema = [
  {
    TAG: /* Custom */7,
    _0: /* ShopId */0,
    _1: (function (param) {
        if (param.shopId !== undefined) {
          return {
                  TAG: /* Ok */0,
                  _0: undefined
                };
        } else {
          return {
                  TAG: /* Error */1,
                  _0: Intl$Pos.t("Missing shop")
                };
        }
      })
  },
  {
    TAG: /* Custom */7,
    _0: /* Quantity */5,
    _1: (function (param) {
        var quantity = param.quantity;
        if (param.variantCapacityPrecision !== undefined) {
          if (quantity > 0) {
            return {
                    TAG: /* Ok */0,
                    _0: undefined
                  };
          } else {
            return {
                    TAG: /* Error */1,
                    _0: Intl$Pos.t("This value must be positive")
                  };
          }
        } else if (quantity >= 1) {
          return {
                  TAG: /* Ok */0,
                  _0: undefined
                };
        } else {
          return {
                  TAG: /* Error */1,
                  _0: Intl$Pos.t("This value must be positive")
                };
        }
      })
  }
];

function CatalogVariantDeliveryReportModal(Props) {
  var cku = Props.cku;
  var opened = Props.opened;
  var shopsVariantStock = Props.shopsVariantStock;
  var onRequestClose = Props.onRequestClose;
  var shops = Auth$Pos.useShops(undefined);
  var activeShop = Auth$Pos.useActiveShop(undefined);
  var onSubmit = useDeliveryReportMutation(cku);
  var formInitialShopId = Belt_Option.map(activeShop, (function (shop) {
          return shop.id;
        }));
  var formId = cku + Belt_Option.getWithDefault(formInitialShopId, "");
  var formInitialValues = React.useMemo((function () {
          return makeFormValues(formInitialShopId, shopsVariantStock, shops, undefined);
        }), [
        formInitialShopId,
        shopsVariantStock,
        shops
      ]);
  var onSubmitSuccess = function (param) {
    Curry._1(onRequestClose, undefined);
  };
  var match = Curry._1(CatalogVariantDeliveryReportForm.useFormPropState, {
        id: formId,
        initialValues: formInitialValues,
        schema: schema,
        resetValuesAfterSubmission: true,
        onSubmitSuccess: onSubmitSuccess
      });
  var formDispatch = match[1];
  var formState = match[0];
  var formValuesShopId = formState.values.shopId;
  React.useEffect((function () {
          var formValues = makeFormValues(formValuesShopId, shopsVariantStock, shops, undefined);
          Curry._1(formDispatch, {
                TAG: /* FieldsValuesChanged */1,
                _0: (function (values) {
                    return {
                            shopId: values.shopId,
                            deviceId: formValues.deviceId,
                            variantId: formValues.variantId,
                            variantCapacityUnit: formValues.variantCapacityUnit,
                            variantCapacityPrecision: formValues.variantCapacityPrecision,
                            quantity: values.quantity,
                            comment: values.comment
                          };
                  })
              });
        }), [formValuesShopId]);
  var unit = formState.values.variantCapacityUnit;
  var tmp = {
    field: /* Quantity */5,
    label: Intl$Pos.t("Quantity"),
    minValue: 0,
    precision: Belt_Option.getWithDefault(formState.values.variantCapacityPrecision, 0)
  };
  var tmp$1 = unit !== undefined ? ({
        TAG: /* Custom */1,
        _0: unit
      }) : undefined;
  if (tmp$1 !== undefined) {
    tmp.appender = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement(Modal$Pos.make, {
              children: React.createElement(CatalogVariantDeliveryReportForm.Core.Provider.make, {
                    children: React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(null), "xlarge", undefined, "xlarge", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), React.createElement(Stack$Pos.make, {
                              children: null,
                              space: "normal"
                            }, Belt_Option.isNone(activeShop) ? React.createElement(CatalogVariantDeliveryReportForm.InputSelect.make, {
                                    field: /* ShopId */0,
                                    label: Intl$Pos.t("Shop"),
                                    placeholder: Intl$Pos.t("Select a shop"),
                                    sections: [{
                                        items: Belt_Array.map(shopsVariantStock, (function (param) {
                                                var shopId = param.shopId;
                                                return {
                                                        key: shopId,
                                                        value: shopId,
                                                        label: param.shopName
                                                      };
                                              }))
                                      }]
                                  }) : null, React.createElement(CatalogVariantDeliveryReportForm.InputNumber.make, tmp), React.createElement(CatalogVariantDeliveryReportForm.InputTextArea.make, {
                                  field: /* Comment */6,
                                  label: Intl$Pos.t("Comment")
                                })), React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(Inline$Pos.make, {
                                          children: null,
                                          space: "xmedium",
                                          align: "end"
                                        }, React.createElement(Button$Pos.make, Button$Pos.makeProps(Intl$Pos.t("Cancel"), "medium", "neutral", undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (param) {
                                                    Curry._1(onRequestClose, undefined);
                                                  }), undefined, undefined, undefined)), React.createElement(CatalogVariantDeliveryReportForm.SubmitButton.make, {
                                              variation: "success",
                                              size: "medium",
                                              onSubmit: onSubmit,
                                              text: Intl$Pos.t("Save")
                                            }))), undefined, "xlarge", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined))),
                    propState: [
                      formState,
                      formDispatch
                    ]
                  }),
              opened: opened,
              title: Intl$Pos.t("Report a delivery"),
              hideFooter: true,
              onRequestClose: onRequestClose
            });
}

var make = React.memo(CatalogVariantDeliveryReportModal, (function (oldProps, newProps) {
        if (oldProps.cku === newProps.cku && oldProps.shopsVariantStock === newProps.shopsVariantStock) {
          return oldProps.opened === newProps.opened;
        } else {
          return false;
        }
      }));

var Config;

export {
  CatalogVariantDeliveryReportFormLenses ,
  CatalogVariantDeliveryReportForm ,
  CreateStockActivity ,
  useDeliveryReportMutation ,
  Config ,
  defaultFormValues ,
  makeFormValues ,
  schema ,
  make ,
}
/* CatalogVariantDeliveryReportForm Not a pure module */
