// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as CatalogVariant$Pos from "./CatalogVariant.bs.js";
import * as CatalogVariantEditForm$Pos from "./CatalogVariantEditForm.bs.js";
import * as CatalogPriceLookUpInput$Pos from "./CatalogPriceLookUpInput.bs.js";

function CatalogVariantEditFormInputs$InputVariantCapacityValueSelect(Props) {
  var productKind = Props.productKind;
  var match = Curry._1(CatalogVariantEditForm$Pos.useFormState, undefined).values;
  var inputPlaceholder = CatalogVariant$Pos.CapacityValue.placeholderFromProductKind(productKind);
  var unit = Belt_Option.getWithDefault(match.capacityUnit, "custom");
  var match$1 = unit === CatalogVariant$Pos.CapacityUnit.kg || unit === CatalogVariant$Pos.CapacityUnit.l ? [
      0.01,
      2
    ] : (
      unit === CatalogVariant$Pos.CapacityUnit.cl ? [
          0.1,
          1
        ] : (
          unit === CatalogVariant$Pos.CapacityUnit.ml || unit === CatalogVariant$Pos.CapacityUnit.g ? [
              10,
              0
            ] : [
              undefined,
              3
            ]
        )
    );
  var inputStep = match$1[0];
  var tmp = {
    field: /* CapacityValue */2,
    label: Intl$Pos.t("Capacity"),
    placeholder: inputPlaceholder,
    minValue: Belt_Option.getWithDefault(inputStep, 0),
    precision: match$1[1]
  };
  if (inputStep !== undefined) {
    tmp.step = Caml_option.valFromOption(inputStep);
  }
  return React.createElement(CatalogVariantEditForm$Pos.InputOptionalNumber.make, tmp);
}

var InputVariantCapacityValueSelect = {
  make: CatalogVariantEditFormInputs$InputVariantCapacityValueSelect
};

function CatalogVariantEditFormInputs$InputVariantCapacityUnitSelect(Props) {
  var productKind = Props.productKind;
  var disabled = Props.disabled;
  var match = Curry._1(CatalogVariantEditForm$Pos.useFormState, undefined).values;
  var bulk = match.bulk;
  var capacityUnit = match.capacityUnit;
  var dispatch = Curry._1(CatalogVariantEditForm$Pos.useFormDispatch, undefined);
  var options = CatalogVariant$Pos.CapacityUnit.makeOptions(productKind);
  React.useEffect((function () {
          if (bulk && Belt_Option.isNone(capacityUnit)) {
            Curry._1(dispatch, {
                  TAG: /* FieldValueChanged */2,
                  _0: /* CapacityUnit */1,
                  _1: (function (param) {
                      return Belt_Array.get(options, 0);
                    })
                });
          }
          
        }), [bulk]);
  var infotip = bulk ? (
      disabled ? Intl$Pos.t("The capacity unit cannot be changed.") : Intl$Pos.t("Once the product has been created in bulk, the capacity unit can't be modified.")
    ) : undefined;
  var defaultItem_label = Intl$Pos.template(Intl$Pos.t("Piece"), undefined, undefined);
  var defaultItem = {
    key: "default",
    label: defaultItem_label
  };
  var items = Belt_Array.map(options, (function (unit) {
          return {
                  key: unit,
                  value: unit,
                  label: unit
                };
        }));
  var sections = [{
      items: Belt_Array.concatMany(bulk ? [items] : [
              items,
              [defaultItem]
            ])
    }];
  var tmp = {
    field: /* CapacityUnit */1,
    label: Intl$Pos.t("Capacity unit"),
    placeholder: Intl$Pos.t("Select an unit"),
    disabled: disabled,
    searchable: false,
    sections: sections
  };
  if (infotip !== undefined) {
    tmp.infotip = Caml_option.valFromOption(infotip);
  }
  return React.createElement(CatalogVariantEditForm$Pos.InputSelect.make, tmp);
}

var InputVariantCapacityUnitSelect = {
  make: CatalogVariantEditFormInputs$InputVariantCapacityUnitSelect
};

function CatalogVariantEditFormInputs$InputVariantYear(Props) {
  var productKind = Props.productKind;
  return React.createElement(CatalogVariantEditForm$Pos.InputOptionalNumber.make, {
              field: /* Year */10,
              label: Intl$Pos.t("Vintage"),
              hideStepper: true,
              placeholder: CatalogVariant$Pos.Year.placeholderFromProductKind(productKind),
              useGrouping: false,
              minValue: 0,
              maxValue: new Date().getFullYear(),
              precision: 0
            });
}

var InputVariantYear = {
  make: CatalogVariantEditFormInputs$InputVariantYear
};

function CatalogVariantEditFormInputs$InputVariantAlcoholVolume(Props) {
  var productKind = Props.productKind;
  return React.createElement(CatalogVariantEditForm$Pos.InputOptionalNumber.make, {
              field: /* AlcoholVolume */9,
              label: Intl$Pos.t("Alcohol volume"),
              placeholder: CatalogVariant$Pos.Volume.placeholderFromProductKind(productKind),
              appender: /* Percent */0,
              minValue: 0,
              maxValue: 100
            });
}

var InputVariantAlcoholVolume = {
  make: CatalogVariantEditFormInputs$InputVariantAlcoholVolume
};

function CatalogVariantEditFormInputs$InputVariantPriceLookup(Props) {
  var match = Curry._1(CatalogVariantEditForm$Pos.useFormState, undefined);
  var validation = match.validation;
  var dispatch = Curry._1(CatalogVariantEditForm$Pos.useFormDispatch, undefined);
  var priceLookUpCodeError;
  priceLookUpCodeError = validation.TAG === /* Ok */0 ? undefined : Belt_Array.get(Belt_Array.keepMap(validation._0, (function (param) {
                if (param[0]._0 !== 6) {
                  return ;
                } else {
                  return param[1];
                }
              })), 0);
  var onAlreadyConsummedValuesFetched = function (values) {
    Curry._1(dispatch, {
          TAG: /* FieldValueChanged */2,
          _0: /* PriceLookUpCode */6,
          _1: (function (previous) {
              return {
                      invalidValues: values,
                      value: previous.value
                    };
            })
        });
  };
  var onChange = function (value) {
    Curry._1(dispatch, {
          TAG: /* FieldValueChanged */2,
          _0: /* PriceLookUpCode */6,
          _1: (function (previous) {
              return {
                      invalidValues: previous.invalidValues,
                      value: value
                    };
            })
        });
  };
  return React.createElement(CatalogPriceLookUpInput$Pos.make, {
              value: match.values.priceLookUpCode.value,
              errorMessage: priceLookUpCodeError,
              onAlreadyConsummedValuesFetched: onAlreadyConsummedValuesFetched,
              onChange: onChange
            });
}

var InputVariantPriceLookup = {
  make: CatalogVariantEditFormInputs$InputVariantPriceLookup
};

export {
  InputVariantCapacityValueSelect ,
  InputVariantCapacityUnitSelect ,
  InputVariantYear ,
  InputVariantAlcoholVolume ,
  InputVariantPriceLookup ,
}
/* react Not a pure module */
