// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Icon$Pos from "../images-and-icons/Icon.bs.js";
import * as Field$Pos from "../layout-and-structure/Field.bs.js";
import * as Hover$Pos from "../../primitives/Hover.bs.js";
import * as Colors$Pos from "../theme/Colors.bs.js";
import * as Spaces$Pos from "../theme/Spaces.bs.js";
import * as ReactAria from "react-aria";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as TextInput$Pos from "../../primitives/TextInput.bs.js";
import * as Touchable$Pos from "../../primitives/Touchable.bs.js";
import * as IconButton$Pos from "../actions/IconButton.bs.js";
import * as TextFieldStyle$Pos from "./TextFieldStyle.bs.js";
import * as ReactDomElement$Pos from "../../primitives/ReactDomElement.bs.js";

function InputTextField$EraseAppender(Props) {
  var onRequestInputFocus = Props.onRequestInputFocus;
  var onRequestInputClear = Props.onRequestInputClear;
  return React.createElement(IconButton$Pos.make, IconButton$Pos.makeProps("close_medium", 17, undefined, Colors$Pos.neutralColor25, Colors$Pos.neutralColor50, undefined, undefined, (function (param) {
                    Curry._1(onRequestInputFocus, undefined);
                    Curry._1(onRequestInputClear, undefined);
                  }), undefined, undefined, undefined));
}

function InputTextField$ListAppender(Props) {
  var inputFocused = Props.inputFocused;
  var inputErrored = Props.inputErrored;
  var inputHovered = Props.inputHovered;
  var disabled = Props.disabled;
  var opened = Props.opened;
  var toggleButtonProps = Props.toggleButtonProps;
  var match = Hover$Pos.use(undefined, undefined);
  var hovered = match[1];
  var match$1 = React.useState(function () {
        return false;
      });
  var setPressed = match$1[1];
  var toggleButtonProps$1 = ReactAria.mergeProps(toggleButtonProps, {
        elementType: "div",
        isDisabled: disabled,
        onPressStart: (function (param) {
            Curry._1(setPressed, (function (param) {
                    return true;
                  }));
          }),
        onPressEnd: (function (param) {
            Curry._1(setPressed, (function (param) {
                    return false;
                  }));
          })
      });
  var style = {
    backgroundColor: disabled ? Colors$Pos.neutralColor05 : (
        match$1[0] ? Colors$Pos.neutralColor10 : (
            hovered || opened ? Colors$Pos.neutralColor05 : Colors$Pos.transparent
          )
      ),
    borderLeft: "1px solid",
    borderLeftColor: TextFieldStyle$Pos.borderColor(inputFocused, inputHovered, inputErrored, disabled),
    display: "flex",
    height: "100%",
    marginRight: "-12px",
    alignItems: "center",
    flex: "0",
    flexBasis: "40px",
    justifyContent: "center"
  };
  var iconFillColor = disabled ? Colors$Pos.neutralColor20 : (
      hovered || opened ? Colors$Pos.neutralColor90 : Colors$Pos.neutralColor50
    );
  return React.createElement(Touchable$Pos.make, Touchable$Pos.makeProps(React.createElement(Icon$Pos.make, {
                      name: "arrow_down_light",
                      fill: iconFillColor,
                      size: 20
                    }), toggleButtonProps$1, disabled, undefined, undefined, Caml_option.some(style), (function (param) {
                    
                  }), undefined, match[0], undefined));
}

function style(focused, hovered, errored, disabled, bordered) {
  return {
          backgroundColor: TextFieldStyle$Pos.backgroundColor(disabled),
          border: bordered ? "1px solid" : "none",
          borderColor: TextFieldStyle$Pos.borderColor(focused, hovered, errored, disabled),
          display: "flex",
          height: "40px",
          overflow: "hidden",
          padding: "0 " + String(Spaces$Pos.normal) + "px",
          borderRadius: TextFieldStyle$Pos.borderRadiusPx,
          columnGap: String(Spaces$Pos.small) + "px",
          alignItems: "center",
          flexDirection: "row",
          boxSizing: "border-box"
        };
}

function textInputStyle(disabled) {
  return {
          color: TextFieldStyle$Pos.color(disabled),
          fontSize: TextFieldStyle$Pos.fontSizePx,
          height: "35px",
          width: "0",
          flex: "1",
          textOverflow: "ellipsis"
        };
}

var make = React.forwardRef(function (Props, ref) {
      var children = Props.children;
      var label = Props.label;
      var required = Props.required;
      var fieldAction = Props.fieldAction;
      var infotip = Props.infotip;
      var errorMessage = Props.errorMessage;
      var labelProps = Props.labelProps;
      return React.createElement(Field$Pos.make, Field$Pos.makeProps(children, label, labelProps, infotip, errorMessage, fieldAction, required, undefined, Caml_option.some(ref), undefined));
    });

function InputTextField(Props) {
  var variationOpt = Props.variation;
  var label = Props.label;
  var required = Props.required;
  var infotip = Props.infotip;
  var fieldAction = Props.fieldAction;
  var disabledOpt = Props.disabled;
  var borderedOpt = Props.bordered;
  var focusedOpt = Props.focused;
  var placeholder = Props.placeholder;
  var secureTextEntry = Props.secureTextEntry;
  var autoTrim = Props.autoTrim;
  var autoFocus = Props.autoFocus;
  var errorMessage = Props.errorMessage;
  var containerRef = Props.containerRef;
  var inputRef = Props.inputRef;
  var ariaProps = Props.ariaProps;
  var valueOpt = Props.value;
  var onChange = Props.onChange;
  var onFocus = Props.onFocus;
  var onBlur = Props.onBlur;
  var variation = variationOpt !== undefined ? variationOpt : "normal";
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var bordered = borderedOpt !== undefined ? borderedOpt : true;
  var focused = focusedOpt !== undefined ? focusedOpt : false;
  var value = valueOpt !== undefined ? valueOpt : "";
  var inputRef$1 = Belt_Option.getWithDefault(inputRef, React.useRef(null));
  var match = Hover$Pos.use(containerRef, undefined);
  var hovered = match[1];
  var match$1 = ReactAria.useLabel({
        label: label,
        "aria-label": label
      });
  var focusInput = function (param) {
    var input = inputRef$1.current;
    if (!(input == null)) {
      return ReactDomElement$Pos.focus(input);
    }
    
  };
  var errored = Belt_Option.isSome(errorMessage);
  var style$1 = style(focused, hovered, errored, disabled, bordered);
  var textInputStyle$1 = textInputStyle(disabled);
  var value$1 = Belt_Option.getWithDefault(Belt_Option.flatMap(ariaProps, (function (ariaProps) {
              return ariaProps.value;
            })), value);
  var ariaProps$1 = ReactAria.mergeProps(ariaProps, match$1.fieldProps);
  var tmp = {
    inputRef: inputRef$1,
    ariaProps: ariaProps$1,
    readOnly: disabled,
    style: textInputStyle$1,
    value: value$1
  };
  if (placeholder !== undefined) {
    tmp.placeholder = placeholder;
  }
  if (autoTrim !== undefined) {
    tmp.autoTrim = autoTrim;
  }
  if (secureTextEntry !== undefined) {
    tmp.secureTextEntry = secureTextEntry;
  }
  if (autoFocus !== undefined) {
    tmp.autoFocus = autoFocus;
  }
  if (onChange !== undefined) {
    tmp.onChange = Caml_option.valFromOption(onChange);
  }
  if (onFocus !== undefined) {
    tmp.onFocus = Caml_option.valFromOption(onFocus);
  }
  if (onBlur !== undefined) {
    tmp.onBlur = Caml_option.valFromOption(onBlur);
  }
  var tmp$1;
  if (typeof variation === "object" && variation.NAME === "suggestion") {
    var match$2 = variation.VAL;
    tmp$1 = React.createElement(InputTextField$ListAppender, {
          inputFocused: focused,
          inputErrored: errored,
          inputHovered: hovered,
          disabled: match$2.toggleButtonDisabled || disabled,
          opened: match$2.opened,
          toggleButtonProps: match$2.toggleButtonProps
        });
  } else {
    tmp$1 = null;
  }
  var tmp$2 = {
    children: React.createElement("div", {
          style: style$1
        }, React.createElement(TextInput$Pos.make, tmp), typeof variation === "object" && variation.NAME === "suggestion" && value$1 !== "" && !disabled ? React.createElement(InputTextField$EraseAppender, {
                onRequestInputFocus: focusInput,
                onRequestInputClear: variation.VAL.onRequestClear
              }) : null, tmp$1),
    label: label,
    errorMessage: errorMessage,
    labelProps: match$1.labelProps,
    ref: match[0]
  };
  if (required !== undefined) {
    tmp$2.required = Caml_option.valFromOption(required);
  }
  if (fieldAction !== undefined) {
    tmp$2.fieldAction = Caml_option.valFromOption(fieldAction);
  }
  if (infotip !== undefined) {
    tmp$2.infotip = infotip;
  }
  return React.createElement(make, tmp$2);
}

var make$1 = React.memo(InputTextField);

export {
  make$1 as make,
}
/* make Not a pure module */
