// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Card$Pos from "../../resources/layout-and-structure/Card.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as CatalogDuplicationForm$Pos from "./CatalogDuplicationForm.bs.js";

function CatalogDuplicationFormSourceCard(Props) {
  var shopsData = Props.shopsData;
  var references = Props.references;
  var duplicationMode = Props.duplicationMode;
  var availableShops = Belt_Array.keep(shopsData, (function (shop) {
          return shop.duplicability === /* ExistsAlready */1;
        }));
  var options = Belt_Array.map(availableShops, (function (shop) {
          var reference = Belt_Array.getBy(references, (function (param) {
                  return param.shopId === shop.dataId;
                }));
          if (duplicationMode) {
            if (reference !== undefined) {
              return {
                      TAG: /* Variant */1,
                      _0: {
                        id: reference.id,
                        shopId: reference.shopId
                      }
                    };
            } else {
              return ;
            }
          } else if (reference !== undefined) {
            return {
                    TAG: /* Product */0,
                    _0: {
                      id: reference.id,
                      shopId: reference.shopId
                    }
                  };
          } else {
            return ;
          }
        }));
  var optionToLabel = function (source) {
    return Belt_Option.mapWithDefault(Belt_Option.flatMap(source, (function (source) {
                      return Belt_Array.getBy(availableShops, (function (shop) {
                                    var match = shop.dataId;
                                    var id = source._0.shopId;
                                    if (id !== undefined) {
                                      return match === id;
                                    } else {
                                      return false;
                                    }
                                  }));
                    })), "", (function (param) {
                  return param.dataName;
                }));
  };
  var items = Belt_Array.map(options, (function (source) {
          return {
                  key: optionToLabel(source),
                  value: source,
                  label: optionToLabel(source)
                };
        }));
  var sections = [{
      items: items
    }];
  return React.createElement(Card$Pos.make, {
              children: React.createElement(CatalogDuplicationForm$Pos.InputSelect.make, {
                    field: /* Source */0,
                    label: Intl$Pos.t("Shop catalog"),
                    placeholder: Intl$Pos.t("Select a shop"),
                    sections: sections
                  })
            });
}

var make = React.memo(CatalogDuplicationFormSourceCard);

export {
  make ,
}
/* make Not a pure module */
