// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Box$Pos from "../../resources/layout-and-structure/Box.bs.js";
import * as Auth$Pos from "../../bundles/Auth/Auth.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Page$Pos from "../../resources/layout-and-structure/Page.bs.js";
import * as Group$Pos from "../../resources/layout-and-structure/Group.bs.js";
import * as Stack$Pos from "../../resources/layout-and-structure/Stack.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Inline$Pos from "../../resources/layout-and-structure/Inline.bs.js";
import * as Metric$Pos from "../../resources/data-visualizations/Metric.bs.js";
import * as Scalar$Pos from "../../core/Scalar.bs.js";
import * as Select$Pos from "../../resources/selection-and-input/Select.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as FontFaces$Pos from "../../resources/theme/FontFaces.bs.js";
import * as Separator$Pos from "../../resources/layout-and-structure/Separator.bs.js";
import * as TextStyle$Pos from "../../resources/typography/TextStyle.bs.js";
import * as Client from "@apollo/client";
import * as AsyncResult$Pos from "../../primitives/AsyncResult.bs.js";
import * as DateHelpers$Pos from "../../helpers/DateHelpers.bs.js";
import * as Placeholder$Pos from "../../resources/layout-and-structure/Placeholder.bs.js";
import * as ApolloHelpers$Pos from "../../helpers/ApolloHelpers.bs.js";
import * as ValueIndicator$Pos from "../../resources/data-visualizations/ValueIndicator.bs.js";
import * as SelectDateRangeFilter$Pos from "../../resources/selection-and-input/SelectDateRangeFilter.bs.js";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";

var Raw = {};

var query = Client.gql(["query AnalyticsOverviewQuery($shopIds: [String!]!, $startDateSelectedPeriod: Datetime!, $endDateSelectedPeriod: Datetime!, $startDateComparisonPeriod: Datetime!, $endDateComparisonPeriod: Datetime!)  {\nindicatorsCurrent: generalKeyPerformanceIndicators(startDate: $startDateSelectedPeriod, endDate: $endDateSelectedPeriod, shopIds: $shopIds)  {\n__typename  \nticketsRevenue  {\n__typename  \nincludingTaxes  \nexcludingTaxes  \n}\n\ninvoicesRevenue  {\n__typename  \nincludingTaxes  \nexcludingTaxes  \n}\n\nglobalRevenue  {\n__typename  \nincludingTaxes  \nexcludingTaxes  \n}\n\nsalesCount  \nsalesIndex  \nshoppingCartAverage  \ntotalAmountOfTaxes  \nproductsSoldCount  \nmargin  \nmarginRate  \nmarkupRate  \ntotalPurchaseCost  \n}\n\nindicatorPrevious: generalKeyPerformanceIndicators(startDate: $startDateComparisonPeriod, endDate: $endDateComparisonPeriod, shopIds: $shopIds)  {\n__typename  \nticketsRevenue  {\n__typename  \nincludingTaxes  \nexcludingTaxes  \n}\n\ninvoicesRevenue  {\n__typename  \nincludingTaxes  \nexcludingTaxes  \n}\n\nglobalRevenue  {\n__typename  \nincludingTaxes  \nexcludingTaxes  \n}\n\nsalesCount  \nsalesIndex  \nshoppingCartAverage  \ntotalAmountOfTaxes  \nproductsSoldCount  \nmargin  \nmarginRate  \nmarkupRate  \ntotalPurchaseCost  \n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.indicatorsCurrent;
  var value$2 = value$1.ticketsRevenue;
  var value$3 = value$1.invoicesRevenue;
  var value$4 = value$1.globalRevenue;
  var value$5 = value.indicatorPrevious;
  var value$6 = value$5.ticketsRevenue;
  var value$7 = value$5.invoicesRevenue;
  var value$8 = value$5.globalRevenue;
  return {
          indicatorsCurrent: {
            __typename: value$1.__typename,
            ticketsRevenue: {
              __typename: value$2.__typename,
              includingTaxes: value$2.includingTaxes,
              excludingTaxes: value$2.excludingTaxes
            },
            invoicesRevenue: {
              __typename: value$3.__typename,
              includingTaxes: value$3.includingTaxes,
              excludingTaxes: value$3.excludingTaxes
            },
            globalRevenue: {
              __typename: value$4.__typename,
              includingTaxes: value$4.includingTaxes,
              excludingTaxes: value$4.excludingTaxes
            },
            salesCount: value$1.salesCount,
            salesIndex: value$1.salesIndex,
            shoppingCartAverage: value$1.shoppingCartAverage,
            totalAmountOfTaxes: value$1.totalAmountOfTaxes,
            productsSoldCount: value$1.productsSoldCount,
            margin: value$1.margin,
            marginRate: value$1.marginRate,
            markupRate: value$1.markupRate,
            totalPurchaseCost: value$1.totalPurchaseCost
          },
          indicatorPrevious: {
            __typename: value$5.__typename,
            ticketsRevenue: {
              __typename: value$6.__typename,
              includingTaxes: value$6.includingTaxes,
              excludingTaxes: value$6.excludingTaxes
            },
            invoicesRevenue: {
              __typename: value$7.__typename,
              includingTaxes: value$7.includingTaxes,
              excludingTaxes: value$7.excludingTaxes
            },
            globalRevenue: {
              __typename: value$8.__typename,
              includingTaxes: value$8.includingTaxes,
              excludingTaxes: value$8.excludingTaxes
            },
            salesCount: value$5.salesCount,
            salesIndex: value$5.salesIndex,
            shoppingCartAverage: value$5.shoppingCartAverage,
            totalAmountOfTaxes: value$5.totalAmountOfTaxes,
            productsSoldCount: value$5.productsSoldCount,
            margin: value$5.margin,
            marginRate: value$5.marginRate,
            markupRate: value$5.markupRate,
            totalPurchaseCost: value$5.totalPurchaseCost
          }
        };
}

function serialize(value) {
  var value$1 = value.indicatorPrevious;
  var value$2 = value$1.totalPurchaseCost;
  var value$3 = value$1.markupRate;
  var value$4 = value$1.marginRate;
  var value$5 = value$1.margin;
  var value$6 = value$1.productsSoldCount;
  var value$7 = value$1.totalAmountOfTaxes;
  var value$8 = value$1.shoppingCartAverage;
  var value$9 = value$1.salesIndex;
  var value$10 = value$1.salesCount;
  var value$11 = value$1.globalRevenue;
  var value$12 = value$11.excludingTaxes;
  var value$13 = value$11.includingTaxes;
  var value$14 = value$11.__typename;
  var globalRevenue = {
    __typename: value$14,
    includingTaxes: value$13,
    excludingTaxes: value$12
  };
  var value$15 = value$1.invoicesRevenue;
  var value$16 = value$15.excludingTaxes;
  var value$17 = value$15.includingTaxes;
  var value$18 = value$15.__typename;
  var invoicesRevenue = {
    __typename: value$18,
    includingTaxes: value$17,
    excludingTaxes: value$16
  };
  var value$19 = value$1.ticketsRevenue;
  var value$20 = value$19.excludingTaxes;
  var value$21 = value$19.includingTaxes;
  var value$22 = value$19.__typename;
  var ticketsRevenue = {
    __typename: value$22,
    includingTaxes: value$21,
    excludingTaxes: value$20
  };
  var value$23 = value$1.__typename;
  var indicatorPrevious = {
    __typename: value$23,
    ticketsRevenue: ticketsRevenue,
    invoicesRevenue: invoicesRevenue,
    globalRevenue: globalRevenue,
    salesCount: value$10,
    salesIndex: value$9,
    shoppingCartAverage: value$8,
    totalAmountOfTaxes: value$7,
    productsSoldCount: value$6,
    margin: value$5,
    marginRate: value$4,
    markupRate: value$3,
    totalPurchaseCost: value$2
  };
  var value$24 = value.indicatorsCurrent;
  var value$25 = value$24.totalPurchaseCost;
  var value$26 = value$24.markupRate;
  var value$27 = value$24.marginRate;
  var value$28 = value$24.margin;
  var value$29 = value$24.productsSoldCount;
  var value$30 = value$24.totalAmountOfTaxes;
  var value$31 = value$24.shoppingCartAverage;
  var value$32 = value$24.salesIndex;
  var value$33 = value$24.salesCount;
  var value$34 = value$24.globalRevenue;
  var value$35 = value$34.excludingTaxes;
  var value$36 = value$34.includingTaxes;
  var value$37 = value$34.__typename;
  var globalRevenue$1 = {
    __typename: value$37,
    includingTaxes: value$36,
    excludingTaxes: value$35
  };
  var value$38 = value$24.invoicesRevenue;
  var value$39 = value$38.excludingTaxes;
  var value$40 = value$38.includingTaxes;
  var value$41 = value$38.__typename;
  var invoicesRevenue$1 = {
    __typename: value$41,
    includingTaxes: value$40,
    excludingTaxes: value$39
  };
  var value$42 = value$24.ticketsRevenue;
  var value$43 = value$42.excludingTaxes;
  var value$44 = value$42.includingTaxes;
  var value$45 = value$42.__typename;
  var ticketsRevenue$1 = {
    __typename: value$45,
    includingTaxes: value$44,
    excludingTaxes: value$43
  };
  var value$46 = value$24.__typename;
  var indicatorsCurrent = {
    __typename: value$46,
    ticketsRevenue: ticketsRevenue$1,
    invoicesRevenue: invoicesRevenue$1,
    globalRevenue: globalRevenue$1,
    salesCount: value$33,
    salesIndex: value$32,
    shoppingCartAverage: value$31,
    totalAmountOfTaxes: value$30,
    productsSoldCount: value$29,
    margin: value$28,
    marginRate: value$27,
    markupRate: value$26,
    totalPurchaseCost: value$25
  };
  return {
          indicatorsCurrent: indicatorsCurrent,
          indicatorPrevious: indicatorPrevious
        };
}

function serializeVariables(inp) {
  var a = inp.shopIds;
  return {
          shopIds: Js_array.map((function (b) {
                  return b;
                }), a),
          startDateSelectedPeriod: inp.startDateSelectedPeriod,
          endDateSelectedPeriod: inp.endDateSelectedPeriod,
          startDateComparisonPeriod: inp.startDateComparisonPeriod,
          endDateComparisonPeriod: inp.endDateComparisonPeriod
        };
}

function makeVariables(shopIds, startDateSelectedPeriod, endDateSelectedPeriod, startDateComparisonPeriod, endDateComparisonPeriod, param) {
  return {
          shopIds: shopIds,
          startDateSelectedPeriod: startDateSelectedPeriod,
          endDateSelectedPeriod: endDateSelectedPeriod,
          startDateComparisonPeriod: startDateComparisonPeriod,
          endDateComparisonPeriod: endDateComparisonPeriod
        };
}

var AnalyticsPerformanceIndicatorsQueries_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var AnalyticsPerformanceIndicatorsQueries_refetchQueryDescription = include.refetchQueryDescription;

var AnalyticsPerformanceIndicatorsQueries_useLazy = include.useLazy;

var AnalyticsPerformanceIndicatorsQueries_useLazyWithVariables = include.useLazyWithVariables;

var AnalyticsPerformanceIndicatorsQueries = {
  AnalyticsPerformanceIndicatorsQueries_inner: AnalyticsPerformanceIndicatorsQueries_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  refetchQueryDescription: AnalyticsPerformanceIndicatorsQueries_refetchQueryDescription,
  use: use,
  useLazy: AnalyticsPerformanceIndicatorsQueries_useLazy,
  useLazyWithVariables: AnalyticsPerformanceIndicatorsQueries_useLazyWithVariables
};

var Raw$1 = {};

var query$1 = Client.gql(["query AnalyticsShopsInventoryValuationQuery($shopIds: [String!]!)  {\nshopsInventoryValuation(shopIds: $shopIds)  {\n__typename  \ninventoryValuation  \nshop  {\n__typename  \nid  \nname  \n}\n\n}\n\n}\n"]);

function parse$1(value) {
  var value$1 = value.shopsInventoryValuation;
  return {
          shopsInventoryValuation: Js_array.map((function (value) {
                  var value$1 = value.shop;
                  return {
                          __typename: value.__typename,
                          inventoryValuation: value.inventoryValuation,
                          shop: {
                            __typename: value$1.__typename,
                            id: value$1.id,
                            name: value$1.name
                          }
                        };
                }), value$1)
        };
}

function serialize$1(value) {
  var value$1 = value.shopsInventoryValuation;
  var shopsInventoryValuation = Js_array.map((function (value) {
          var value$1 = value.shop;
          var value$2 = value$1.name;
          var value$3 = value$1.id;
          var value$4 = value$1.__typename;
          var shop = {
            __typename: value$4,
            id: value$3,
            name: value$2
          };
          var value$5 = value.inventoryValuation;
          var value$6 = value.__typename;
          return {
                  __typename: value$6,
                  inventoryValuation: value$5,
                  shop: shop
                };
        }), value$1);
  return {
          shopsInventoryValuation: shopsInventoryValuation
        };
}

function serializeVariables$1(inp) {
  var a = inp.shopIds;
  return {
          shopIds: Js_array.map((function (b) {
                  return b;
                }), a)
        };
}

function makeVariables$1(shopIds, param) {
  return {
          shopIds: shopIds
        };
}

var AnalyticsShopsInventoryValuationQuery_inner = {
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  makeVariables: makeVariables$1
};

var include$1 = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query$1,
      Raw: Raw$1,
      parse: parse$1,
      serialize: serialize$1,
      serializeVariables: serializeVariables$1
    });

var use$1 = include$1.use;

var AnalyticsShopsInventoryValuationQuery_refetchQueryDescription = include$1.refetchQueryDescription;

var AnalyticsShopsInventoryValuationQuery_useLazy = include$1.useLazy;

var AnalyticsShopsInventoryValuationQuery_useLazyWithVariables = include$1.useLazyWithVariables;

var AnalyticsShopsInventoryValuationQuery = {
  AnalyticsShopsInventoryValuationQuery_inner: AnalyticsShopsInventoryValuationQuery_inner,
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  makeVariables: makeVariables$1,
  refetchQueryDescription: AnalyticsShopsInventoryValuationQuery_refetchQueryDescription,
  use: use$1,
  useLazy: AnalyticsShopsInventoryValuationQuery_useLazy,
  useLazyWithVariables: AnalyticsShopsInventoryValuationQuery_useLazyWithVariables
};

function getPercentageChange(firstValue, secondValue) {
  return (secondValue - firstValue) / firstValue;
}

var tooltipAmountStyle = {
  display: "block",
  fontFamily: FontFaces$Pos.fontFamilyFromFontName("LibreFranklin"),
  fontSize: "15px",
  fontWeight: "500"
};

var tooltipPreviousPeriodStyle = {
  display: "block",
  fontFamily: FontFaces$Pos.fontFamilyFromFontName("LibreFranklin")
};

function getPeriodName(mode) {
  if (mode) {
    return Intl$Pos.t("Previous year");
  } else {
    return Intl$Pos.t("Previous period");
  }
}

function makeTooltipElement(amount, previousPeriod) {
  return React.createElement(React.Fragment, undefined, React.createElement("span", {
                  style: tooltipAmountStyle
                }, amount), React.createElement("span", {
                  style: tooltipPreviousPeriodStyle
                }, previousPeriod));
}

function AnalyticsOverviewPage$Progression(Props) {
  var progression = Props.value;
  var comparisonMode = Props.mode;
  switch (progression.TAG | 0) {
    case /* Currency */0 :
        var previousPeriodValue = progression._0;
        var difference = progression._1 - previousPeriodValue;
        var formattedDifference = Intl$Pos.currencyFormat("EUR", 2, 2, difference);
        var formattedAmount = difference > 0 ? "+" + formattedDifference : formattedDifference;
        var formattedPreviousPeriod = getPeriodName(comparisonMode) + " : " + Intl$Pos.currencyFormat("EUR", 2, 2, previousPeriodValue);
        return makeTooltipElement(formattedAmount, formattedPreviousPeriod);
    case /* Percentage */1 :
        var previousPeriodValue$1 = progression._0;
        var difference$1 = progression._1 - previousPeriodValue$1;
        var formattedDifference$1 = Intl$Pos.percentFormat(2, 2, difference$1);
        var formattedAmount$1 = difference$1 > 0 ? "+" + formattedDifference$1 : formattedDifference$1;
        var formattedPreviousPeriod$1 = getPeriodName(comparisonMode) + " : " + Intl$Pos.percentFormat(2, 2, previousPeriodValue$1);
        return makeTooltipElement(formattedAmount$1, formattedPreviousPeriod$1);
    case /* Quantity */2 :
        var previousPeriodValue$2 = progression._0;
        var difference$2 = progression._1 - previousPeriodValue$2 | 0;
        var formattedAmount$2 = difference$2 > 0 ? "+" + String(difference$2) : String(difference$2);
        var formattedPreviousPeriod$2 = getPeriodName(comparisonMode) + " : " + String(previousPeriodValue$2);
        return makeTooltipElement(formattedAmount$2, formattedPreviousPeriod$2);
    case /* Index */3 :
        var previousPeriodValue$3 = progression._0;
        var difference$3 = progression._1 - previousPeriodValue$3;
        var formattedDifference$2 = Intl$Pos.decimalFormat(2, 2, difference$3);
        var formattedAmount$3 = difference$3 > 0 ? "+" + formattedDifference$2 : formattedDifference$2;
        var formattedPreviousPeriod$3 = getPeriodName(comparisonMode) + " : " + Intl$Pos.decimalFormat(2, 2, previousPeriodValue$3);
        return makeTooltipElement(formattedAmount$3, formattedPreviousPeriod$3);
    
  }
}

var make = React.memo(AnalyticsOverviewPage$Progression);

var Progression = {
  tooltipAmountStyle: tooltipAmountStyle,
  tooltipPreviousPeriodStyle: tooltipPreviousPeriodStyle,
  getPeriodName: getPeriodName,
  makeTooltipElement: makeTooltipElement,
  make: make
};

function AnalyticsOverviewPage$TicketsRevenueMetric(Props) {
  var loading = Props.loading;
  var value = Props.value;
  var secondaryValue = Props.secondaryValue;
  var currency = Props.currency;
  var progression = Props.progression;
  var tooltip = Props.tooltip;
  return React.createElement(Metric$Pos.make, {
              children: React.createElement(Stack$Pos.make, {
                    children: null,
                    space: "normal"
                  }, React.createElement(ValueIndicator$Pos.make, {
                        value: {
                          NAME: "currency",
                          VAL: [
                            value,
                            currency
                          ]
                        }
                      }), React.createElement(Stack$Pos.make, {
                        children: null,
                        space: "xsmall"
                      }, React.createElement(TextStyle$Pos.make, {
                            children: Intl$Pos.t("Tickets revenue incl. VAT"),
                            variation: "normal",
                            size: "tiny"
                          }), React.createElement(ValueIndicator$Pos.make, {
                            value: {
                              NAME: "currency",
                              VAL: [
                                secondaryValue,
                                currency
                              ]
                            },
                            variant: "compact"
                          }))),
              title: Intl$Pos.t("Tickets revenue excl. VAT"),
              loading: loading,
              progression: progression,
              tooltip: tooltip
            });
}

var TicketsRevenueMetric = {
  make: AnalyticsOverviewPage$TicketsRevenueMetric
};

function AnalyticsOverviewPage$InvoicesRevenueMetric(Props) {
  var loading = Props.loading;
  var value = Props.value;
  var secondaryValue = Props.secondaryValue;
  var currency = Props.currency;
  var progression = Props.progression;
  var tooltip = Props.tooltip;
  return React.createElement(Metric$Pos.make, {
              children: React.createElement(Stack$Pos.make, {
                    children: null,
                    space: "normal"
                  }, React.createElement(ValueIndicator$Pos.make, {
                        value: {
                          NAME: "currency",
                          VAL: [
                            value,
                            currency
                          ]
                        }
                      }), React.createElement(Stack$Pos.make, {
                        children: null,
                        space: "xsmall"
                      }, React.createElement(TextStyle$Pos.make, {
                            children: Intl$Pos.t("Invoices revenue incl. VAT"),
                            variation: "normal",
                            size: "tiny"
                          }), React.createElement(ValueIndicator$Pos.make, {
                            value: {
                              NAME: "currency",
                              VAL: [
                                secondaryValue,
                                currency
                              ]
                            },
                            variant: "compact"
                          }))),
              title: Intl$Pos.t("Invoices revenue excl. VAT"),
              loading: loading,
              progression: progression,
              tooltip: tooltip
            });
}

var InvoicesRevenueMetric = {
  make: AnalyticsOverviewPage$InvoicesRevenueMetric
};

function AnalyticsOverviewPage$GlobalRevenueMetric(Props) {
  var loading = Props.loading;
  var value = Props.value;
  var secondaryValue = Props.secondaryValue;
  var currency = Props.currency;
  var progression = Props.progression;
  var tooltip = Props.tooltip;
  return React.createElement(Metric$Pos.make, {
              children: React.createElement(Stack$Pos.make, {
                    children: null,
                    space: "normal"
                  }, React.createElement(ValueIndicator$Pos.make, {
                        value: {
                          NAME: "currency",
                          VAL: [
                            value,
                            currency
                          ]
                        }
                      }), React.createElement(Stack$Pos.make, {
                        children: null,
                        space: "xsmall"
                      }, React.createElement(TextStyle$Pos.make, {
                            children: Intl$Pos.t("Global revenue incl. VAT"),
                            variation: "normal",
                            size: "tiny"
                          }), React.createElement(ValueIndicator$Pos.make, {
                            value: {
                              NAME: "currency",
                              VAL: [
                                secondaryValue,
                                currency
                              ]
                            },
                            variant: "compact"
                          }))),
              title: Intl$Pos.t("Global revenue excl. VAT"),
              loading: loading,
              progression: progression,
              tooltip: tooltip
            });
}

var GlobalRevenueMetric = {
  make: AnalyticsOverviewPage$GlobalRevenueMetric
};

function AnalyticsOverviewPage$MarginMetric(Props) {
  var loading = Props.loading;
  var value = Props.value;
  var currency = Props.currency;
  var progression = Props.progression;
  var tooltip = Props.tooltip;
  return React.createElement(Metric$Pos.make, {
              children: React.createElement(ValueIndicator$Pos.make, {
                    value: {
                      NAME: "currency",
                      VAL: [
                        value,
                        currency
                      ]
                    }
                  }),
              title: Intl$Pos.t("Margin"),
              loading: loading,
              progression: progression,
              tooltip: tooltip
            });
}

var MarginMetric = {
  make: AnalyticsOverviewPage$MarginMetric
};

function AnalyticsOverviewPage$MarginRateMetric(Props) {
  var loading = Props.loading;
  var value = Props.value;
  var progression = Props.progression;
  var tooltip = Props.tooltip;
  return React.createElement(Metric$Pos.make, {
              children: React.createElement(ValueIndicator$Pos.make, {
                    value: {
                      NAME: "percent",
                      VAL: value / 100
                    }
                  }),
              title: Intl$Pos.t("Margin rate"),
              loading: loading,
              progression: progression,
              tooltip: tooltip
            });
}

var MarginRateMetric = {
  make: AnalyticsOverviewPage$MarginRateMetric
};

function AnalyticsOverviewPage$MarkupRateMetric(Props) {
  var loading = Props.loading;
  var value = Props.value;
  var progression = Props.progression;
  var tooltip = Props.tooltip;
  return React.createElement(Metric$Pos.make, {
              children: React.createElement(ValueIndicator$Pos.make, {
                    value: {
                      NAME: "percent",
                      VAL: value / 100
                    }
                  }),
              title: Intl$Pos.t("Markup rate"),
              loading: loading,
              progression: progression,
              tooltip: tooltip
            });
}

var MarkupRateMetric = {
  make: AnalyticsOverviewPage$MarkupRateMetric
};

function AnalyticsOverviewPage$TotalAmountOfTaxesMetric(Props) {
  var loading = Props.loading;
  var value = Props.value;
  var currency = Props.currency;
  return React.createElement(Metric$Pos.make, {
              children: React.createElement(ValueIndicator$Pos.make, {
                    value: {
                      NAME: "currency",
                      VAL: [
                        value,
                        currency
                      ]
                    }
                  }),
              title: Intl$Pos.t("Total amount of VAT"),
              loading: loading
            });
}

var TotalAmountOfTaxesMetric = {
  make: AnalyticsOverviewPage$TotalAmountOfTaxesMetric
};

function AnalyticsOverviewPage$TotalPurchaseCostMetric(Props) {
  var loading = Props.loading;
  var value = Props.value;
  var currency = Props.currency;
  return React.createElement(Metric$Pos.make, {
              children: React.createElement(ValueIndicator$Pos.make, {
                    value: {
                      NAME: "currency",
                      VAL: [
                        value,
                        currency
                      ]
                    }
                  }),
              title: Intl$Pos.t("Cost of sales"),
              loading: loading
            });
}

var TotalPurchaseCostMetric = {
  make: AnalyticsOverviewPage$TotalPurchaseCostMetric
};

function AnalyticsOverviewPage$AverageCartMetric(Props) {
  var loading = Props.loading;
  var value = Props.value;
  var currency = Props.currency;
  var progression = Props.progression;
  var tooltip = Props.tooltip;
  return React.createElement(Metric$Pos.make, {
              children: React.createElement(ValueIndicator$Pos.make, {
                    value: {
                      NAME: "currency",
                      VAL: [
                        value,
                        currency
                      ]
                    }
                  }),
              title: Intl$Pos.t("Average cart incl. VAT"),
              loading: loading,
              progression: progression,
              tooltip: tooltip
            });
}

var AverageCartMetric = {
  make: AnalyticsOverviewPage$AverageCartMetric
};

function AnalyticsOverviewPage$SalesCountMetric(Props) {
  var loading = Props.loading;
  var value = Props.value;
  var progression = Props.progression;
  var tooltip = Props.tooltip;
  return React.createElement(Metric$Pos.make, {
              children: React.createElement(ValueIndicator$Pos.make, {
                    value: {
                      NAME: "integer",
                      VAL: value
                    }
                  }),
              title: Intl$Pos.t("Number of sales"),
              loading: loading,
              progression: progression,
              tooltip: tooltip
            });
}

var SalesCountMetric = {
  make: AnalyticsOverviewPage$SalesCountMetric
};

function AnalyticsOverviewPage$SalesIndexMetric(Props) {
  var loading = Props.loading;
  var value = Props.value;
  var progression = Props.progression;
  var tooltip = Props.tooltip;
  return React.createElement(Metric$Pos.make, {
              children: React.createElement(ValueIndicator$Pos.make, {
                    value: {
                      NAME: "decimal",
                      VAL: value
                    }
                  }),
              title: Intl$Pos.t("Sales index"),
              loading: loading,
              progression: progression,
              tooltip: tooltip
            });
}

var SalesIndexMetric = {
  make: AnalyticsOverviewPage$SalesIndexMetric
};

function AnalyticsOverviewPage$SoldProductsMetric(Props) {
  var loading = Props.loading;
  var value = Props.value;
  var progression = Props.progression;
  var tooltip = Props.tooltip;
  return React.createElement(Metric$Pos.make, {
              children: React.createElement(ValueIndicator$Pos.make, {
                    value: {
                      NAME: "integer",
                      VAL: value
                    }
                  }),
              title: Intl$Pos.t("Sold products"),
              loading: loading,
              progression: progression,
              tooltip: tooltip
            });
}

var SoldProductsMetric = {
  make: AnalyticsOverviewPage$SoldProductsMetric
};

function AnalyticsOverviewPage$InventoryValuationMetric(Props) {
  var loading = Props.loading;
  var value = Props.value;
  var currency = Props.currency;
  var disabledOpt = Props.disabled;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var tooltip = disabled ? Caml_option.some(Intl$Pos.t("The stock value is computed only on today's date")) : undefined;
  var tmp = {
    children: disabled ? React.createElement(TextStyle$Pos.make, {
            children: Intl$Pos.t("No data to display for the selected period"),
            variation: "normal"
          }) : React.createElement(ValueIndicator$Pos.make, {
            value: {
              NAME: "currency",
              VAL: [
                value,
                currency
              ]
            }
          }),
    title: Intl$Pos.t("Inventory Valuation"),
    loading: loading
  };
  if (tooltip !== undefined) {
    tmp.tooltip = Caml_option.valFromOption(tooltip);
  }
  return React.createElement(Metric$Pos.make, tmp);
}

var InventoryValuationMetric = {
  make: AnalyticsOverviewPage$InventoryValuationMetric
};

function make$1(state, action) {
  if (action.TAG === /* SelectedPeriodChanged */0) {
    var dateRange = action._0;
    var endDate = dateRange[1];
    var startDate = dateRange[0];
    var previousPeriod = DateHelpers$Pos.getPreviousPeriod(startDate, endDate);
    var previousYearPeriod = DateHelpers$Pos.getPreviousYearPeriod(startDate, endDate);
    var match = state.comparisonMode;
    var comparisonPeriod = match ? previousYearPeriod : previousPeriod;
    return {
            selectedPeriod: dateRange,
            comparisonPeriod: comparisonPeriod,
            comparisonMode: state.comparisonMode,
            previousYearPeriod: previousYearPeriod,
            previousPeriod: previousPeriod
          };
  }
  var comparisonMode = action._0;
  var comparisonPeriod$1 = comparisonMode ? state.previousYearPeriod : state.previousPeriod;
  return {
          selectedPeriod: state.selectedPeriod,
          comparisonPeriod: comparisonPeriod$1,
          comparisonMode: comparisonMode,
          previousYearPeriod: state.previousYearPeriod,
          previousPeriod: state.previousPeriod
        };
}

var Reducer = {
  make: make$1
};

function AnalyticsOverviewPage(Props) {
  var scope = Auth$Pos.useScope(undefined);
  var organisationAccount;
  organisationAccount = scope.TAG === /* Organisation */0 ? true : false;
  var tmp;
  if (scope.TAG === /* Organisation */0) {
    var shop = scope.activeShop;
    tmp = shop !== undefined ? [shop] : scope.shops;
  } else {
    tmp = [scope._0];
  }
  var shopIds = Belt_Array.map(tmp, (function (shop) {
          return shop.id;
        }));
  var initialEndDate = SelectDateRangeFilter$Pos.todayDateRange[1];
  var initialStartDate = SelectDateRangeFilter$Pos.todayDateRange[0];
  var match = React.useReducer(make$1, {
        selectedPeriod: SelectDateRangeFilter$Pos.todayDateRange,
        comparisonPeriod: DateHelpers$Pos.getPreviousPeriod(initialStartDate, initialEndDate),
        comparisonMode: /* PreviousPeriod */0,
        previousYearPeriod: DateHelpers$Pos.getPreviousYearPeriod(initialStartDate, initialEndDate),
        previousPeriod: DateHelpers$Pos.getPreviousPeriod(initialStartDate, initialEndDate)
      });
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.previousPeriod;
  var endDateComparisonPeriodPreviousPeriod = match$1[1];
  var startDateComparisonPeriodPreviousPeriod = match$1[0];
  var match$2 = state.previousYearPeriod;
  var endDateComparisonPeriodPreviousYear = match$2[1];
  var startDateComparisonPeriodPreviousYear = match$2[0];
  var match$3 = state.selectedPeriod;
  var endDateSelectedPeriod = match$3[1];
  var startDateSelectedPeriod = match$3[0];
  var match$4 = state.comparisonPeriod;
  var isDateRangeDifferentThanToday = !(DateHelpers$Pos.isSameDay(initialStartDate, startDateSelectedPeriod) && DateHelpers$Pos.isSameDay(initialEndDate, endDateSelectedPeriod));
  var analyticsPerformanceIndicatorsQueriesResult = AsyncResult$Pos.mapError(AsyncResult$Pos.mapOk(ApolloHelpers$Pos.queryResultToAsyncResult(Curry.app(use, [
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    /* CacheAndNetwork */0,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    makeVariables(shopIds, Scalar$Pos.Datetime.serialize(startDateSelectedPeriod), Scalar$Pos.Datetime.serialize(endDateSelectedPeriod), Scalar$Pos.Datetime.serialize(match$4[0]), Scalar$Pos.Datetime.serialize(match$4[1]), undefined)
                  ])), (function (data) {
              return data;
            })), (function (param) {
          
        }));
  var analyticsShopsInventoryValuationQueryResult = AsyncResult$Pos.mapError(AsyncResult$Pos.mapOk(ApolloHelpers$Pos.queryResultToAsyncResult(Curry.app(use$1, [
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    /* CacheAndNetwork */0,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    {
                      shopIds: shopIds
                    }
                  ])), (function (param) {
              return param.shopsInventoryValuation;
            })), (function (param) {
          
        }));
  var renderHeaderActions = function (param) {
    return React.createElement(Inline$Pos.make, {
                children: null,
                space: "small"
              }, organisationAccount ? React.createElement(Auth$Pos.SelectShopFilter.make, {}) : null, organisationAccount ? React.createElement(Separator$Pos.make, {}) : null, React.createElement(SelectDateRangeFilter$Pos.make, {
                    value: state.selectedPeriod,
                    placeholder: Intl$Pos.t("Select a period"),
                    disabledResetButton: true,
                    onChange: (function (selectedDateRange) {
                        Belt_Option.forEach(selectedDateRange, (function (dateRange) {
                                Curry._1(dispatch, {
                                      TAG: /* SelectedPeriodChanged */0,
                                      _0: dateRange
                                    });
                              }));
                      })
                  }), React.createElement(Select$Pos.make, {
                    label: Intl$Pos.t("Compare period to"),
                    sections: [{
                        items: [
                          {
                            key: "select_previous_period",
                            value: /* PreviousPeriod */0,
                            label: Intl$Pos.t("Previous period"),
                            description: "\u00A0" + Intl$Pos.dateTimeFormat(undefined, "medium", startDateComparisonPeriodPreviousPeriod) + " - " + Intl$Pos.dateTimeFormat(undefined, "medium", endDateComparisonPeriodPreviousPeriod)
                          },
                          {
                            key: "select_previous_year",
                            value: /* PreviousYearPeriod */1,
                            label: Intl$Pos.t("Previous year"),
                            description: "\u00A0" + Intl$Pos.dateTimeFormat(undefined, "medium", startDateComparisonPeriodPreviousYear) + " - " + Intl$Pos.dateTimeFormat(undefined, "medium", endDateComparisonPeriodPreviousYear)
                          }
                        ]
                      }],
                    preset: "filter",
                    value: state.comparisonMode,
                    onChange: (function (value) {
                        Curry._1(dispatch, {
                              TAG: /* ComparisonModeChanged */1,
                              _0: value
                            });
                      })
                  }));
  };
  var tmp$1;
  var exit = 0;
  var data;
  if (typeof analyticsPerformanceIndicatorsQueriesResult === "number") {
    exit = 1;
  } else if (analyticsPerformanceIndicatorsQueriesResult.TAG === /* Reloading */0) {
    var data$1 = analyticsPerformanceIndicatorsQueriesResult._0;
    if (data$1.TAG === /* Ok */0) {
      data = data$1._0;
      exit = 2;
    } else {
      exit = 1;
    }
  } else {
    var data$2 = analyticsPerformanceIndicatorsQueriesResult._0;
    if (data$2.TAG === /* Ok */0) {
      data = data$2._0;
      exit = 2;
    } else {
      tmp$1 = React.createElement(Placeholder$Pos.make, {
            status: /* Error */2
          });
    }
  }
  switch (exit) {
    case 1 :
        tmp$1 = React.createElement(Placeholder$Pos.make, {
              status: /* Loading */0
            });
        break;
    case 2 :
        var loading = AsyncResult$Pos.isReloading(analyticsPerformanceIndicatorsQueriesResult);
        var tmp$2;
        var exit$1 = 0;
        var shopsInventoryValuation;
        if (typeof analyticsShopsInventoryValuationQueryResult === "number") {
          exit$1 = 3;
        } else if (analyticsShopsInventoryValuationQueryResult.TAG === /* Reloading */0) {
          var shopsInventoryValuation$1 = analyticsShopsInventoryValuationQueryResult._0;
          if (shopsInventoryValuation$1.TAG === /* Ok */0) {
            shopsInventoryValuation = shopsInventoryValuation$1._0;
            exit$1 = 4;
          } else {
            exit$1 = 3;
          }
        } else {
          var shopsInventoryValuation$2 = analyticsShopsInventoryValuationQueryResult._0;
          if (shopsInventoryValuation$2.TAG === /* Ok */0) {
            shopsInventoryValuation = shopsInventoryValuation$2._0;
            exit$1 = 4;
          } else {
            tmp$2 = null;
          }
        }
        switch (exit$1) {
          case 3 :
              tmp$2 = React.createElement(AnalyticsOverviewPage$InventoryValuationMetric, {
                    loading: true,
                    value: 0,
                    currency: "EUR"
                  });
              break;
          case 4 :
              tmp$2 = React.createElement(AnalyticsOverviewPage$InventoryValuationMetric, {
                    loading: AsyncResult$Pos.isReloading(analyticsShopsInventoryValuationQueryResult),
                    value: Belt_Array.reduce(shopsInventoryValuation, 0, (function (acc, next) {
                            return acc + next.inventoryValuation;
                          })),
                    currency: "EUR",
                    disabled: isDateRangeDifferentThanToday
                  });
              break;
          
        }
        tmp$1 = React.createElement(Group$Pos.make, {
              children: null,
              spaceX: "normal",
              spaceY: "large",
              columns: 3
            }, React.createElement(AnalyticsOverviewPage$TicketsRevenueMetric, {
                  loading: loading,
                  value: data.indicatorsCurrent.ticketsRevenue.excludingTaxes,
                  secondaryValue: data.indicatorsCurrent.ticketsRevenue.includingTaxes,
                  currency: "EUR",
                  progression: getPercentageChange(data.indicatorPrevious.ticketsRevenue.excludingTaxes, data.indicatorsCurrent.ticketsRevenue.excludingTaxes),
                  tooltip: React.createElement(make, {
                        value: {
                          TAG: /* Currency */0,
                          _0: data.indicatorPrevious.ticketsRevenue.excludingTaxes,
                          _1: data.indicatorsCurrent.ticketsRevenue.excludingTaxes
                        },
                        mode: state.comparisonMode
                      })
                }), React.createElement(AnalyticsOverviewPage$InvoicesRevenueMetric, {
                  loading: loading,
                  value: data.indicatorsCurrent.invoicesRevenue.excludingTaxes,
                  secondaryValue: data.indicatorsCurrent.invoicesRevenue.includingTaxes,
                  currency: "EUR",
                  progression: getPercentageChange(data.indicatorPrevious.invoicesRevenue.excludingTaxes, data.indicatorsCurrent.invoicesRevenue.excludingTaxes),
                  tooltip: React.createElement(make, {
                        value: {
                          TAG: /* Currency */0,
                          _0: data.indicatorPrevious.invoicesRevenue.excludingTaxes,
                          _1: data.indicatorsCurrent.invoicesRevenue.excludingTaxes
                        },
                        mode: state.comparisonMode
                      })
                }), React.createElement(AnalyticsOverviewPage$GlobalRevenueMetric, {
                  loading: loading,
                  value: data.indicatorsCurrent.globalRevenue.excludingTaxes,
                  secondaryValue: data.indicatorsCurrent.globalRevenue.includingTaxes,
                  currency: "EUR",
                  progression: getPercentageChange(data.indicatorPrevious.globalRevenue.excludingTaxes, data.indicatorsCurrent.globalRevenue.excludingTaxes),
                  tooltip: React.createElement(make, {
                        value: {
                          TAG: /* Currency */0,
                          _0: data.indicatorPrevious.globalRevenue.excludingTaxes,
                          _1: data.indicatorsCurrent.globalRevenue.excludingTaxes
                        },
                        mode: state.comparisonMode
                      })
                }), React.createElement(AnalyticsOverviewPage$MarginMetric, {
                  loading: loading,
                  value: data.indicatorsCurrent.margin,
                  currency: "EUR",
                  progression: getPercentageChange(data.indicatorPrevious.margin, data.indicatorsCurrent.margin),
                  tooltip: React.createElement(make, {
                        value: {
                          TAG: /* Currency */0,
                          _0: data.indicatorPrevious.margin,
                          _1: data.indicatorsCurrent.margin
                        },
                        mode: state.comparisonMode
                      })
                }), React.createElement(AnalyticsOverviewPage$MarginRateMetric, {
                  loading: loading,
                  value: data.indicatorsCurrent.marginRate,
                  progression: getPercentageChange(data.indicatorPrevious.marginRate, data.indicatorsCurrent.marginRate),
                  tooltip: React.createElement(make, {
                        value: {
                          TAG: /* Percentage */1,
                          _0: data.indicatorPrevious.marginRate / 100,
                          _1: data.indicatorsCurrent.marginRate / 100
                        },
                        mode: state.comparisonMode
                      })
                }), React.createElement(AnalyticsOverviewPage$MarkupRateMetric, {
                  loading: loading,
                  value: data.indicatorsCurrent.markupRate,
                  progression: getPercentageChange(data.indicatorPrevious.markupRate, data.indicatorsCurrent.markupRate),
                  tooltip: React.createElement(make, {
                        value: {
                          TAG: /* Percentage */1,
                          _0: data.indicatorPrevious.markupRate / 100,
                          _1: data.indicatorsCurrent.markupRate / 100
                        },
                        mode: state.comparisonMode
                      })
                }), React.createElement(AnalyticsOverviewPage$TotalPurchaseCostMetric, {
                  loading: loading,
                  value: data.indicatorsCurrent.totalPurchaseCost,
                  currency: "EUR"
                }), React.createElement(AnalyticsOverviewPage$TotalAmountOfTaxesMetric, {
                  loading: loading,
                  value: data.indicatorsCurrent.totalAmountOfTaxes,
                  currency: "EUR"
                }), React.createElement(AnalyticsOverviewPage$AverageCartMetric, {
                  loading: loading,
                  value: data.indicatorsCurrent.shoppingCartAverage,
                  currency: "EUR",
                  progression: getPercentageChange(data.indicatorPrevious.shoppingCartAverage, data.indicatorsCurrent.shoppingCartAverage),
                  tooltip: React.createElement(make, {
                        value: {
                          TAG: /* Currency */0,
                          _0: data.indicatorPrevious.shoppingCartAverage,
                          _1: data.indicatorsCurrent.shoppingCartAverage
                        },
                        mode: state.comparisonMode
                      })
                }), React.createElement(AnalyticsOverviewPage$SalesCountMetric, {
                  loading: loading,
                  value: data.indicatorsCurrent.salesCount,
                  progression: getPercentageChange(data.indicatorPrevious.salesCount, data.indicatorsCurrent.salesCount),
                  tooltip: React.createElement(make, {
                        value: {
                          TAG: /* Quantity */2,
                          _0: data.indicatorPrevious.salesCount,
                          _1: data.indicatorsCurrent.salesCount
                        },
                        mode: state.comparisonMode
                      })
                }), React.createElement(AnalyticsOverviewPage$SalesIndexMetric, {
                  loading: loading,
                  value: data.indicatorsCurrent.salesIndex,
                  progression: getPercentageChange(data.indicatorPrevious.salesIndex, data.indicatorsCurrent.salesIndex),
                  tooltip: React.createElement(make, {
                        value: {
                          TAG: /* Index */3,
                          _0: data.indicatorPrevious.salesIndex,
                          _1: data.indicatorsCurrent.salesIndex
                        },
                        mode: state.comparisonMode
                      })
                }), React.createElement(AnalyticsOverviewPage$SoldProductsMetric, {
                  loading: loading,
                  value: data.indicatorsCurrent.productsSoldCount,
                  progression: getPercentageChange(data.indicatorPrevious.productsSoldCount, data.indicatorsCurrent.productsSoldCount),
                  tooltip: React.createElement(make, {
                        value: {
                          TAG: /* Quantity */2,
                          _0: data.indicatorPrevious.productsSoldCount,
                          _1: data.indicatorsCurrent.productsSoldCount
                        },
                        mode: state.comparisonMode
                      })
                }), tmp$2, React.createElement("div", undefined), React.createElement("div", undefined));
        break;
    
  }
  return React.createElement(Page$Pos.make, {
              children: React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(tmp$1), undefined, undefined, "xlarge", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)),
              variation: "compact",
              title: Intl$Pos.t("General performances"),
              renderHeaderActions: renderHeaderActions
            });
}

var make$2 = React.memo(AnalyticsOverviewPage);

var getPreviousYearPeriod = DateHelpers$Pos.getPreviousYearPeriod;

var getPreviousPeriod = DateHelpers$Pos.getPreviousPeriod;

export {
  AnalyticsPerformanceIndicatorsQueries ,
  AnalyticsShopsInventoryValuationQuery ,
  getPercentageChange ,
  Progression ,
  TicketsRevenueMetric ,
  InvoicesRevenueMetric ,
  GlobalRevenueMetric ,
  MarginMetric ,
  MarginRateMetric ,
  MarkupRateMetric ,
  TotalAmountOfTaxesMetric ,
  TotalPurchaseCostMetric ,
  AverageCartMetric ,
  SalesCountMetric ,
  SalesIndexMetric ,
  SoldProductsMetric ,
  InventoryValuationMetric ,
  getPreviousYearPeriod ,
  getPreviousPeriod ,
  Reducer ,
  make$2 as make,
}
/* query Not a pure module */
