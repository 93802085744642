// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Box$Pos from "../../resources/layout-and-structure/Box.bs.js";
import * as Env$Pos from "../../core/Env.bs.js";
import * as Icon$Pos from "../../resources/images-and-icons/Icon.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Json$Pos from "../../primitives/Json.bs.js";
import * as Page$Pos from "../../resources/layout-and-structure/Page.bs.js";
import * as Badge$Pos from "../../resources/feedback-indicators/Badge.bs.js";
import * as Stack$Pos from "../../resources/layout-and-structure/Stack.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Button$Pos from "../../resources/actions/Button.bs.js";
import * as Colors$Pos from "../../resources/theme/Colors.bs.js";
import * as Inline$Pos from "../../resources/layout-and-structure/Inline.bs.js";
import * as Strong$Pos from "../../resources/typography/Strong.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Request$Pos from "../../core/Request.bs.js";
import * as Tooltip$Pos from "../../resources/overlays/Tooltip.bs.js";
import * as SearchBar$Pos from "../../resources/selection-and-input/SearchBar.bs.js";
import * as TableView$Pos from "../../resources/tables/TableView.bs.js";
import * as TextStyle$Pos from "../../resources/typography/TextStyle.bs.js";
import * as AuthRoutes$Pos from "../Auth/AuthRoutes.bs.js";
import * as BarControl$Pos from "../../resources/layout-and-structure/BarControl.bs.js";
import * as EmptyState$Pos from "../../resources/layout-and-structure/EmptyState.bs.js";
import * as Navigation$Pos from "../../primitives/Navigation.bs.js";
import * as Pagination$Pos from "../../resources/navigation/Pagination.bs.js";
import * as AsyncResult$Pos from "../../primitives/AsyncResult.bs.js";
import * as Illustration$Pos from "../../resources/images-and-icons/Illustration.bs.js";
import * as Belt_HashMapString from "rescript/lib/es6/belt_HashMapString.js";

function decodeResultItem(jsonUser) {
  var userDict = Json$Pos.decodeDict(jsonUser);
  var userScopeDict = Json$Pos.flatDecodeDictFieldDict(userDict, "scope");
  if (Belt_Option.getWithDefault(Json$Pos.flatDecodeDictFieldBoolean(userScopeDict, "canUseImpersonation"), false) !== false) {
    return ;
  }
  var match = Json$Pos.flatDecodeDictFieldString(userDict, "id");
  var match$1 = Json$Pos.flatDecodeDictFieldString(userDict, "organizationName");
  var match$2 = Json$Pos.flatDecodeDictFieldString(userDict, "username");
  var match$3 = Json$Pos.flatDecodeDictFieldString(userDict, "name");
  var match$4 = Belt_Option.map(Json$Pos.flatDecodeDictFieldArray(userScopeDict, "shopIds"), (function (shopIds) {
          return Belt_Array.keepMap(shopIds, Json$Pos.decodeString);
        }));
  if (match !== undefined && match$1 !== undefined && match$2 !== undefined && match$3 !== undefined && match$4 !== undefined) {
    return {
            id: match,
            organizationName: match$1,
            username: match$2,
            name: match$3,
            shopsIds: match$4
          };
  }
  
}

function decodeResult(json) {
  var resultItems = Json$Pos.decodeArray(json);
  if (resultItems !== undefined) {
    return Belt_Array.keepMap(resultItems, decodeResultItem);
  } else {
    return [];
  }
}

var endpoint = Env$Pos.gatewayUrl(undefined) + "/admin/users";

function make(param) {
  return Future.mapOk(Request$Pos.make("GET", undefined, undefined, undefined, endpoint), undefined, decodeResult);
}

var QueryAllUsersRequest = {
  decodeResultItem: decodeResultItem,
  decodeResult: decodeResult,
  endpoint: endpoint,
  make: make
};

function decodeResultItem$1(json) {
  var dict = Json$Pos.decodeDict(json);
  var match = Json$Pos.flatDecodeDictFieldString(dict, "id");
  var match$1 = Json$Pos.flatDecodeDictFieldString(dict, "name");
  if (match !== undefined && match$1 !== undefined) {
    return {
            id: match,
            name: match$1
          };
  }
  
}

function decodeResult$1(json) {
  var resultItems = Json$Pos.decodeArray(json);
  if (resultItems !== undefined) {
    return Belt_Array.keepMap(resultItems, decodeResultItem$1);
  } else {
    return [];
  }
}

var endpoint$1 = Env$Pos.gatewayUrl(undefined) + "/admin/shops";

function make$1(param) {
  return Future.mapOk(Request$Pos.make("GET", undefined, undefined, undefined, endpoint$1), undefined, decodeResult$1);
}

var QueryAllShopsRequest = {
  decodeResultItem: decodeResultItem$1,
  decodeResult: decodeResult$1,
  endpoint: endpoint$1,
  make: make$1
};

function mergeQueryAllUsersResultWithQueryAllShopsResult(param) {
  var queryAllShops = param[1];
  var queryAllUsers = param[0];
  if (queryAllUsers.TAG !== /* Ok */0) {
    return {
            TAG: /* Error */1,
            _0: undefined
          };
  }
  var queryAllUsers$1 = queryAllUsers._0;
  if (queryAllShops.TAG !== /* Ok */0) {
    return {
            TAG: /* Error */1,
            _0: undefined
          };
  }
  var queryAllShops$1 = queryAllShops._0;
  if (!(queryAllUsers$1.length !== 0 && queryAllShops$1.length !== 0)) {
    return {
            TAG: /* Error */1,
            _0: undefined
          };
  }
  var hashMapShops = Belt_HashMapString.fromArray(Belt_Array.map(queryAllShops$1, (function (param) {
              return [
                      param.id,
                      param.name
                    ];
            })));
  var items = Belt_Array.map(queryAllUsers$1, (function (user) {
          return {
                  id: user.id,
                  organizationName: user.organizationName,
                  username: user.username,
                  name: user.name,
                  shopsNames: Belt_Array.keepMap(user.shopsIds, (function (param) {
                          return Belt_HashMapString.get(hashMapShops, param);
                        }))
                };
        }));
  return {
          TAG: /* Ok */0,
          _0: items
        };
}

function make$2(param) {
  var futures = Future.all2([
        make(undefined),
        make$1(undefined)
      ]);
  return Future.map(futures, undefined, mergeQueryAllUsersResultWithQueryAllShopsResult);
}

var QueryAllUsersWithAssociatedShopsRequest = {
  mergeQueryAllUsersResultWithQueryAllShopsResult: mergeQueryAllUsersResultWithQueryAllShopsResult,
  make: make$2
};

function keyExtractor(row) {
  return row.id;
}

function sanitize(string) {
  return string.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^a-zA-Z0-9 ]/g, "");
}

function matchShopsNames(shopsNames, query) {
  return Belt_Array.some(Belt_Array.map(shopsNames, sanitize), (function (shopName) {
                return shopName.includes(sanitize(query));
              }));
}

function match(row, query) {
  if (query === "" || row.id === query || matchShopsNames(row.shopsNames, query) || sanitize(row.email).includes(sanitize(query)) || sanitize(row.organizationName).includes(sanitize(query))) {
    return true;
  } else {
    return sanitize(row.userName).includes(sanitize(query));
  }
}

function fromQueryItem(queryItem) {
  return {
          id: queryItem.id,
          organizationName: queryItem.organizationName,
          userName: queryItem.name,
          email: queryItem.username,
          shopsNames: queryItem.shopsNames
        };
}

var TableRow = {
  keyExtractor: keyExtractor,
  sanitize: sanitize,
  matchShopsNames: matchShopsNames,
  match: match,
  fromQueryItem: fromQueryItem
};

function totalPages(rows) {
  return Math.ceil(rows.length / 10) | 0;
}

function search(rows, searchQuery) {
  return Belt_Array.keep(rows, (function (row) {
                return match(row, searchQuery);
              }));
}

function paginate(rows, currentPage) {
  return Belt_Array.slice(rows, Math.imul(currentPage - 1 | 0, 10), 10);
}

function fromQueryAllUsers(queryAllUsers) {
  return Belt_Array.map(queryAllUsers, fromQueryItem);
}

var TableRows = {
  rowsPerPage: 10,
  totalPages: totalPages,
  search: search,
  paginate: paginate,
  fromQueryAllUsers: fromQueryAllUsers
};

function AdminImpersonationPage$TableItemShopsCell(Props) {
  var shopsNames = Props.shopsNames;
  var len = shopsNames.length;
  var text = len !== 1 ? (
      len !== 0 ? Intl$Pos.template(Intl$Pos.t("{{count}} shops"), {
              count: shopsNames.length
            }, undefined) : Intl$Pos.t("No shops associated")
    ) : shopsNames[0];
  var len$1 = shopsNames.length;
  var tooltip = len$1 !== 1 && len$1 !== 0 ? Belt_Array.reduce(shopsNames, "", (function (current, shopName) {
            return current + ("•  " + shopName + "\n");
          })) : undefined;
  if (tooltip !== undefined) {
    return React.createElement(Tooltip$Pos.make, {
                children: React.createElement(Inline$Pos.make, {
                      children: null,
                      space: "small"
                    }, React.createElement(TextStyle$Pos.make, {
                          children: text,
                          variation: "subdued"
                        }), React.createElement(Icon$Pos.make, {
                          name: "info_tip",
                          fill: Colors$Pos.neutralColor30
                        })),
                placement: "end",
                content: {
                  TAG: /* Text */0,
                  _0: tooltip
                },
                delay: 0
              });
  } else {
    return React.createElement(TextStyle$Pos.make, {
                children: text
              });
  }
}

var TableItemShopsCell = {
  make: AdminImpersonationPage$TableItemShopsCell
};

function tableColumns(handleImpersonate) {
  return [
          {
            key: "name",
            name: Intl$Pos.t("Name / organization"),
            layout: {
              minWidth: {
                NAME: "px",
                VAL: 200
              },
              sticky: true
            },
            render: (function (param) {
                var match = param.data;
                return React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(Stack$Pos.make, {
                                        children: null,
                                        space: "xxsmall"
                                      }, React.createElement(Strong$Pos.make, {
                                            children: match.userName
                                          }), React.createElement(TextStyle$Pos.make, {
                                            children: match.organizationName,
                                            variation: "normal",
                                            size: "tiny"
                                          }))), undefined, "xxsmall", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
              })
          },
          {
            key: "email",
            name: Intl$Pos.t("Email"),
            layout: {
              minWidth: {
                NAME: "px",
                VAL: 200
              }
            },
            render: (function (param) {
                return React.createElement(TextStyle$Pos.make, {
                            children: param.data.email
                          });
              })
          },
          {
            key: "shops",
            name: Intl$Pos.t("Shop(s)"),
            layout: {
              minWidth: {
                NAME: "px",
                VAL: 200
              }
            },
            render: (function (param) {
                return React.createElement(AdminImpersonationPage$TableItemShopsCell, {
                            shopsNames: param.data.shopsNames
                          });
              })
          },
          {
            key: "action",
            layout: {
              minWidth: {
                NAME: "px",
                VAL: 110
              },
              alignX: "flexEnd"
            },
            render: (function (param) {
                var id = param.data.id;
                return React.createElement(Button$Pos.make, Button$Pos.makeProps(Intl$Pos.t("Impersonate"), "small", "neutral", undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (param) {
                                  Curry._1(handleImpersonate, id);
                                }), undefined, undefined, undefined));
              })
          }
        ];
}

var initialState_asyncResult = AsyncResult$Pos.notAsked(undefined);

var initialState = {
  searchQuery: "",
  currentPage: 1,
  asyncResult: initialState_asyncResult
};

function make$3(prevState, action) {
  switch (action.TAG | 0) {
    case /* SearchQueryChanged */0 :
        return {
                searchQuery: action._0,
                currentPage: 1,
                asyncResult: prevState.asyncResult
              };
    case /* AsyncResultGet */1 :
        return {
                searchQuery: prevState.searchQuery,
                currentPage: 1,
                asyncResult: action._0
              };
    case /* Paginated */2 :
        var totalPages = action._1;
        var match = prevState.currentPage;
        var tmp;
        switch (action._0) {
          case /* First */0 :
              tmp = 1;
              break;
          case /* Prev */1 :
              tmp = match !== 1 ? prevState.currentPage - 1 | 0 : 1;
              break;
          case /* Next */2 :
              tmp = match >= totalPages ? match : prevState.currentPage + 1 | 0;
              break;
          case /* Last */3 :
              tmp = totalPages;
              break;
          
        }
        return {
                searchQuery: prevState.searchQuery,
                currentPage: tmp,
                asyncResult: prevState.asyncResult
              };
    
  }
}

var Reducer = {
  initialState: initialState,
  make: make$3
};

function AdminImpersonationPage(Props) {
  var mounted = React.useRef(false);
  var match = React.useReducer(make$3, initialState);
  var state = match[0];
  var currentPage = state.currentPage;
  var searchQuery = state.searchQuery;
  var dispatch = match[1];
  var handleImpersonate = function (userId) {
    Navigation$Pos.openNewTabRoute(AuthRoutes$Pos.impersonationRoute(userId));
  };
  React.useEffect((function () {
          if (mounted.current) {
            return ;
          }
          var request = make$2(undefined);
          Curry._1(dispatch, {
                TAG: /* AsyncResultGet */1,
                _0: AsyncResult$Pos.loading(undefined)
              });
          Future.get(request, (function (result) {
                  Curry._1(dispatch, {
                        TAG: /* AsyncResultGet */1,
                        _0: AsyncResult$Pos.done(result)
                      });
                }));
          return (function (param) {
                    Future.cancel(request);
                  });
        }), []);
  React.useEffect((function () {
          mounted.current = true;
          return (function (param) {
                    mounted.current = false;
                  });
        }), []);
  var columns = tableColumns(handleImpersonate);
  var tableRows = AsyncResult$Pos.mapOk(state.asyncResult, fromQueryAllUsers);
  var searchedTableRows = AsyncResult$Pos.mapOk(tableRows, (function (rows) {
          return search(rows, searchQuery);
        }));
  var totalPages$1;
  if (typeof searchedTableRows === "number") {
    totalPages$1 = 1;
  } else if (searchedTableRows.TAG === /* Reloading */0) {
    var rows = searchedTableRows._0;
    totalPages$1 = rows.TAG === /* Ok */0 ? totalPages(rows._0) : 1;
  } else {
    var rows$1 = searchedTableRows._0;
    totalPages$1 = rows$1.TAG === /* Ok */0 ? totalPages(rows$1._0) : 1;
  }
  var searchedAndPaginatedTableRows = AsyncResult$Pos.mapOk(searchedTableRows, (function (rows) {
          return paginate(rows, currentPage);
        }));
  var onRequestSearch = function (queryString) {
    Curry._1(dispatch, {
          TAG: /* SearchQueryChanged */0,
          _0: queryString
        });
  };
  var onRequestPaginate = function (paginateAction) {
    Curry._1(dispatch, {
          TAG: /* Paginated */2,
          _0: paginateAction,
          _1: totalPages$1
        });
  };
  var match$1 = state.asyncResult;
  var placeholderEmptyState;
  if (typeof searchedAndPaginatedTableRows === "number" || searchedAndPaginatedTableRows.TAG !== /* Done */1) {
    placeholderEmptyState = EmptyState$Pos.error;
  } else {
    var match$2 = searchedAndPaginatedTableRows._0;
    if (match$2.TAG === /* Ok */0 && !(match$2._0.length !== 0 || typeof match$1 === "number" || match$1.TAG !== /* Done */1)) {
      var rows$2 = match$1._0;
      placeholderEmptyState = rows$2.TAG === /* Ok */0 && rows$2._0.length !== 0 ? React.createElement(EmptyState$Pos.make, {
              illustration: Illustration$Pos.notFound,
              title: Intl$Pos.t("No result were found."),
              text: Intl$Pos.t("Try again with another keyword or:"),
              children: React.createElement(Button$Pos.make, Button$Pos.makeProps(Intl$Pos.t("Clear search query"), undefined, "neutral", undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (param) {
                          Curry._1(dispatch, {
                                TAG: /* SearchQueryChanged */0,
                                _0: ""
                              });
                        }), undefined, undefined, undefined))
            }) : EmptyState$Pos.error;
    } else {
      placeholderEmptyState = EmptyState$Pos.error;
    }
  }
  var searchBar = React.createElement(SearchBar$Pos.make, {
        placeholder: Intl$Pos.t("Search an user"),
        value: searchQuery,
        onChange: onRequestSearch
      });
  return React.createElement(Page$Pos.make, {
              children: null,
              variation: "compact",
              title: Intl$Pos.t("Impersonation"),
              subtitle: Intl$Pos.t("List of users"),
              renderTitleEnd: (function (param) {
                  return React.createElement(Badge$Pos.make, {
                              children: Intl$Pos.t("Support"),
                              variation: "primary"
                            });
                })
            }, React.createElement(BarControl$Pos.make, {}), React.createElement(TableView$Pos.make, {
                  data: searchedAndPaginatedTableRows,
                  columns: columns,
                  keyExtractor: keyExtractor,
                  placeholderEmptyState: placeholderEmptyState,
                  searchBar: searchBar
                }), React.createElement(Pagination$Pos.make, {
                  currentPage: currentPage,
                  totalPages: totalPages$1,
                  onRequestPaginate: onRequestPaginate
                }));
}

var make$4 = React.memo(AdminImpersonationPage);

export {
  QueryAllUsersRequest ,
  QueryAllShopsRequest ,
  QueryAllUsersWithAssociatedShopsRequest ,
  TableRow ,
  TableRows ,
  TableItemShopsCell ,
  tableColumns ,
  Reducer ,
  make$4 as make,
}
/* endpoint Not a pure module */
