// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Box$Pos from "../../resources/layout-and-structure/Box.bs.js";
import * as Env$Pos from "../../core/Env.bs.js";
import * as Js_math from "rescript/lib/es6/js_math.js";
import * as Card$Pos from "../../resources/layout-and-structure/Card.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Json$Pos from "../../primitives/Json.bs.js";
import * as Page$Pos from "../../resources/layout-and-structure/Page.bs.js";
import * as Uuid$Pos from "../../externals/Uuid.bs.js";
import * as View$Pos from "../../primitives/View.bs.js";
import * as Stack$Pos from "../../resources/layout-and-structure/Stack.bs.js";
import * as Style$Pos from "../../primitives/Style.bs.js";
import * as Banner$Pos from "../../resources/feedback-indicators/Banner.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Float from "rescript/lib/es6/belt_Float.js";
import * as Button$Pos from "../../resources/actions/Button.bs.js";
import * as Colors$Pos from "../../resources/theme/Colors.bs.js";
import * as Column$Pos from "../../resources/layout-and-structure/Column.bs.js";
import * as Inline$Pos from "../../resources/layout-and-structure/Inline.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Columns$Pos from "../../resources/layout-and-structure/Columns.bs.js";
import * as Request$Pos from "../../core/Request.bs.js";
import * as Tooltip$Pos from "../../resources/overlays/Tooltip.bs.js";
import * as DropZone$Pos from "../../resources/selection-and-input/DropZone.bs.js";
import * as TextStyle$Pos from "../../resources/typography/TextStyle.bs.js";
import * as FilePicker$Pos from "../../primitives/FilePicker.bs.js";
import * as HelpCenter$Pos from "../../core/HelpCenter.bs.js";
import * as IconButton$Pos from "../../resources/actions/IconButton.bs.js";
import * as InlineText$Pos from "../../resources/layout-and-structure/InlineText.bs.js";
import * as Navigation$Pos from "../../primitives/Navigation.bs.js";
import * as StyleSheet$Pos from "../../primitives/StyleSheet.bs.js";
import * as TextAction$Pos from "../../resources/actions/TextAction.bs.js";
import * as AsyncResult$Pos from "../../primitives/AsyncResult.bs.js";
import * as InputDateField$Pos from "../../resources/selection-and-input/InputDateField.bs.js";
import * as InputTimeField$Pos from "../../resources/selection-and-input/InputTimeField.bs.js";
import * as ReactUpdateReducer$Pos from "../../primitives/ReactUpdateReducer.bs.js";
import * as StockActivityRouter$Pos from "../StockActivity/StockActivityRouter.bs.js";
import * as InputToggleSwitchField$Pos from "../../resources/selection-and-input/InputToggleSwitchField.bs.js";
import * as CatalogInventoryImportSheet$Pos from "../../modules/Catalog/CatalogInventoryImportSheet.bs.js";

var endpoint = Env$Pos.gatewayUrl(undefined) + "/inventory-import/audit";

function encodeBodyJson(inventoryItems) {
  var dict = {};
  dict["inventoryItems"] = Json$Pos.encodeArray(Belt_Array.map(inventoryItems, (function (item) {
              var dict = {};
              dict["variantId"] = Json$Pos.encodeString(item.variantId);
              dict["stock"] = Json$Pos.encodeNumber(item.stock);
              return Json$Pos.encodeDict(dict);
            })));
  return dict;
}

function decodeInvalidRequestFailureDataVariantId(json) {
  return Belt_Option.flatMap(Belt_Option.orElse(Json$Pos.flatDecodeDictFieldString(Json$Pos.decodeDict(json), "id"), Json$Pos.flatDecodeDictFieldString(Json$Pos.decodeDict(json), "variantId")), Uuid$Pos.fromString);
}

function decodeInvalidRequestFailure(serverFailure) {
  var variantId = Belt_Option.flatMap(serverFailure.data, decodeInvalidRequestFailureDataVariantId);
  if (variantId === undefined) {
    return /* Unknown */0;
  }
  var variantId$1 = Caml_option.valFromOption(variantId);
  var match = serverFailure.kind;
  switch (match) {
    case "DuplicateVariantIdFailure" :
        return {
                TAG: /* DuplicateVariantId */3,
                _0: variantId$1
              };
    case "InsufficientCapacityPrecision" :
        return {
                TAG: /* InvalidCapacityPrecision */1,
                _0: variantId$1
              };
    case "NotFoundVariant" :
        return {
                TAG: /* NotFoundVariant */0,
                _0: variantId$1
              };
    case "OutOfRangeStockValue" :
        return {
                TAG: /* OutOfRangeStockValue */4,
                _0: variantId$1
              };
    case "VariantBulkStockValue" :
        return {
                TAG: /* NotBulkProduct */2,
                _0: variantId$1
              };
    default:
      return /* Unknown */0;
  }
}

function decodeError(error) {
  if (typeof error === "number") {
    return [/* Unknown */0];
  }
  if (error.TAG !== /* InvalidRequestFailures */2) {
    return [/* Unknown */0];
  }
  var invalidRequestFailure = error._0;
  if (invalidRequestFailure.length !== 0) {
    return Belt_Array.map(invalidRequestFailure, decodeInvalidRequestFailure);
  } else {
    return [/* Unknown */0];
  }
}

function make(inventoryItems) {
  return Future.mapError(Future.mapOk(Request$Pos.make("POST", Caml_option.some(encodeBodyJson(inventoryItems)), undefined, undefined, endpoint), undefined, (function (param) {
                    
                  })), undefined, decodeError);
}

var CatalogInventoryAuditRequest = {
  endpoint: endpoint,
  encodeBodyJson: encodeBodyJson,
  decodeInvalidRequestFailureDataVariantId: decodeInvalidRequestFailureDataVariantId,
  decodeInvalidRequestFailure: decodeInvalidRequestFailure,
  decodeError: decodeError,
  make: make
};

var endpoint$1 = Env$Pos.gatewayUrl(undefined) + "/inventory-import";

function encodeBodyJson$1(inventoryItems, inventoryDatetime, param) {
  var dict = {};
  dict["inventoryItems"] = Json$Pos.encodeArray(Belt_Array.map(inventoryItems, (function (item) {
              var dict = {};
              dict["variantId"] = Json$Pos.encodeString(item.variantId);
              dict["stock"] = Json$Pos.encodeNumber(item.stock);
              return Json$Pos.encodeDict(dict);
            })));
  if (inventoryDatetime !== undefined) {
    var timestamp = Caml_option.valFromOption(inventoryDatetime).getTime();
    dict["inventoryDate"] = Json$Pos.encodeNumber(timestamp);
  }
  return dict;
}

function make$1(inventoryItems, inventoryDatetime, param) {
  return Request$Pos.make("POST", Caml_option.some(encodeBodyJson$1(inventoryItems, inventoryDatetime, undefined)), undefined, true, endpoint$1);
}

var CatalogInventoryImportRequest = {
  endpoint: endpoint$1,
  encodeBodyJson: encodeBodyJson$1,
  make: make$1
};

function CatalogInventoryImportPage$CatalogInventoryImportPickFileView(Props) {
  var loading = Props.loading;
  var onPickSuccess = Props.onPickSuccess;
  var onPickError = Props.onPickError;
  return React.createElement(Stack$Pos.make, {
              children: null,
              space: "medium"
            }, React.createElement(Stack$Pos.make, {
                  children: null
                }, React.createElement(Inline$Pos.make, {
                      children: null,
                      space: "none",
                      align: "center"
                    }, React.createElement(TextStyle$Pos.make, {
                          children: Intl$Pos.t("Before upload your files below, make sure "),
                          variation: "normal"
                        }), React.createElement(TextAction$Pos.make, {
                          text: Intl$Pos.t("your file is ready to be imported."),
                          highlighted: true,
                          onPress: (function (param) {
                              HelpCenter$Pos.showArticle(HelpCenter$Pos.prepareImportInventory);
                            })
                        })), React.createElement(TextStyle$Pos.make, {
                      children: Intl$Pos.t("Drag and drop or choose a file to upload the inventory."),
                      align: "center",
                      variation: "normal"
                    })), React.createElement(Inline$Pos.make, {
                  children: React.createElement(FilePicker$Pos.make, {
                        children: React.createElement(Button$Pos.make, Button$Pos.makeProps(Intl$Pos.t("Pick file"), undefined, "neutral", undefined, undefined, loading, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)),
                        types: CatalogInventoryImportSheet$Pos.fileTypes,
                        maxSizeMb: 10,
                        onChange: onPickSuccess,
                        onError: onPickError
                      }),
                  align: "center"
                }));
}

var CatalogInventoryImportPickFileView = {
  make: CatalogInventoryImportPage$CatalogInventoryImportPickFileView
};

function CatalogInventoryImportPage$CatalogInventoryImportLaunchImportView(Props) {
  var filename = Props.filename;
  var rowsCount = Props.rowsCount;
  var onFileClear = Props.onFileClear;
  return React.createElement(Stack$Pos.make, {
              children: null
            }, React.createElement(Inline$Pos.make, {
                  children: null,
                  align: "center"
                }, React.createElement(TextStyle$Pos.make, {
                      children: Intl$Pos.t("File is ready to be imported:") + " "
                    }), React.createElement(TextStyle$Pos.make, {
                      children: Intl$Pos.template(Intl$Pos.t("{{stockCount}} {{stocksPlural}} quantity to be updated"), {
                            stockCount: rowsCount,
                            stocksPlural: rowsCount > 1 ? Intl$Pos.t("stocks") + " " + Intl$Pos.t("are") : Intl$Pos.t("stock") + " " + Intl$Pos.t("is")
                          }, undefined),
                      variation: "secondary",
                      weight: "medium"
                    })), React.createElement(Inline$Pos.make, {
                  children: null,
                  space: "xsmall",
                  align: "center"
                }, React.createElement(TextStyle$Pos.make, {
                      children: Intl$Pos.t("File to import:"),
                      variation: "normal",
                      size: "small"
                    }), React.createElement(TextStyle$Pos.make, {
                      children: " " + filename,
                      weight: "medium",
                      size: "small"
                    }), onFileClear !== undefined ? React.createElement(IconButton$Pos.make, IconButton$Pos.makeProps("close_medium", undefined, undefined, undefined, undefined, undefined, undefined, (function (param) {
                              Curry._1(onFileClear, undefined);
                            }), undefined, undefined, undefined)) : null));
}

var CatalogInventoryImportLaunchImportView = {
  make: CatalogInventoryImportPage$CatalogInventoryImportLaunchImportView
};

function CatalogInventoryImportPage$CatalogInventoryImportSuccessImportView(Props) {
  var filename = Props.filename;
  var rowsCount = Props.rowsCount;
  var navigate = Navigation$Pos.useNavigate(undefined);
  var onPressViewButton = function (param) {
    Curry._3(navigate, undefined, undefined, StockActivityRouter$Pos.baseRoute);
  };
  return React.createElement(Stack$Pos.make, {
              children: null,
              space: "medium"
            }, React.createElement(Stack$Pos.make, {
                  children: null
                }, React.createElement(TextStyle$Pos.make, {
                      children: Intl$Pos.template(Intl$Pos.t("{{stockCount}} {{stocksPlural}} quantity {{haveBeenPlural}} updated in your catalog"), {
                            stockCount: rowsCount,
                            stocksPlural: rowsCount > 1 ? Intl$Pos.t("stocks") : Intl$Pos.t("stock"),
                            haveBeenPlural: rowsCount > 1 ? Intl$Pos.t("have been") : Intl$Pos.t("has been")
                          }, undefined),
                      align: "center",
                      variation: "success",
                      weight: "medium"
                    }), React.createElement(Inline$Pos.make, {
                      children: null,
                      space: "xsmall",
                      align: "center"
                    }, React.createElement(TextStyle$Pos.make, {
                          children: Intl$Pos.t("Imported file:"),
                          variation: "normal",
                          size: "small"
                        }), React.createElement(TextStyle$Pos.make, {
                          children: " " + filename,
                          weight: "medium",
                          size: "small"
                        }))), React.createElement(Inline$Pos.make, {
                  children: React.createElement(Button$Pos.make, Button$Pos.makeProps(Intl$Pos.t("View stock activities"), undefined, "neutral", undefined, undefined, undefined, undefined, undefined, undefined, undefined, onPressViewButton, undefined, undefined, undefined)),
                  align: "center"
                }));
}

var CatalogInventoryImportSuccessImportView = {
  make: CatalogInventoryImportPage$CatalogInventoryImportSuccessImportView
};

var initialState = {
  TAG: /* Audit */0,
  _0: /* NotAsked */0
};

var CatalogInventoryImportFileState = {
  initialState: initialState
};

var initialState$1 = {
  enabled: false,
  edited: false,
  errorMessage: undefined,
  value: undefined
};

var CatalogInventoryImportDatetimeState = {
  initialState: initialState$1
};

function validateDatetimeState(state) {
  if (!state.enabled) {
    return {
            TAG: /* Ok */0,
            _0: undefined
          };
  }
  if (!state.edited) {
    return {
            TAG: /* Error */1,
            _0: Intl$Pos.t("Required field")
          };
  }
  var datetime = state.value;
  if (datetime === undefined) {
    return {
            TAG: /* Error */1,
            _0: Intl$Pos.t("Required field")
          };
  }
  var now = new Date();
  var validated = Caml_option.valFromOption(datetime).getTime() <= now.getTime();
  if (validated) {
    return {
            TAG: /* Ok */0,
            _0: undefined
          };
  } else {
    return {
            TAG: /* Error */1,
            _0: Intl$Pos.t("The time must be earlier")
          };
  }
}

var initialState$2 = {
  submitted: false,
  errored: false,
  notification: undefined,
  sheetFile: undefined,
  fileState: initialState,
  datetimeState: initialState$1
};

function reducer(state, action) {
  if (typeof action === "number") {
    switch (action) {
      case /* ResetRequested */0 :
          return {
                  TAG: /* Update */0,
                  _0: {
                    submitted: false,
                    errored: false,
                    notification: undefined,
                    sheetFile: undefined,
                    fileState: initialState,
                    datetimeState: initialState$1
                  }
                };
      case /* DatetimeValidationRequested */1 :
          var validated = validateDatetimeState(state.datetimeState);
          var errorMessage;
          errorMessage = validated.TAG === /* Ok */0 ? undefined : validated._0;
          var init = state.datetimeState;
          var datetimeState_enabled = init.enabled;
          var datetimeState_edited = init.edited;
          var datetimeState_value = init.value;
          var datetimeState = {
            enabled: datetimeState_enabled,
            edited: datetimeState_edited,
            errorMessage: errorMessage,
            value: datetimeState_value
          };
          return {
                  TAG: /* Update */0,
                  _0: {
                    submitted: false,
                    errored: state.errored,
                    notification: state.notification,
                    sheetFile: state.sheetFile,
                    fileState: state.fileState,
                    datetimeState: datetimeState
                  }
                };
      case /* ValidationRequested */2 :
          var validated$1 = validateDatetimeState(state.datetimeState);
          var errored = Belt_Result.isError(validated$1);
          return {
                  TAG: /* UpdateWithSideEffects */1,
                  _0: {
                    submitted: true,
                    errored: errored,
                    notification: state.notification,
                    sheetFile: state.sheetFile,
                    fileState: state.fileState,
                    datetimeState: state.datetimeState
                  },
                  _1: (function (self) {
                      var errorNotificationText_textStatus = {
                        TAG: /* Danger */1,
                        _0: Intl$Pos.t("There are some errors in the form, please correct them before trying to send it again.")
                      };
                      var errorNotificationText = {
                        textStatus: errorNotificationText_textStatus
                      };
                      if (errored) {
                        Curry._1(self.dispatch, /* DatetimeValidationRequested */1);
                        Curry._1(self.dispatch, {
                              TAG: /* SetNotificationRequested */0,
                              _0: errorNotificationText
                            });
                      } else {
                        Curry._1(self.dispatch, {
                              TAG: /* SetFileProcessed */2,
                              _0: {
                                TAG: /* Import */1,
                                _0: /* Loading */1
                              }
                            });
                      }
                    })
                };
      
    }
  } else {
    switch (action.TAG | 0) {
      case /* SetNotificationRequested */0 :
          return {
                  TAG: /* Update */0,
                  _0: {
                    submitted: state.submitted,
                    errored: state.errored,
                    notification: action._0,
                    sheetFile: state.sheetFile,
                    fileState: state.fileState,
                    datetimeState: state.datetimeState
                  }
                };
      case /* ImportSubmitted */1 :
          return {
                  TAG: /* UpdateWithSideEffects */1,
                  _0: {
                    submitted: state.submitted,
                    errored: state.errored,
                    notification: state.notification,
                    sheetFile: action._0,
                    fileState: state.fileState,
                    datetimeState: state.datetimeState
                  },
                  _1: (function (param) {
                      Curry._1(param.dispatch, /* ValidationRequested */2);
                    })
                };
      case /* SetFileProcessed */2 :
          return {
                  TAG: /* UpdateWithSideEffects */1,
                  _0: {
                    submitted: state.submitted,
                    errored: state.errored,
                    notification: state.notification,
                    sheetFile: state.sheetFile,
                    fileState: action._0,
                    datetimeState: state.datetimeState
                  },
                  _1: (function (self) {
                      var match = self.state.fileState;
                      var notification;
                      if (match.TAG === /* Audit */0) {
                        var match$1 = match._0;
                        if (typeof match$1 === "number" || match$1.TAG !== /* Done */1) {
                          notification = undefined;
                        } else {
                          var error = match$1._0;
                          if (error.TAG === /* Ok */0) {
                            notification = {
                              textStatus: {
                                TAG: /* Info */3,
                                _0: Intl$Pos.t("The inventory file is ready. Click on \"Launch import\" to import your file.")
                              }
                            };
                          } else {
                            var error$1 = error._0;
                            Curry._1(self.dispatch, /* ResetRequested */0);
                            var exit = 0;
                            var errorMessages;
                            switch (error$1.TAG | 0) {
                              case /* ParseDecodeError */0 :
                                  notification = {
                                    textStatus: {
                                      TAG: /* Danger */1,
                                      _0: error$1._0
                                    }
                                  };
                                  break;
                              case /* MultipleParseDecodeErrors */1 :
                                  errorMessages = error$1._0;
                                  exit = 1;
                                  break;
                              case /* ServerError */2 :
                                  var errorMessages$1 = error$1._0;
                                  if (errorMessages$1) {
                                    errorMessages = errorMessages$1._0;
                                    exit = 1;
                                  } else {
                                    notification = {
                                      textStatus: {
                                        TAG: /* Danger */1,
                                        _0: Intl$Pos.t("Wino could not proceed to the inventory import, please try again.")
                                      }
                                    };
                                  }
                                  break;
                              
                            }
                            if (exit === 1) {
                              notification = {
                                textStatus: {
                                  TAG: /* Danger */1,
                                  _0: Intl$Pos.t("The inventory file is incorrect and cannot be processed, several data items are incorrect.")
                                },
                                details: errorMessages
                              };
                            }
                            
                          }
                        }
                      } else {
                        var match$2 = match._0;
                        if (typeof match$2 === "number" || match$2.TAG !== /* Done */1) {
                          notification = undefined;
                        } else if (match$2._0.TAG === /* Ok */0) {
                          notification = {
                            textStatus: {
                              TAG: /* Success */0,
                              _0: Intl$Pos.t("The inventory file has been successfully imported! Please wait a few minutes before synchronizing your iPads.")
                            }
                          };
                        } else {
                          Curry._1(self.dispatch, /* ResetRequested */0);
                          notification = {
                            textStatus: {
                              TAG: /* Danger */1,
                              _0: Intl$Pos.t("Wino could not proceed to the inventory import, please try again.")
                            }
                          };
                        }
                      }
                      Curry._1(self.dispatch, {
                            TAG: /* SetNotificationRequested */0,
                            _0: notification
                          });
                    })
                };
      case /* BackdateToggled */3 :
          var enabled = action._0;
          var defaultDatetime = enabled ? Caml_option.some(new Date()) : undefined;
          var datetimeState$1 = {
            enabled: enabled,
            edited: false,
            errorMessage: undefined,
            value: defaultDatetime
          };
          return {
                  TAG: /* Update */0,
                  _0: {
                    submitted: state.submitted,
                    errored: state.errored,
                    notification: state.notification,
                    sheetFile: state.sheetFile,
                    fileState: state.fileState,
                    datetimeState: datetimeState$1
                  }
                };
      case /* DateUpdated */4 :
          var nextDate = action._0;
          var date = Belt_Option.getWithDefault(state.datetimeState.value, new Date());
          var nextDate$1 = new Date(nextDate.getFullYear(), nextDate.getMonth(), nextDate.getDate(), date.getHours(), date.getMinutes());
          var init$1 = state.datetimeState;
          var datetimeState_enabled$1 = init$1.enabled;
          var datetimeState_edited$1 = init$1.edited;
          var datetimeState_errorMessage = init$1.errorMessage;
          var datetimeState_value$1 = Caml_option.some(nextDate$1);
          var datetimeState$2 = {
            enabled: datetimeState_enabled$1,
            edited: datetimeState_edited$1,
            errorMessage: datetimeState_errorMessage,
            value: datetimeState_value$1
          };
          return {
                  TAG: /* UpdateWithSideEffects */1,
                  _0: {
                    submitted: state.submitted,
                    errored: state.errored,
                    notification: state.notification,
                    sheetFile: state.sheetFile,
                    fileState: state.fileState,
                    datetimeState: datetimeState$2
                  },
                  _1: (function (self) {
                      if (self.state.datetimeState.edited) {
                        Curry._1(self.dispatch, /* DatetimeValidationRequested */1);
                      }
                      
                    })
                };
      case /* DatetimeUpdated */5 :
          var nextDatetime = action._0;
          var date$1 = Belt_Option.getWithDefault(state.datetimeState.value, new Date());
          var datetime = new Date(date$1.getFullYear(), date$1.getMonth(), date$1.getDate(), nextDatetime.getHours(), nextDatetime.getMinutes());
          var init$2 = state.datetimeState;
          var datetimeState_enabled$2 = init$2.enabled;
          var datetimeState_errorMessage$1 = init$2.errorMessage;
          var datetimeState_value$2 = Caml_option.some(datetime);
          var datetimeState$3 = {
            enabled: datetimeState_enabled$2,
            edited: true,
            errorMessage: datetimeState_errorMessage$1,
            value: datetimeState_value$2
          };
          return {
                  TAG: /* UpdateWithSideEffects */1,
                  _0: {
                    submitted: state.submitted,
                    errored: state.errored,
                    notification: state.notification,
                    sheetFile: state.sheetFile,
                    fileState: state.fileState,
                    datetimeState: datetimeState$3
                  },
                  _1: (function (self) {
                      Curry._1(self.dispatch, /* DatetimeValidationRequested */1);
                    })
                };
      
    }
  }
}

var CatalogInventoryImportReducer = {
  validateDatetimeState: validateDatetimeState,
  initialState: initialState$2,
  reducer: reducer
};

var styles = StyleSheet$Pos.create({
      root: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Colors$Pos.neutralColor10, undefined, undefined, undefined, undefined, undefined, undefined, Colors$Pos.neutralColor15, undefined, undefined, undefined, undefined, 5, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, 1, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "row", undefined, undefined, undefined, undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(28)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
    });

function CatalogInventoryImportPage$CatalogInventoryImportFileCard(Props) {
  var state = Props.state;
  var sheetFile = Props.sheetFile;
  var onAcceptFile = Props.onAcceptFile;
  var onRejectFile = Props.onRejectFile;
  var onResetPress = Props.onResetPress;
  var dropFileDisabled;
  if (state.TAG === /* Audit */0) {
    dropFileDisabled = state._0 !== 0;
  } else {
    var match = state._0;
    dropFileDisabled = typeof match === "number" || !(match.TAG === /* Done */1 && match._0.TAG === /* Ok */0) ? true : false;
  }
  var tmp;
  if (state.TAG === /* Audit */0) {
    var asyncResult = state._0;
    var exit = 0;
    if (typeof asyncResult === "number") {
      exit = 1;
    } else if (asyncResult.TAG === /* Reloading */0) {
      tmp = null;
    } else {
      var match$1 = asyncResult._0;
      if (match$1.TAG === /* Ok */0) {
        var match$2 = match$1._0;
        tmp = React.createElement(CatalogInventoryImportPage$CatalogInventoryImportLaunchImportView, {
              filename: match$2.filename,
              rowsCount: match$2.data.length,
              onFileClear: onResetPress
            });
      } else {
        tmp = null;
      }
    }
    if (exit === 1) {
      var loading = AsyncResult$Pos.isBusy(asyncResult);
      tmp = React.createElement(CatalogInventoryImportPage$CatalogInventoryImportPickFileView, {
            loading: loading,
            onPickSuccess: onAcceptFile,
            onPickError: onRejectFile
          });
    }
    
  } else {
    var match$3 = state._0;
    var exit$1 = 0;
    if (typeof match$3 === "number") {
      if (match$3 === /* NotAsked */0) {
        tmp = null;
      } else {
        exit$1 = 1;
      }
    } else if (match$3.TAG === /* Reloading */0) {
      exit$1 = 1;
    } else {
      var match$4 = match$3._0;
      if (match$4.TAG === /* Ok */0) {
        var match$5 = match$4._0;
        tmp = React.createElement(CatalogInventoryImportPage$CatalogInventoryImportSuccessImportView, {
              filename: match$5.filename,
              rowsCount: match$5.data.length
            });
      } else {
        tmp = null;
      }
    }
    if (exit$1 === 1) {
      var match$6 = Belt_Option.mapWithDefault(sheetFile, [
            "?",
            []
          ], (function (payload) {
              return [
                      payload.filename,
                      payload.data
                    ];
            }));
      tmp = React.createElement(CatalogInventoryImportPage$CatalogInventoryImportLaunchImportView, {
            filename: match$6[0],
            rowsCount: match$6[1].length
          });
    }
    
  }
  return React.createElement(Card$Pos.make, {
              children: React.createElement(DropZone$Pos.make, {
                    children: React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(tmp), Caml_option.some(styles.root), undefined, undefined, undefined)),
                    customSubtitle: Intl$Pos.t("to validate it then to import it"),
                    types: CatalogInventoryImportSheet$Pos.fileTypes,
                    maxSizeMb: 10,
                    disabled: dropFileDisabled,
                    onSuccess: onAcceptFile,
                    onError: onRejectFile
                  }),
              title: Intl$Pos.t("Inventory file")
            });
}

var CatalogInventoryImportFileCard = {
  styles: styles,
  make: CatalogInventoryImportPage$CatalogInventoryImportFileCard
};

function CatalogInventoryImportPage$CatalogInventoryImportDatetimeCard(Props) {
  var state = Props.state;
  var onToggle = Props.onToggle;
  var onDateChange = Props.onDateChange;
  var onDatetimeChange = Props.onDatetimeChange;
  var tmp;
  if (state.enabled) {
    var tmp$1 = {
      label: Intl$Pos.t("Time"),
      required: true,
      value: undefined,
      onChange: onDatetimeChange
    };
    if (state.errorMessage !== undefined) {
      tmp$1.errorMessage = state.errorMessage;
    }
    tmp = React.createElement(Stack$Pos.make, {
          children: null,
          space: "medium"
        }, React.createElement(InputDateField$Pos.make, {
              label: Intl$Pos.t("Date"),
              disabledFutureDays: true,
              value: state.value,
              onChange: onDateChange
            }), React.createElement(InputTimeField$Pos.make, tmp$1));
  } else {
    tmp = React.createElement(InlineText$Pos.make, {
          children: null
        }, React.createElement(TextStyle$Pos.make, {
              children: Intl$Pos.t("By default, inventory is imported at time"),
              variation: "normal",
              size: "small"
            }), React.createElement(TextStyle$Pos.make, {
              children: " t.",
              variation: "subdued",
              size: "small"
            }));
  }
  return React.createElement(Card$Pos.make, {
              children: React.createElement(Stack$Pos.make, {
                    children: null,
                    space: "medium"
                  }, React.createElement(Tooltip$Pos.make, {
                        children: React.createElement(InputToggleSwitchField$Pos.make, {
                              label: Intl$Pos.t("Import at an earlier datetime"),
                              required: false,
                              value: state.enabled,
                              onChange: onToggle
                            }),
                        placement: "end",
                        offset: 3,
                        content: {
                          TAG: /* Text */0,
                          _0: Intl$Pos.t("To view inventory movements following a backdated inventory, filter the movement table by the previous date entered.")
                        },
                        delay: 300,
                        immediateClose: true
                      }), tmp),
              title: Intl$Pos.t("Import option")
            });
}

var CatalogInventoryImportDatetimeCard = {
  make: CatalogInventoryImportPage$CatalogInventoryImportDatetimeCard
};

var text = "Please be aware that the action of importing inventory is irreversible. Before launching the import of the loaded file, make sure you have taken all the necessary precautions:\n\n1. You have verified that you've entered accurate stock quantities in your import file.\n2. You have checked that the inventory date for your file corresponds to the import options defined in the interface.\n3. You have synchronized all of the store's iPads, and then you have not made any further stock movements on the devices until the inventory has been imported.";

function CatalogInventoryImportPage$CatalogInventoryImportPrecautionCard(Props) {
  return React.createElement(Card$Pos.make, {
              children: null,
              title: Intl$Pos.t("Read precautions before importing")
            }, React.createElement(TextStyle$Pos.make, {
                  children: Intl$Pos.t(text)
                }), React.createElement(TextAction$Pos.make, {
                  text: Intl$Pos.t("Consult the tutorial dedicated to inventory import"),
                  highlighted: true,
                  onPress: (function (param) {
                      HelpCenter$Pos.showArticle(HelpCenter$Pos.prepareImportInventory);
                    })
                }));
}

var CatalogInventoryImportPrecautionCard = {
  text: text,
  make: CatalogInventoryImportPage$CatalogInventoryImportPrecautionCard
};

function arrayChunks(value, size) {
  if (value.length !== 0) {
    if (size > 0) {
      return Belt_Array.makeBy(Js_math.ceil_int(value.length / size), (function (index) {
                    return Belt_Array.slice(value, Math.imul(index, size), size);
                  }));
    } else {
      return [value];
    }
  } else {
    return [];
  }
}

function queryCatalogInventoryImport(catalogInventoryImportRequest, catalogInventoryQueryChunkSize, inventoryItems, inventoryDatetime, param) {
  return Future.map(Future.all(Belt_Array.map(arrayChunks(inventoryItems, catalogInventoryQueryChunkSize), (function (inventoryItems) {
                        return Curry._2(catalogInventoryImportRequest, inventoryItems, inventoryDatetime);
                      }))), undefined, (function (results) {
                if (Belt_Array.every(results, Belt_Result.isOk)) {
                  return {
                          TAG: /* Ok */0,
                          _0: undefined
                        };
                } else {
                  return {
                          TAG: /* Error */1,
                          _0: undefined
                        };
                }
              }));
}

function queryCatalogInventoryAudit(catalogInventoryAuditRequest, catalogInventoryQueryChunkSize, inventoryItems) {
  return Future.map(Future.all(Belt_Array.map(arrayChunks(inventoryItems, catalogInventoryQueryChunkSize), catalogInventoryAuditRequest)), undefined, (function (results) {
                if (Belt_Array.every(results, Belt_Result.isOk)) {
                  return {
                          TAG: /* Ok */0,
                          _0: undefined
                        };
                }
                var errors = Belt_Array.reduce(results, [], (function (acc, result) {
                        if (result.TAG === /* Ok */0) {
                          return acc;
                        } else {
                          return Belt_Array.concat(acc, result._0);
                        }
                      }));
                return {
                        TAG: /* Error */1,
                        _0: errors
                      };
              }));
}

function mapQueryCatalogInventorySheetDecodeRowError(errors) {
  var traceFromRowError = function (json, index) {
    var values = {
      json: Json$Pos.stringify(json),
      index: String(index + 1 | 0)
    };
    var stringLiteral = Intl$Pos.t("{{json}} at line {{index}}");
    return Intl$Pos.template(stringLiteral, Caml_option.some(values), undefined);
  };
  return Belt_Array.map(errors, (function (error) {
                switch (error.TAG | 0) {
                  case /* CannotDecodeHeaderRow */0 :
                  case /* CannotDecodeRow */1 :
                      break;
                  case /* CannotDecodeCellVariantId */2 :
                      var trace = traceFromRowError(error.json, error.index);
                      var stringLiteral = Intl$Pos.t("Incorrect reference ID {{trace}}");
                      return Intl$Pos.template(stringLiteral, {
                                  trace: trace
                                }, undefined);
                  case /* CannotDecodeCellStock */3 :
                      var trace$1 = traceFromRowError(error.json, error.index);
                      var stringLiteral$1 = Intl$Pos.t("Incorrect inventory quantity {{trace}}");
                      return Intl$Pos.template(stringLiteral$1, {
                                  trace: trace$1
                                }, undefined);
                  case /* NegativeCellStock */4 :
                      var trace$2 = traceFromRowError(error.json, error.index);
                      var stringLiteral$2 = Intl$Pos.t("Negative inventory quantity {{trace}}");
                      return Intl$Pos.template(stringLiteral$2, {
                                  trace: trace$2
                                }, undefined);
                  case /* EmptyCellStock */5 :
                      var stringLiteral$3 = Intl$Pos.t("Inventory quantity missing at line {{line}}");
                      return Intl$Pos.template(stringLiteral$3, {
                                  line: error.index + 1 | 0
                                }, undefined);
                  case /* EmptyCellVariantId */6 :
                      var stringLiteral$4 = Intl$Pos.t("Reference ID missing at line {{line}}");
                      return Intl$Pos.template(stringLiteral$4, {
                                  line: error.index + 1 | 0
                                }, undefined);
                  
                }
                var trace$3 = traceFromRowError(error.json, error.index);
                var stringLiteral$5 = Intl$Pos.t("Cannot decode {{trace}}");
                return Intl$Pos.template(stringLiteral$5, {
                            trace: trace$3
                          }, undefined);
              }));
}

function mapQueryCatalogInventorySheetParseAndDecodeError(error) {
  switch (error) {
    case /* InvalidHeaderRow */0 :
        var message = Intl$Pos.t("Please ensure that the inventory file is correct. Errors have been identified on the header row: the columns '{{A}}' and/or '{{B}}' cannot be found.");
        return Intl$Pos.template(Intl$Pos.t(message), {
                    A: CatalogInventoryImportSheet$Pos.dictVariantIdKey,
                    B: CatalogInventoryImportSheet$Pos.dictStockKey
                  }, undefined);
    case /* NoRows */1 :
        return Intl$Pos.t("Please ensure that the inventory file is correct: it is currently empty, no rows have been found.");
    case /* EmptyColumnStock */2 :
        return Intl$Pos.template(Intl$Pos.t("Please ensure that the inventory file is correct, the '{{column}}' column is empty."), {
                    column: CatalogInventoryImportSheet$Pos.dictStockKey
                  }, undefined);
    case /* Unknown */3 :
        return Intl$Pos.t("Please ensure that the inventory file is correct, as an error has been reported during its analysis.");
    
  }
}

function mapQueryCatalogInventoryAuditError(error, headerOffset, inventoryItems) {
  var formateErrorMessage = function (variantId, errorMessageTemplate, errorMessageTemplateTrace) {
    var index = Belt_Array.getIndexBy(inventoryItems, (function (param) {
            return param.variantId === variantId;
          }));
    if (index === undefined) {
      return ;
    }
    var traceValues = {
      json: Json$Pos.stringify(Json$Pos.encodeString(variantId)),
      index: String((index + 1 | 0) + headerOffset | 0)
    };
    var trace = Intl$Pos.template(errorMessageTemplateTrace, Caml_option.some(traceValues), undefined);
    return Intl$Pos.template(errorMessageTemplate, {
                trace: trace
              }, undefined);
  };
  var mappedErrors = Belt_Array.keepMap(error, (function (error) {
          if (typeof error === "number") {
            return ;
          }
          switch (error.TAG | 0) {
            case /* NotFoundVariant */0 :
                var errorMessageTemplateTrace = Intl$Pos.t("{{json}} at line {{index}}");
                var errorMessageTemplate = Intl$Pos.t("Incorrect reference ID {{trace}}");
                return formateErrorMessage(error._0, errorMessageTemplate, errorMessageTemplateTrace);
            case /* InvalidCapacityPrecision */1 :
                var errorMessageTemplateTrace$1 = Intl$Pos.t("at line {{index}}");
                var errorMessageTemplate$1 = Intl$Pos.t("Incorrect or corrupted inventory quantity at decimals {{trace}}, please re-enter the value.");
                return formateErrorMessage(error._0, errorMessageTemplate$1, errorMessageTemplateTrace$1);
            case /* NotBulkProduct */2 :
                var errorMessageTemplateTrace$2 = Intl$Pos.t("at line {{index}}");
                var errorMessageTemplate$2 = Intl$Pos.t("Invalid product inventory quantity {{trace}}, product is unit and its quantity must be an integer.");
                return formateErrorMessage(error._0, errorMessageTemplate$2, errorMessageTemplateTrace$2);
            case /* DuplicateVariantId */3 :
                var errorMessageTemplateTrace$3 = Intl$Pos.t("{{json}} at line {{index}}");
                var errorMessageTemplate$3 = Intl$Pos.t("several occurrences of the ID {{trace}} are present in the file.");
                return formateErrorMessage(error._0, errorMessageTemplate$3, errorMessageTemplateTrace$3);
            case /* OutOfRangeStockValue */4 :
                var errorMessageTemplateTrace$4 = Intl$Pos.t("at line {{index}}");
                var errorMessageTemplate$4 = Intl$Pos.t("Incorrect inventory quantity {{trace}}");
                return formateErrorMessage(error._0, errorMessageTemplate$4, errorMessageTemplateTrace$4);
            
          }
        }));
  if (mappedErrors.length === 0) {
    return ;
  } else {
    return mappedErrors;
  }
}

function catalogInventoryImportRequest(inventoryItems, inventoryDatetime) {
  return make$1(inventoryItems, inventoryDatetime, undefined);
}

var catalogInventoryAuditRequest = make;

function catalogInventoryProcessAudit(value, file, dispatch) {
  Future.map(Future.flatMapOk(Future.flatMap(value, undefined, (function (param) {
                  var payload = param.payload;
                  var tmp;
                  if (payload.TAG === /* Ok */0) {
                    var rows = payload._0;
                    var okRows = Belt_Array.keepMap(rows, (function (row) {
                            if (row.TAG !== /* Ok */0) {
                              return ;
                            }
                            var row$1 = row._0;
                            var sanitizedStock = row$1.stock.toFixed(3);
                            var stock = Belt_Option.getWithDefault(Belt_Float.fromString(sanitizedStock), row$1.stock);
                            return {
                                    variantId: row$1.variantId,
                                    stock: stock
                                  };
                          }));
                    var erroredRows = Belt_Array.keepMap(rows, (function (row) {
                            if (row.TAG === /* Ok */0) {
                              return ;
                            } else {
                              return row._0;
                            }
                          }));
                    tmp = erroredRows.length !== 0 ? ({
                          TAG: /* Error */1,
                          _0: {
                            TAG: /* MultipleParseDecodeErrors */1,
                            _0: mapQueryCatalogInventorySheetDecodeRowError(erroredRows)
                          }
                        }) : ({
                          TAG: /* Ok */0,
                          _0: {
                            headerOffset: param.headerOffset,
                            payload: okRows
                          }
                        });
                  } else {
                    var parseDecodeError = mapQueryCatalogInventorySheetParseAndDecodeError(payload._0);
                    tmp = {
                      TAG: /* Error */1,
                      _0: {
                        TAG: /* ParseDecodeError */0,
                        _0: parseDecodeError
                      }
                    };
                  }
                  return Future.value(tmp);
                })), undefined, (function (param) {
              var inventoryItems = param.payload;
              var headerOffset = param.headerOffset;
              return Future.mapError(Future.mapOk(queryCatalogInventoryAudit(catalogInventoryAuditRequest, 2500, inventoryItems), undefined, (function (param) {
                                return {
                                        filename: file.name,
                                        data: inventoryItems
                                      };
                              })), undefined, (function (error) {
                            var maybeErrorMessage = mapQueryCatalogInventoryAuditError(error, headerOffset, inventoryItems);
                            if (maybeErrorMessage !== undefined) {
                              return {
                                      TAG: /* ServerError */2,
                                      _0: /* ErrorMessages */{
                                        _0: maybeErrorMessage
                                      }
                                    };
                            } else {
                              return {
                                      TAG: /* ServerError */2,
                                      _0: /* Unexpected */0
                                    };
                            }
                          }));
            })), undefined, (function (data) {
          return Curry._1(dispatch, {
                      TAG: /* SetFileProcessed */2,
                      _0: {
                        TAG: /* Audit */0,
                        _0: {
                          TAG: /* Done */1,
                          _0: data
                        }
                      }
                    });
        }));
}

function CatalogInventoryImportPage(Props) {
  var match = ReactUpdateReducer$Pos.use(reducer, initialState$2);
  var dispatch = match[1];
  var state = match[0];
  React.useEffect((function () {
          var match = state.submitted;
          var match$1 = state.errored;
          var match$2 = state.sheetFile;
          if (match && !match$1 && match$2 !== undefined) {
            var inventoryItems = match$2.data;
            var filename = match$2.filename;
            Future.get(queryCatalogInventoryImport(catalogInventoryImportRequest, 2500, inventoryItems, state.datetimeState.value, undefined), (function (result) {
                    if (result.TAG === /* Ok */0) {
                      return Curry._1(dispatch, {
                                  TAG: /* SetFileProcessed */2,
                                  _0: {
                                    TAG: /* Import */1,
                                    _0: {
                                      TAG: /* Done */1,
                                      _0: {
                                        TAG: /* Ok */0,
                                        _0: {
                                          filename: filename,
                                          data: inventoryItems
                                        }
                                      }
                                    }
                                  }
                                });
                    } else {
                      return Curry._1(dispatch, {
                                  TAG: /* SetFileProcessed */2,
                                  _0: {
                                    TAG: /* Import */1,
                                    _0: {
                                      TAG: /* Done */1,
                                      _0: {
                                        TAG: /* Error */1,
                                        _0: undefined
                                      }
                                    }
                                  }
                                });
                    }
                  }));
          }
          
        }), [state.submitted]);
  var onAcceptFile = function (file) {
    Curry._1(dispatch, {
          TAG: /* SetFileProcessed */2,
          _0: {
            TAG: /* Audit */0,
            _0: /* Loading */1
          }
        });
    var match = FilePicker$Pos.typeFromMime(file.type);
    if (match.TAG !== /* Ok */0) {
      return ;
    }
    var match$1 = match._0;
    if (match$1 === "spreadsheetml") {
      return catalogInventoryProcessAudit(CatalogInventoryImportSheet$Pos.parseAndDecodeXlsxFile(2, file), file, dispatch);
    } else if (match$1 === "csv") {
      return catalogInventoryProcessAudit(CatalogInventoryImportSheet$Pos.parseAndDecodeCsvFile(file), file, dispatch);
    } else {
      return ;
    }
  };
  var onRejectFile = function (message) {
    Curry._1(dispatch, {
          TAG: /* SetNotificationRequested */0,
          _0: {
            textStatus: {
              TAG: /* Danger */1,
              _0: message
            }
          }
        });
  };
  var onToggleBackdateView = function (enabled) {
    Curry._1(dispatch, {
          TAG: /* BackdateToggled */3,
          _0: enabled
        });
  };
  var onDateChange = function (date) {
    Curry._1(dispatch, {
          TAG: /* DateUpdated */4,
          _0: date
        });
  };
  var onDatetimeChange = function (datetime) {
    Curry._1(dispatch, {
          TAG: /* DatetimeUpdated */5,
          _0: datetime
        });
  };
  var onReset = function (param) {
    Curry._1(dispatch, /* ResetRequested */0);
  };
  var onClearNotification = function (param) {
    Curry._1(dispatch, {
          TAG: /* SetNotificationRequested */0,
          _0: undefined
        });
  };
  var status = state.fileState;
  var pageActions;
  if (status.TAG === /* Audit */0) {
    var match$1 = status._0;
    if (typeof match$1 === "number" || match$1.TAG !== /* Done */1) {
      pageActions = null;
    } else {
      var match$2 = match$1._0;
      if (match$2.TAG === /* Ok */0) {
        var match$3 = match$2._0;
        var data = match$3.data;
        var filename = match$3.filename;
        pageActions = React.createElement(Button$Pos.make, Button$Pos.makeProps(Intl$Pos.t("Launch import"), undefined, "primary", undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (param) {
                    Curry._1(dispatch, {
                          TAG: /* ImportSubmitted */1,
                          _0: {
                            filename: filename,
                            data: data
                          }
                        });
                  }), undefined, undefined, undefined));
      } else {
        pageActions = null;
      }
    }
  } else {
    var status$1 = status._0;
    pageActions = AsyncResult$Pos.isBusy(status$1) ? React.createElement(Button$Pos.make, Button$Pos.makeProps(Intl$Pos.t("Launch import"), undefined, "primary", undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)) : (
        typeof status$1 === "number" || !(status$1.TAG === /* Done */1 && status$1._0.TAG === /* Ok */0) ? null : React.createElement(Button$Pos.make, Button$Pos.makeProps(Intl$Pos.t("Load new inventory"), undefined, "neutral", undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (param) {
                      Curry._1(dispatch, /* ResetRequested */0);
                    }), undefined, undefined, undefined))
      );
  }
  var shouldBlockOnRouteChange = React.useCallback((function (_nextRoute) {
          var match = state.fileState;
          if (match.TAG === /* Audit */0) {
            return false;
          }
          var match$1 = match._0;
          if (typeof match$1 === "number") {
            return match$1 !== 0;
          } else {
            return false;
          }
        }), [state.fileState]);
  var match$4 = state.fileState;
  var formattedTitle;
  if (match$4.TAG === /* Audit */0) {
    var tmp = match$4._0;
    formattedTitle = typeof tmp === "number" || tmp.TAG !== /* Done */1 ? Intl$Pos.t("Step 1 | Validate the inventory file") : Intl$Pos.t("Step 2 | Import the inventory file");
  } else {
    var tmp$1 = match$4._0;
    formattedTitle = typeof tmp$1 === "number" || tmp$1.TAG !== /* Done */1 ? Intl$Pos.t("Step 2 | Import the inventory file") : Intl$Pos.t("Import finished");
  }
  var match$5 = state.notification;
  var tmp$2;
  if (match$5 !== undefined) {
    var tmp$3 = {
      textStatus: match$5.textStatus,
      onRequestClose: onClearNotification
    };
    if (match$5.details !== undefined) {
      tmp$3.details = Caml_option.valFromOption(match$5.details);
    }
    tmp$2 = React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(Banner$Pos.make, tmp$3)), undefined, undefined, "medium", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
  } else {
    tmp$2 = null;
  }
  return React.createElement(Page$Pos.make, {
              children: null,
              title: formattedTitle,
              renderActions: (function (param) {
                  return pageActions;
                })
            }, React.createElement(Navigation$Pos.Prompt.make, {
                  message: Intl$Pos.t("Warning: the inventory import operation is still in progress."),
                  shouldBlockOnRouteChange: shouldBlockOnRouteChange
                }), tmp$2, React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(Stack$Pos.make, {
                              children: null,
                              space: "medium"
                            }, React.createElement(CatalogInventoryImportPage$CatalogInventoryImportFileCard, {
                                  state: state.fileState,
                                  sheetFile: state.sheetFile,
                                  onAcceptFile: onAcceptFile,
                                  onRejectFile: onRejectFile,
                                  onResetPress: onReset
                                }), React.createElement(Columns$Pos.make, {
                                  children: null,
                                  space: "medium"
                                }, React.createElement(Column$Pos.make, {
                                      children: React.createElement(CatalogInventoryImportPage$CatalogInventoryImportDatetimeCard, {
                                            state: state.datetimeState,
                                            onToggle: onToggleBackdateView,
                                            onDateChange: onDateChange,
                                            onDatetimeChange: onDatetimeChange
                                          }),
                                      width: "one_third"
                                    }), React.createElement(Column$Pos.make, {
                                      children: React.createElement(CatalogInventoryImportPage$CatalogInventoryImportPrecautionCard, {}),
                                      width: "two_third"
                                    })))), undefined, undefined, "large", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)));
}

var make$2 = React.memo(CatalogInventoryImportPage);

var catalogInventoryQueryChunkSize = 2500;

var dictVariantIdKey = CatalogInventoryImportSheet$Pos.dictVariantIdKey;

var dictStockKey = CatalogInventoryImportSheet$Pos.dictStockKey;

export {
  CatalogInventoryAuditRequest ,
  CatalogInventoryImportRequest ,
  CatalogInventoryImportPickFileView ,
  CatalogInventoryImportLaunchImportView ,
  CatalogInventoryImportSuccessImportView ,
  CatalogInventoryImportFileState ,
  CatalogInventoryImportDatetimeState ,
  CatalogInventoryImportReducer ,
  CatalogInventoryImportFileCard ,
  CatalogInventoryImportDatetimeCard ,
  CatalogInventoryImportPrecautionCard ,
  arrayChunks ,
  catalogInventoryQueryChunkSize ,
  queryCatalogInventoryImport ,
  queryCatalogInventoryAudit ,
  dictVariantIdKey ,
  dictStockKey ,
  mapQueryCatalogInventorySheetDecodeRowError ,
  mapQueryCatalogInventorySheetParseAndDecodeError ,
  mapQueryCatalogInventoryAuditError ,
  catalogInventoryImportRequest ,
  catalogInventoryAuditRequest ,
  catalogInventoryProcessAudit ,
  make$2 as make,
}
/* endpoint Not a pure module */
