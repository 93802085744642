// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_int32 from "rescript/lib/es6/caml_int32.js";
import * as Spaces$Pos from "../theme/Spaces.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Stylex from "@stylexjs/stylex";

var styles = Stylex.create({
      container: {
        display: "flex",
        flexWrap: "wrap"
      }
    });

function containerStyleProps(spaceX, spaceY) {
  return Stylex.props(styles.container, {
              columnGap: "" + String(Spaces$Pos.toFloat(spaceX)) + "px",
              rowGap: "" + String(Spaces$Pos.toFloat(spaceY)) + "px"
            });
}

function itemStyleProps(flexBasisPct) {
  return Stylex.props({
              flex: "1 1 " + String(flexBasisPct) + "%"
            });
}

function Group(Props) {
  var children = Props.children;
  var spaceXOpt = Props.spaceX;
  var spaceYOpt = Props.spaceY;
  var columns = Props.columns;
  var spaceX = spaceXOpt !== undefined ? spaceXOpt : "large";
  var spaceY = spaceYOpt !== undefined ? spaceYOpt : "large";
  var childrenLength = Belt_Option.getWithDefault(columns, React.Children.toArray(children).length);
  var containerStyleProps$1 = containerStyleProps(spaceX, spaceY);
  var flexBasisPct = Math.max(Caml_int32.div(100, childrenLength) - childrenLength | 0, childrenLength - 2 | 0);
  var match = itemStyleProps(flexBasisPct);
  var itemClassName = match.className;
  var itemStyle = match.style;
  var tmp = {};
  if (containerStyleProps$1.className !== undefined) {
    tmp.className = Caml_option.valFromOption(containerStyleProps$1.className);
  }
  if (containerStyleProps$1.style !== undefined) {
    tmp.style = Caml_option.valFromOption(containerStyleProps$1.style);
  }
  return React.createElement("div", tmp, Belt_Array.mapWithIndex(React.Children.toArray(children), (function (idx, child) {
                    var tmp = {
                      key: String(idx)
                    };
                    if (itemClassName !== undefined) {
                      tmp.className = Caml_option.valFromOption(itemClassName);
                    }
                    if (itemStyle !== undefined) {
                      tmp.style = Caml_option.valFromOption(itemStyle);
                    }
                    return React.createElement("div", tmp, child);
                  })));
}

var make = React.memo(Group);

export {
  make ,
}
/* styles Not a pure module */
