// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Button$Pos from "../../resources/actions/Button.bs.js";
import * as Inline$Pos from "../../resources/layout-and-structure/Inline.bs.js";
import * as Scalar$Pos from "../../core/Scalar.bs.js";
import * as Select$Pos from "../../resources/selection-and-input/Select.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Scaffold$Pos from "../../bundles/Scaffold/Scaffold.bs.js";
import * as JsonCodec$Pos from "../../primitives/JsonCodec.bs.js";
import * as SearchBar$Pos from "../../resources/selection-and-input/SearchBar.bs.js";
import * as TextStyle$Pos from "../../resources/typography/TextStyle.bs.js";
import * as Client from "@apollo/client";
import * as ButtonLink$Pos from "../../resources/actions/ButtonLink.bs.js";
import * as EmptyState$Pos from "../../resources/layout-and-structure/EmptyState.bs.js";
import * as OrderStatus$Pos from "../../modules/Order/OrderStatus.bs.js";
import * as Placeholder$Pos from "../../resources/layout-and-structure/Placeholder.bs.js";
import * as Illustration$Pos from "../../resources/images-and-icons/Illustration.bs.js";
import * as LegacyRouter$Pos from "../../core/LegacyRouter.bs.js";
import * as TableDateCell$Pos from "../../resources/tables/TableDateCell.bs.js";
import * as AmountTableCell$Pos from "../../modules/AmountTableCell.bs.js";
import * as OrderStatusBadges$Pos from "../../modules/Order/OrderStatusBadges.bs.js";
import * as OrderTableActions$Pos from "../../modules/Order/OrderTableActions.bs.js";
import * as OrderNameTableCell$Pos from "../../modules/Order/OrderNameTableCell.bs.js";
import * as OrderUrlQueryString$Pos from "../../modules/Order/OrderUrlQueryString.bs.js";
import * as SelectDateRangeFilter$Pos from "../../resources/selection-and-input/SelectDateRangeFilter.bs.js";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";

var Raw = {};

var query = Client.gql(["query SupplierOrderListPageParentQuery($id: ID!)  {\nsupplier(id: $id)  {\n__typename  \nid  \ncompanyName  \nshop  {\n__typename  \nid  \n}\n\n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.supplier;
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var value$2 = value$1.shop;
    tmp = {
      __typename: value$1.__typename,
      id: value$1.id,
      companyName: value$1.companyName,
      shop: {
        __typename: value$2.__typename,
        id: value$2.id
      }
    };
  }
  return {
          supplier: tmp
        };
}

function serialize(value) {
  var value$1 = value.supplier;
  var supplier;
  if (value$1 !== undefined) {
    var value$2 = value$1.shop;
    var value$3 = value$2.id;
    var value$4 = value$2.__typename;
    var shop = {
      __typename: value$4,
      id: value$3
    };
    var value$5 = value$1.companyName;
    var value$6 = value$1.id;
    var value$7 = value$1.__typename;
    supplier = {
      __typename: value$7,
      id: value$6,
      companyName: value$5,
      shop: shop
    };
  } else {
    supplier = null;
  }
  return {
          supplier: supplier
        };
}

function serializeVariables(inp) {
  return {
          id: inp.id
        };
}

function makeVariables(id, param) {
  return {
          id: id
        };
}

var ParentQuery_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var ParentQuery_refetchQueryDescription = include.refetchQueryDescription;

var ParentQuery_useLazy = include.useLazy;

var ParentQuery_useLazyWithVariables = include.useLazyWithVariables;

var ParentQuery = {
  ParentQuery_inner: ParentQuery_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  refetchQueryDescription: ParentQuery_refetchQueryDescription,
  use: use,
  useLazy: ParentQuery_useLazy,
  useLazyWithVariables: ParentQuery_useLazyWithVariables
};

var Raw$1 = {};

var query$1 = Client.gql(["query SupplierOrderListPageQuery($supplierId: ID!, $after: String, $before: String, $filterBy: InputSupplierOrdersFilter, $first: Int, $last: Int, $search: String)  {\nsupplier(id: $supplierId)  {\n__typename  \nid  \norders(after: $after, before: $before, filterBy: $filterBy, first: $first, last: $last, search: $search)  {\n__typename  \npageInfo  {\n__typename  \nstartCursor  \nendCursor  \n}\n\ntotalCount  \nedges  {\n__typename  \nnode  {\n__typename  \nid  \nformattedName  \nshopName  \nsupplierCompanyName  \nissueDate  \nreceptionFinishedAt  \nformattedStatus  \ntotalAmountExcludingTaxes  \ntotalAmountIncludingTaxes  \ntotalProductsQuantity  \ntotalProductsExpectedQuantity  \n}\n\n}\n\n}\n\n}\n\n}\n"]);

function parse$1(value) {
  var value$1 = value.supplier;
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var value$2 = value$1.orders;
    var value$3 = value$2.pageInfo;
    var value$4 = value$3.startCursor;
    var value$5 = value$3.endCursor;
    var value$6 = value$2.edges;
    tmp = {
      __typename: value$1.__typename,
      id: value$1.id,
      orders: {
        __typename: value$2.__typename,
        pageInfo: {
          __typename: value$3.__typename,
          startCursor: !(value$4 == null) ? value$4 : undefined,
          endCursor: !(value$5 == null) ? value$5 : undefined
        },
        totalCount: value$2.totalCount,
        edges: Js_array.map((function (value) {
                var value$1 = value.node;
                var value$2 = value$1.receptionFinishedAt;
                var value$3 = value$1.formattedStatus;
                return {
                        __typename: value.__typename,
                        node: {
                          __typename: value$1.__typename,
                          id: value$1.id,
                          formattedName: value$1.formattedName,
                          shopName: value$1.shopName,
                          supplierCompanyName: value$1.supplierCompanyName,
                          issueDate: Scalar$Pos.Datetime.parse(value$1.issueDate),
                          receptionFinishedAt: !(value$2 == null) ? Caml_option.some(Scalar$Pos.Datetime.parse(value$2)) : undefined,
                          formattedStatus: Js_array.map((function (value) {
                                  switch (value) {
                                    case "ACCEPTED" :
                                        return "ACCEPTED";
                                    case "ARCHIVED" :
                                        return "ARCHIVED";
                                    case "DRAFT" :
                                        return "DRAFT";
                                    case "FINALIZED" :
                                        return "FINALIZED";
                                    case "NOT_RECEIVED" :
                                        return "NOT_RECEIVED";
                                    case "PAID" :
                                        return "PAID";
                                    case "RECEIVED" :
                                        return "RECEIVED";
                                    case "RECEIVING" :
                                        return "RECEIVING";
                                    case "REFUSED" :
                                        return "REFUSED";
                                    case "SENT" :
                                        return "SENT";
                                    case "TO_PAY" :
                                        return "TO_PAY";
                                    default:
                                      throw {
                                            RE_EXN_ID: "Not_found",
                                            Error: new Error()
                                          };
                                  }
                                }), value$3),
                          totalAmountExcludingTaxes: value$1.totalAmountExcludingTaxes,
                          totalAmountIncludingTaxes: value$1.totalAmountIncludingTaxes,
                          totalProductsQuantity: value$1.totalProductsQuantity,
                          totalProductsExpectedQuantity: value$1.totalProductsExpectedQuantity
                        }
                      };
              }), value$6)
      }
    };
  }
  return {
          supplier: tmp
        };
}

function serialize$1(value) {
  var value$1 = value.supplier;
  var supplier;
  if (value$1 !== undefined) {
    var value$2 = value$1.orders;
    var value$3 = value$2.edges;
    var edges = Js_array.map((function (value) {
            var value$1 = value.node;
            var value$2 = value$1.totalProductsExpectedQuantity;
            var value$3 = value$1.totalProductsQuantity;
            var value$4 = value$1.totalAmountIncludingTaxes;
            var value$5 = value$1.totalAmountExcludingTaxes;
            var value$6 = value$1.formattedStatus;
            var formattedStatus = Js_array.map((function (value) {
                    if (value === "TO_PAY") {
                      return "TO_PAY";
                    } else if (value === "DRAFT") {
                      return "DRAFT";
                    } else if (value === "NOT_RECEIVED") {
                      return "NOT_RECEIVED";
                    } else if (value === "REFUSED") {
                      return "REFUSED";
                    } else if (value === "FINALIZED") {
                      return "FINALIZED";
                    } else if (value === "ACCEPTED") {
                      return "ACCEPTED";
                    } else if (value === "PAID") {
                      return "PAID";
                    } else if (value === "RECEIVING") {
                      return "RECEIVING";
                    } else if (value === "SENT") {
                      return "SENT";
                    } else if (value === "RECEIVED") {
                      return "RECEIVED";
                    } else {
                      return "ARCHIVED";
                    }
                  }), value$6);
            var value$7 = value$1.receptionFinishedAt;
            var receptionFinishedAt = value$7 !== undefined ? Scalar$Pos.Datetime.serialize(Caml_option.valFromOption(value$7)) : null;
            var value$8 = value$1.issueDate;
            var value$9 = Scalar$Pos.Datetime.serialize(value$8);
            var value$10 = value$1.supplierCompanyName;
            var value$11 = value$1.shopName;
            var value$12 = value$1.formattedName;
            var value$13 = value$1.id;
            var value$14 = value$1.__typename;
            var node = {
              __typename: value$14,
              id: value$13,
              formattedName: value$12,
              shopName: value$11,
              supplierCompanyName: value$10,
              issueDate: value$9,
              receptionFinishedAt: receptionFinishedAt,
              formattedStatus: formattedStatus,
              totalAmountExcludingTaxes: value$5,
              totalAmountIncludingTaxes: value$4,
              totalProductsQuantity: value$3,
              totalProductsExpectedQuantity: value$2
            };
            var value$15 = value.__typename;
            return {
                    __typename: value$15,
                    node: node
                  };
          }), value$3);
    var value$4 = value$2.totalCount;
    var value$5 = value$2.pageInfo;
    var value$6 = value$5.endCursor;
    var endCursor = value$6 !== undefined ? value$6 : null;
    var value$7 = value$5.startCursor;
    var startCursor = value$7 !== undefined ? value$7 : null;
    var value$8 = value$5.__typename;
    var pageInfo = {
      __typename: value$8,
      startCursor: startCursor,
      endCursor: endCursor
    };
    var value$9 = value$2.__typename;
    var orders = {
      __typename: value$9,
      pageInfo: pageInfo,
      totalCount: value$4,
      edges: edges
    };
    var value$10 = value$1.id;
    var value$11 = value$1.__typename;
    supplier = {
      __typename: value$11,
      id: value$10,
      orders: orders
    };
  } else {
    supplier = null;
  }
  return {
          supplier: supplier
        };
}

function serializeInputObjectDateFilter(inp) {
  var a = inp._after;
  var a$1 = inp._before;
  var a$2 = inp._between;
  return {
          _after: a !== undefined ? Caml_option.valFromOption(a) : undefined,
          _before: a$1 !== undefined ? Caml_option.valFromOption(a$1) : undefined,
          _between: a$2 !== undefined ? Js_array.map((function (b) {
                    return b;
                  }), a$2) : undefined
        };
}

function serializeInputObjectInFilter(inp) {
  var a = inp._in;
  return {
          _in: Js_array.map((function (b) {
                  return b;
                }), a)
        };
}

function serializeInputObjectInputSupplierOrdersFilter(inp) {
  var a = inp.status;
  var a$1 = inp.createdAt;
  var a$2 = inp.issueDate;
  var a$3 = inp.receptionFinishedAt;
  var a$4 = inp.shopIds;
  return {
          status: a !== undefined ? serializeInputObjectInFilter(a) : undefined,
          createdAt: a$1 !== undefined ? serializeInputObjectDateFilter(a$1) : undefined,
          issueDate: a$2 !== undefined ? serializeInputObjectDateFilter(a$2) : undefined,
          receptionFinishedAt: a$3 !== undefined ? serializeInputObjectDateFilter(a$3) : undefined,
          shopIds: a$4 !== undefined ? serializeInputObjectInFilter(a$4) : undefined
        };
}

function serializeVariables$1(inp) {
  var a = inp.after;
  var a$1 = inp.before;
  var a$2 = inp.filterBy;
  var a$3 = inp.first;
  var a$4 = inp.last;
  var a$5 = inp.search;
  return {
          supplierId: inp.supplierId,
          after: a !== undefined ? a : undefined,
          before: a$1 !== undefined ? a$1 : undefined,
          filterBy: a$2 !== undefined ? serializeInputObjectInputSupplierOrdersFilter(a$2) : undefined,
          first: a$3 !== undefined ? a$3 : undefined,
          last: a$4 !== undefined ? a$4 : undefined,
          search: a$5 !== undefined ? a$5 : undefined
        };
}

function makeVariables$1(supplierId, after, before, filterBy, first, last, search, param) {
  return {
          supplierId: supplierId,
          after: after,
          before: before,
          filterBy: filterBy,
          first: first,
          last: last,
          search: search
        };
}

function makeInputObjectInputSupplierOrdersFilter(status, createdAt, issueDate, receptionFinishedAt, shopIds, param) {
  return {
          status: status,
          createdAt: createdAt,
          issueDate: issueDate,
          receptionFinishedAt: receptionFinishedAt,
          shopIds: shopIds
        };
}

function makeInputObjectInFilter(_in, param) {
  return {
          _in: _in
        };
}

function makeInputObjectDateFilter(_after, _before, _between, param) {
  return {
          _after: _after,
          _before: _before,
          _between: _between
        };
}

var Query_inner = {
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  serializeInputObjectInputSupplierOrdersFilter: serializeInputObjectInputSupplierOrdersFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter,
  serializeInputObjectDateFilter: serializeInputObjectDateFilter,
  makeVariables: makeVariables$1,
  makeInputObjectInputSupplierOrdersFilter: makeInputObjectInputSupplierOrdersFilter,
  makeInputObjectInFilter: makeInputObjectInFilter,
  makeInputObjectDateFilter: makeInputObjectDateFilter
};

var include$1 = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query$1,
      Raw: Raw$1,
      parse: parse$1,
      serialize: serialize$1,
      serializeVariables: serializeVariables$1
    });

var use$1 = include$1.use;

var Query_refetchQueryDescription = include$1.refetchQueryDescription;

var Query_useLazy = include$1.useLazy;

var Query_useLazyWithVariables = include$1.useLazyWithVariables;

var Query = {
  Query_inner: Query_inner,
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  serializeInputObjectInputSupplierOrdersFilter: serializeInputObjectInputSupplierOrdersFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter,
  serializeInputObjectDateFilter: serializeInputObjectDateFilter,
  makeVariables: makeVariables$1,
  makeInputObjectInputSupplierOrdersFilter: makeInputObjectInputSupplierOrdersFilter,
  makeInputObjectInFilter: makeInputObjectInFilter,
  makeInputObjectDateFilter: makeInputObjectDateFilter,
  refetchQueryDescription: Query_refetchQueryDescription,
  use: use$1,
  useLazy: Query_useLazy,
  useLazyWithVariables: Query_useLazyWithVariables
};

var statuses = [
  "DRAFT",
  "FINALIZED",
  "ACCEPTED",
  "RECEIVING",
  "RECEIVED",
  "TO_PAY",
  "PAID",
  "ARCHIVED"
];

function encoder(param) {
  return [
          Belt_Option.map(param.status, OrderStatus$Pos.toString),
          Belt_Option.map(param.issueDateRange, (function (param) {
                  return [
                          param[0].valueOf(),
                          param[1].valueOf()
                        ];
                })),
          Belt_Option.map(param.receptionDateRange, (function (param) {
                  return [
                          param[0].valueOf(),
                          param[1].valueOf()
                        ];
                }))
        ];
}

function decoder(param) {
  return {
          TAG: /* Ok */0,
          _0: {
            status: Belt_Option.map(param[0], OrderStatus$Pos.fromStringExn),
            issueDateRange: Belt_Option.flatMap(param[1], (function (range) {
                    if (range.length !== 2) {
                      return ;
                    }
                    var start = range[0];
                    var end = range[1];
                    return [
                            new Date(start),
                            new Date(end)
                          ];
                  })),
            receptionDateRange: Belt_Option.flatMap(param[2], (function (range) {
                    if (range.length !== 2) {
                      return ;
                    }
                    var start = range[0];
                    var end = range[1];
                    return [
                            new Date(start),
                            new Date(end)
                          ];
                  }))
          }
        };
}

function useJsonCodec(param) {
  return JsonCodec$Pos.object3(encoder, decoder, JsonCodec$Pos.optional(JsonCodec$Pos.field("status", JsonCodec$Pos.string)), JsonCodec$Pos.optional(JsonCodec$Pos.field("issueDateRange", JsonCodec$Pos.array(JsonCodec$Pos.$$float))), JsonCodec$Pos.optional(JsonCodec$Pos.field("receptionDateRange", JsonCodec$Pos.array(JsonCodec$Pos.$$float))));
}

var Filters = {
  statuses: statuses,
  encoder: encoder,
  decoder: decoder,
  useJsonCodec: useJsonCodec
};

var Row = {};

function SupplierOrderListPage$SupplierOrderReceptionQuantityCell(Props) {
  var statuses = Props.statuses;
  var totalProductsQuantity = Props.totalProductsQuantity;
  var totalProductsExpectedQuantity = Props.totalProductsExpectedQuantity;
  return React.createElement(Inline$Pos.make, {
              children: null
            }, React.createElement(TextStyle$Pos.make, {
                  children: OrderStatus$Pos.has(statuses, "RECEIVING") || OrderStatus$Pos.has(statuses, "RECEIVED") || OrderStatus$Pos.has(statuses, "ARCHIVED") ? String(totalProductsQuantity) : "-",
                  weight: "semibold"
                }), React.createElement(TextStyle$Pos.make, {
                  children: " / " + String(totalProductsExpectedQuantity)
                }));
}

var SupplierOrderReceptionQuantityCell = {
  make: SupplierOrderListPage$SupplierOrderReceptionQuantityCell
};

function makeQueryVariables(defaultQueryVariables, connectionArguments, search, filterBy, param) {
  return {
          supplierId: defaultQueryVariables.supplierId,
          after: connectionArguments.after,
          before: connectionArguments.before,
          filterBy: filterBy,
          first: connectionArguments.first,
          last: connectionArguments.last,
          search: search
        };
}

function makeQueryVariablesFilterBy(param) {
  var receptionDateRange = param.receptionDateRange;
  var issueDateRange = param.issueDateRange;
  var status = Belt_Option.map(param.status, OrderStatus$Pos.toString);
  return {
          status: status !== undefined ? ({
                _in: [status]
              }) : undefined,
          createdAt: undefined,
          issueDate: issueDateRange !== undefined ? ({
                _after: undefined,
                _before: undefined,
                _between: [
                  Scalar$Pos.Datetime.serialize(issueDateRange[0]),
                  Scalar$Pos.Datetime.serialize(issueDateRange[1])
                ]
              }) : undefined,
          receptionFinishedAt: receptionDateRange !== undefined ? ({
                _after: undefined,
                _before: undefined,
                _between: [
                  Scalar$Pos.Datetime.serialize(receptionDateRange[0]),
                  Scalar$Pos.Datetime.serialize(receptionDateRange[1])
                ]
              }) : undefined,
          shopIds: undefined
        };
}

function totalCountFromQueryData(param) {
  return Belt_Option.getWithDefault(Belt_Option.map(param.supplier, (function (param) {
                    return param.orders.totalCount;
                  })), 0);
}

function cursorsFromQueryData(param) {
  var supplier = param.supplier;
  return [
          Belt_Option.flatMap(supplier, (function (param) {
                  return param.orders.pageInfo.startCursor;
                })),
          Belt_Option.flatMap(supplier, (function (param) {
                  return param.orders.pageInfo.endCursor;
                }))
        ];
}

function rowsFromQueryDataAndState(param, param$1) {
  var supplier = param.supplier;
  if (supplier !== undefined) {
    return Belt_Array.map(supplier.orders.edges, (function (edge) {
                  return {
                          id: edge.node.id,
                          supplierId: supplier.id,
                          formattedName: edge.node.formattedName,
                          shopName: edge.node.shopName,
                          issueDate: edge.node.issueDate,
                          receptionDate: edge.node.receptionFinishedAt,
                          statuses: edge.node.formattedStatus,
                          totalAmountExcludingTaxes: edge.node.totalAmountExcludingTaxes,
                          totalAmountIncludingTaxes: edge.node.totalAmountIncludingTaxes,
                          totalProductsQuantity: edge.node.totalProductsQuantity,
                          totalProductsExpectedQuantity: edge.node.totalProductsExpectedQuantity
                        };
                }));
  } else {
    return [];
  }
}

function keyExtractor(param) {
  return param.id;
}

var Scaffolded = Scaffold$Pos.Make({
      useFiltersJsonCodec: useJsonCodec,
      QueryInner: Query_inner,
      useQuery: use$1,
      makeQueryVariables: makeQueryVariables,
      makeQueryVariablesFilterBy: makeQueryVariablesFilterBy,
      cursorsFromQueryData: cursorsFromQueryData,
      totalCountFromQueryData: totalCountFromQueryData,
      rowsFromQueryDataAndState: rowsFromQueryDataAndState,
      keyExtractor: keyExtractor
    });

function SupplierOrderListPage(Props) {
  var id = Props.id;
  var match = Curry.app(use, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        {
          id: id
        }
      ]);
  var match$1 = match.data;
  var match$2;
  var exit = 0;
  if (match$1 !== undefined) {
    var supplier = match$1.supplier;
    if (supplier !== undefined) {
      match$2 = [
        supplier.companyName,
        supplier.shop.id
      ];
    } else {
      exit = 1;
    }
  } else {
    exit = 1;
  }
  if (exit === 1) {
    match$2 = match.error !== undefined ? [
        undefined,
        undefined
      ] : [
        Intl$Pos.t("Loading") + "...",
        undefined
      ];
  }
  var supplierShopId = match$2[1];
  var pageSubtitle = match$2[0];
  var initialState = Curry._1(Scaffolded.makeInitialState, {
        status: undefined,
        issueDateRange: undefined,
        receptionDateRange: undefined
      });
  var match$3 = Curry._1(Scaffolded.use, (function (param) {
          return initialState;
        }));
  var dispatch = match$3[1];
  var state = match$3[0];
  var defaultQueryVariables = makeVariables$1(id, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
  var defaultItem_label = Intl$Pos.t("Not archived");
  var defaultItem = {
    key: "default",
    label: defaultItem_label
  };
  var items = Belt_Array.map(statuses, (function (status) {
          return {
                  key: OrderStatus$Pos.toString(status),
                  value: status,
                  label: OrderStatus$Pos.toLabel(status)
                };
        }));
  var tmp = {
    label: Intl$Pos.t("Issue date"),
    placeholder: Intl$Pos.t("Since the beginning"),
    onChange: (function (issueDateRange) {
        Curry._1(dispatch, {
              TAG: /* FiltersUpdated */2,
              _0: (function (prev) {
                  return {
                          status: prev.status,
                          issueDateRange: issueDateRange,
                          receptionDateRange: prev.receptionDateRange
                        };
                })
            });
      })
  };
  if (state.filters.issueDateRange !== undefined) {
    tmp.value = Caml_option.valFromOption(state.filters.issueDateRange);
  }
  var tmp$1 = {
    label: Intl$Pos.t("Reception date"),
    placeholder: Intl$Pos.t("Since the beginning"),
    onChange: (function (receptionDateRange) {
        Curry._1(dispatch, {
              TAG: /* FiltersUpdated */2,
              _0: (function (prev) {
                  return {
                          status: prev.status,
                          issueDateRange: prev.issueDateRange,
                          receptionDateRange: receptionDateRange
                        };
                })
            });
      })
  };
  if (state.filters.receptionDateRange !== undefined) {
    tmp$1.value = Caml_option.valFromOption(state.filters.receptionDateRange);
  }
  var match$4 = state.filters;
  var tmp$2;
  var exit$1 = 0;
  if (match$4.status !== undefined || match$4.issueDateRange !== undefined || match$4.receptionDateRange !== undefined) {
    exit$1 = 1;
  } else {
    tmp$2 = null;
  }
  if (exit$1 === 1) {
    tmp$2 = React.createElement(Scaffold$Pos.ResetFiltersButton.make, {
          onPress: (function (param) {
              Curry._1(dispatch, {
                    TAG: /* FiltersUpdated */2,
                    _0: (function (param) {
                        return initialState.filters;
                      })
                  });
            })
        });
  }
  var filters = React.createElement(Inline$Pos.make, {
        children: null,
        space: "small"
      }, React.createElement(Select$Pos.make, {
            label: Intl$Pos.t("Status"),
            sections: [{
                items: Belt_Array.concat([defaultItem], items),
                title: Intl$Pos.t("Statuses")
              }],
            preset: "filter",
            value: state.filters.status,
            onChange: (function (status) {
                Curry._1(dispatch, {
                      TAG: /* FiltersUpdated */2,
                      _0: (function (prev) {
                          return {
                                  status: status,
                                  issueDateRange: prev.issueDateRange,
                                  receptionDateRange: prev.receptionDateRange
                                };
                        })
                    });
              })
          }), React.createElement(SelectDateRangeFilter$Pos.make, tmp), React.createElement(SelectDateRangeFilter$Pos.make, tmp$1), tmp$2);
  var createOrderPathname = LegacyRouter$Pos.routeToPathname(/* Order */0) + "/create";
  var createOrderStateJson = supplierShopId !== undefined ? Caml_option.some(OrderUrlQueryString$Pos.CreateOrder.encode({
              supplierId: id,
              shopId: supplierShopId
            })) : undefined;
  var actions = createOrderStateJson !== undefined ? React.createElement(ButtonLink$Pos.make, {
          children: Intl$Pos.t("Create order"),
          variation: "primary",
          to: {
            TAG: /* RouteWithQueryString */1,
            _0: createOrderPathname,
            _1: Caml_option.valFromOption(createOrderStateJson)
          }
        }) : null;
  var tmp$3 = {
    placeholder: Intl$Pos.t("Search an order"),
    onChange: (function (searchQuery) {
        Curry._1(dispatch, {
              TAG: /* Searched */1,
              _0: searchQuery
            });
      })
  };
  if (state.searchQuery !== undefined) {
    tmp$3.value = state.searchQuery;
  }
  var searchBar = React.createElement(SearchBar$Pos.make, tmp$3);
  var columns = [
    {
      name: Intl$Pos.t("Number"),
      layout: {
        minWidth: {
          NAME: "px",
          VAL: 160
        },
        width: {
          NAME: "pct",
          VAL: 20
        },
        margin: "normal",
        sticky: true
      },
      render: (function (param) {
          return React.createElement(OrderNameTableCell$Pos.make, {
                      value: param.formattedName,
                      shopName: param.shopName,
                      id: param.id
                    });
        })
    },
    {
      name: Intl$Pos.t("Amount VAT excl."),
      layout: {
        minWidth: {
          NAME: "px",
          VAL: 110
        },
        sticky: true
      },
      render: (function (param) {
          return React.createElement(AmountTableCell$Pos.make, {
                      value: param.totalAmountExcludingTaxes,
                      secondaryValue: param.totalAmountIncludingTaxes,
                      decimalPrecision: 3
                    });
        })
    },
    {
      name: Intl$Pos.t("Issue date"),
      layout: {
        minWidth: {
          NAME: "px",
          VAL: 110
        }
      },
      render: (function (param) {
          return React.createElement(TableDateCell$Pos.make, {
                      value: Caml_option.some(param.issueDate)
                    });
        })
    },
    {
      name: Intl$Pos.t("Reception date"),
      layout: {
        minWidth: {
          NAME: "px",
          VAL: 110
        }
      },
      render: (function (param) {
          return React.createElement(TableDateCell$Pos.make, {
                      value: param.receptionDate
                    });
        })
    },
    {
      name: Intl$Pos.t("Rec. qt"),
      layout: {
        minWidth: {
          NAME: "px",
          VAL: 100
        }
      },
      render: (function (param) {
          return React.createElement(SupplierOrderListPage$SupplierOrderReceptionQuantityCell, {
                      statuses: param.statuses,
                      totalProductsQuantity: param.totalProductsQuantity,
                      totalProductsExpectedQuantity: param.totalProductsExpectedQuantity
                    });
        })
    },
    {
      name: Intl$Pos.t("Status"),
      layout: {
        minWidth: {
          NAME: "px",
          VAL: 100
        },
        width: {
          NAME: "fr",
          VAL: 1.3
        }
      },
      render: (function (param) {
          return React.createElement(OrderStatusBadges$Pos.make, {
                      statuses: param.statuses
                    });
        })
    },
    {
      layout: {
        minWidth: {
          NAME: "px",
          VAL: 70
        },
        width: {
          NAME: "px",
          VAL: 70
        }
      },
      render: (function (param) {
          return React.createElement(OrderTableActions$Pos.make, {
                      id: param.id,
                      statuses: param.statuses
                    });
        })
    }
  ];
  var emptyState;
  var exit$2 = 0;
  if (state.currentPage !== 1 || state.searchQuery !== undefined) {
    exit$2 = 1;
  } else {
    var match$5 = state.filters;
    if (match$5.status !== undefined || match$5.issueDateRange !== undefined || match$5.receptionDateRange !== undefined) {
      exit$2 = 1;
    } else {
      emptyState = React.createElement(EmptyState$Pos.make, {
            illustration: Illustration$Pos.create,
            title: Intl$Pos.t("Welcome to the supplier orders space.")
          });
    }
  }
  if (exit$2 === 1) {
    emptyState = React.createElement(EmptyState$Pos.make, {
          illustration: Illustration$Pos.notFound,
          title: Intl$Pos.t("No result were found."),
          text: Intl$Pos.t("Try again with another keyword/filter or:"),
          children: React.createElement(Button$Pos.make, Button$Pos.makeProps(Intl$Pos.t("Clear search query and filters"), undefined, "neutral", undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (param) {
                      Curry._1(dispatch, {
                            TAG: /* Reset */3,
                            _0: initialState
                          });
                    }), undefined, undefined, undefined))
        });
  }
  if (pageSubtitle !== undefined) {
    return React.createElement(Scaffolded.make, {
                title: Intl$Pos.t("Supplier orders"),
                subtitle: pageSubtitle,
                state: state,
                dispatch: dispatch,
                columns: columns,
                filters: filters,
                actions: actions,
                searchBar: searchBar,
                emptyState: emptyState,
                defaultQueryVariables: defaultQueryVariables
              });
  } else {
    return React.createElement(Placeholder$Pos.make, {
                status: /* Error */2
              });
  }
}

var make = React.memo(SupplierOrderListPage);

export {
  ParentQuery ,
  Query ,
  Filters ,
  Row ,
  SupplierOrderReceptionQuantityCell ,
  Scaffolded ,
  make ,
}
/* query Not a pure module */
