// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Auth$Pos from "../../bundles/Auth/Auth.bs.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Page$Pos from "../../resources/layout-and-structure/Page.bs.js";
import * as Stack$Pos from "../../resources/layout-and-structure/Stack.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Column$Pos from "../../resources/layout-and-structure/Column.bs.js";
import * as Scalar$Pos from "../../core/Scalar.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Columns$Pos from "../../resources/layout-and-structure/Columns.bs.js";
import * as Notifier$Pos from "../../bundles/Notifier/Notifier.bs.js";
import * as Navigation$Pos from "../../primitives/Navigation.bs.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";
import * as Placeholder$Pos from "../../resources/layout-and-structure/Placeholder.bs.js";
import * as LegacyRouter$Pos from "../../core/LegacyRouter.bs.js";
import * as PromotionSheet$Pos from "../../modules/Promotion/PromotionSheet.bs.js";
import * as PromotionStatus$Pos from "../../modules/Promotion/PromotionStatus.bs.js";
import * as TriggerDownload$Pos from "../../primitives/TriggerDownload.bs.js";
import * as PromotionEditForm$Pos from "../../modules/Promotion/PromotionEditForm.bs.js";
import * as ReactUpdateEffect$Pos from "../../primitives/ReactUpdateEffect.bs.js";
import * as PromotionStatusBadge$Pos from "../../modules/Promotion/PromotionStatusBadge.bs.js";
import * as PromotionEdit__Queries$Pos from "./PromotionEdit__Queries.bs.js";
import * as ReasonMLCommunity__ApolloClient from "rescript-apollo-client/src/ReasonMLCommunity__ApolloClient.bs.js";
import * as PromotionEditFormPageActions$Pos from "../../modules/Promotion/PromotionEditFormPageActions.bs.js";
import * as PromotionEditFormInformationCard$Pos from "../../modules/Promotion/PromotionEditFormInformationCard.bs.js";
import * as PromotionEditFormManagementTableCard$Pos from "../../modules/Promotion/PromotionEditFormManagementTableCard.bs.js";
import * as PromotionEditDiscountedProductTableCard$Pos from "../../modules/Promotion/PromotionEditDiscountedProductTableCard.bs.js";

var PromotionEditPage_InitialValuesError = /* @__PURE__ */Caml_exceptions.create("PromotionEditPage-Pos.Data.PromotionEditPage_InitialValuesError");

function useInitial(cku) {
  var notifier = Notifier$Pos.use(undefined, undefined);
  var navigate = Navigation$Pos.useNavigate(undefined);
  var apolloClient = ReasonMLCommunity__ApolloClient.React.useApolloClient(undefined);
  var match = Curry.app(PromotionEdit__Queries$Pos.PromotionByCkuQuery.useLazy, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var queryCampaignsResults = match[1];
  var executeCampaignsQuery = match[0];
  var match$1 = React.useState(function () {
        return /* Loading */0;
      });
  var setDiscountsStatus = match$1[1];
  var discountsStatus = match$1[0];
  var match$2 = React.useState(function () {
        return false;
      });
  var setErrored = match$2[1];
  React.useEffect((function () {
          if (cku !== undefined) {
            Curry._3(executeCampaignsQuery, undefined, undefined, {
                  cku: Scalar$Pos.CKU.serialize(cku)
                });
          }
          
        }), [cku]);
  React.useEffect((function () {
          var campaignsData;
          if (queryCampaignsResults.TAG === /* Executed */0) {
            var match = queryCampaignsResults._0;
            var match$1 = match.data;
            if (match$1 !== undefined) {
              campaignsData = match$1.promotionCampaignsByCku;
            } else if (match.error !== undefined) {
              Curry._1(setErrored, (function (param) {
                      return true;
                    }));
              campaignsData = undefined;
            } else {
              campaignsData = undefined;
            }
          } else {
            campaignsData = undefined;
          }
          var match$2 = Belt_Option.flatMap(campaignsData, (function (param) {
                  return Belt_Array.get(param.edges, 0);
                }));
          if (match$2 !== undefined) {
            var campaign = match$2.node;
            var discountsFetch = function (after) {
              return Curry._6(apolloClient.rescript_query, {
                          query: PromotionEdit__Queries$Pos.PromotionDiscountsQuery.query,
                          Raw: PromotionEdit__Queries$Pos.PromotionDiscountsQuery.Raw,
                          parse: PromotionEdit__Queries$Pos.PromotionDiscountsQuery.parse,
                          serialize: PromotionEdit__Queries$Pos.PromotionDiscountsQuery.serialize,
                          serializeVariables: PromotionEdit__Queries$Pos.PromotionDiscountsQuery.serializeVariables
                        }, undefined, undefined, /* NetworkOnly */2, undefined, PromotionEdit__Queries$Pos.PromotionDiscountsQuery.makeVariables(campaign.id, after, PromotionEdit__Queries$Pos.PromotionDiscountsQuery.makeInputObjectInputVariantVariantPricesFilter(PromotionEdit__Queries$Pos.PromotionDiscountsQuery.makeInputObjectStringEqualsFilter(campaign.price.id, undefined), undefined), undefined));
            };
            PromotionEdit__Queries$Pos.runScanDiscounts(discountsFetch, undefined, undefined, (function (result) {
                    Curry._1(setDiscountsStatus, (function (param) {
                            return result;
                          }));
                  }), undefined);
          }
          
        }), [queryCampaignsResults]);
  var data = React.useMemo((function () {
          var exit = 0;
          if (queryCampaignsResults.TAG === /* Executed */0) {
            var match = queryCampaignsResults._0;
            var match$1 = match.data;
            if (match$1 !== undefined) {
              if (typeof discountsStatus !== "number") {
                return {
                        campaigns: match$1.promotionCampaignsByCku.edges,
                        discounts: discountsStatus._0
                      };
              }
              if (match.error === undefined) {
                exit = 2;
              }
              
            } else if (match.error === undefined) {
              exit = 2;
            }
            
          } else {
            exit = 2;
          }
          if (exit === 2) {
            if (typeof discountsStatus !== "number") {
              return ;
            }
            if (discountsStatus === 0) {
              return ;
            }
            
          }
          Curry._1(setErrored, (function (param) {
                  return true;
                }));
        }), [
        queryCampaignsResults,
        discountsStatus
      ]);
  var initialValues = React.useMemo((function () {
          var match = Belt_Option.flatMap(data, (function (data) {
                  return Belt_Array.get(data.campaigns, 0);
                }));
          if (data === undefined) {
            return {
                    discountedProducts: [],
                    name: "",
                    priceName: undefined,
                    period: undefined,
                    rootCampaigns: []
                  };
          }
          if (match !== undefined) {
            var campaign = match.node;
            return {
                    discountedProducts: Belt_Array.map(data.discounts, (function (param) {
                            var discount = param.node;
                            var match = discount.variant.capacityUnit;
                            var match$1 = Belt_Option.getWithDefault(discount.variant.bulk, false);
                            return {
                                    id: discount.variant.id,
                                    cku: discount.variant.cku,
                                    name: discount.variant.formattedName,
                                    description: discount.variant.formattedDescription,
                                    stockKeepingUnit: discount.variant.stockKeepingUnit,
                                    purchasePrice: Belt_Option.getWithDefault(discount.variant.purchasedPrice, 0),
                                    bulkUnit: match !== undefined && match$1 ? match : undefined,
                                    retailPrices: Belt_Array.map(discount.variant.variantPrices.edges, (function (param) {
                                            return {
                                                    name: campaign.price.name,
                                                    value: param.node.valueIncludingTax
                                                  };
                                          })),
                                    discount: {
                                      id: discount.id,
                                      amount: discount.value,
                                      kind: discount.kind
                                    }
                                  };
                          })),
                    name: campaign.name,
                    priceName: campaign.price.name,
                    period: [
                      campaign.startDate,
                      campaign.endDate
                    ],
                    rootCampaigns: Belt_Array.map(data.campaigns, (function (param) {
                            var campaign = param.node;
                            return {
                                    id: campaign.id,
                                    creatorIdentifier: campaign.creatorIdentifier,
                                    shopId: campaign.shop.id,
                                    shopName: campaign.shop.name,
                                    priceId: campaign.price.id,
                                    status: campaign.formattedStatus,
                                    selected: true
                                  };
                          }))
                  };
          }
          throw {
                RE_EXN_ID: PromotionEditPage_InitialValuesError,
                Error: new Error()
              };
        }), [data]);
  var onSubmitSuccess = React.useCallback((function (submissionResponse) {
          if (submissionResponse !== undefined) {
            if (cku !== undefined) {
              return Curry._3(executeCampaignsQuery, undefined, undefined, {
                          cku: Scalar$Pos.CKU.serialize(submissionResponse)
                        });
            } else {
              return Curry._3(navigate, true, undefined, LegacyRouter$Pos.routeToPathname(/* Promotion */3) + ("/" + submissionResponse));
            }
          }
          
        }), [cku]);
  var onSubmitFailure = React.useCallback((function (message) {
          Curry._3(notifier.reset, {
                TAG: /* Error */1,
                _0: message
              }, undefined, undefined);
        }), []);
  var formId = React.useMemo((function () {
          if (Belt_Option.isSome(data)) {
            return cku;
          }
          
        }), [Belt_Option.isSome(data)]);
  var match$3 = Curry._1(PromotionEditForm$Pos.useFormPropState, {
        id: formId,
        initialValues: initialValues,
        schema: PromotionEditForm$Pos.schema,
        onSubmitFailure: onSubmitFailure,
        onSubmitSuccess: onSubmitSuccess
      });
  var dispatch = match$3[1];
  var state = match$3[0];
  ReactUpdateEffect$Pos.use1((function (param) {
          if (Belt_Option.isSome(cku)) {
            Curry._1(dispatch, {
                  TAG: /* IdAndInitialValuesUpdated */6,
                  _0: cku,
                  _1: initialValues
                });
          }
          
        }), [initialValues]);
  if (match$2[0]) {
    return /* Errored */1;
  } else if (cku !== undefined && data === undefined) {
    return /* Loading */0;
  } else {
    return /* Data */{
            _0: {
              form: [
                state,
                dispatch
              ],
              query: data
            }
          };
  }
}

var Data = {
  Queries: undefined,
  PromotionByCkuQuery: undefined,
  PromotionDiscountsQuery: undefined,
  PromotionEditPage_InitialValuesError: PromotionEditPage_InitialValuesError,
  useInitial: useInitial
};

function PromotionEditPage(Props) {
  var cku = Props.cku;
  var canShopKindEdit = Props.canShopKindEdit;
  var initialData = useInitial(cku);
  var notifier = Notifier$Pos.use(undefined, undefined);
  var promotionCreated = Belt_Option.isSome(cku);
  var match = React.useState(function () {
        return !promotionCreated;
      });
  var setEditing = match[1];
  var editing = match[0];
  var match$1 = Auth$Pos.useScope(undefined);
  var adminUser;
  adminUser = match$1.TAG === /* Organisation */0 ? true : false;
  var readOnlyShopType = !canShopKindEdit;
  var match$2 = Auth$Pos.useState(undefined);
  var promotionNotCreatedByUser;
  if (typeof match$2 === "number" || match$2.TAG === /* Logging */0 || typeof initialData === "number") {
    promotionNotCreatedByUser = promotionCreated;
  } else {
    var user = match$2._0.user;
    promotionNotCreatedByUser = Belt_Array.some(initialData._0.form[0].values.rootCampaigns, (function (campaign) {
            if (campaign.creatorIdentifier !== user.id) {
              return campaign.status !== undefined;
            } else {
              return false;
            }
          }));
  }
  var readOnly = !adminUser && (readOnlyShopType || promotionNotCreatedByUser);
  var onRequestProductsUpdate = React.useCallback((function (products) {
          if (typeof initialData === "number") {
            return ;
          }
          var match = initialData._0.form;
          if (Caml_obj.notequal(match[0].values.discountedProducts, products)) {
            return Curry._1(match[1], {
                        TAG: /* FieldValueChanged */2,
                        _0: /* DiscountedProducts */0,
                        _1: (function (param) {
                            return products;
                          })
                      });
          }
          
        }), [initialData]);
  var onRequestShopsUpdate = React.useCallback((function (shops) {
          if (typeof initialData === "number") {
            return ;
          }
          if (!editing) {
            return ;
          }
          var match = initialData._0.form;
          if (Caml_obj.notequal(match[0].values.rootCampaigns, shops)) {
            return Curry._1(match[1], {
                        TAG: /* FieldValueChanged */2,
                        _0: /* RootCampaigns */4,
                        _1: (function (param) {
                            return shops;
                          })
                      });
          }
          
        }), [
        initialData,
        editing
      ]);
  var formattedStatus;
  if (promotionCreated && typeof initialData !== "number") {
    var match$3 = initialData._0.query;
    formattedStatus = match$3 !== undefined ? Belt_Option.mapWithDefault(Belt_Array.get(match$3.campaigns, 0), "DRAFT", (function (param) {
              return param.node.formattedStatus;
            })) : "DRAFT";
  } else {
    formattedStatus = "DRAFT";
  }
  var onRequestCsvDownload = function (promotionCampaign) {
    var discounts = Belt_Array.map(promotionCampaign.discountedProducts, (function (product) {
            return {
                    value: product.discount.amount,
                    kind: product.discount.kind,
                    variantId: product.id,
                    variantStockKeepingUnit: product.stockKeepingUnit
                  };
          }));
    var csvBlob = PromotionSheet$Pos.makeCsvBlob(discounts);
    if (csvBlob.TAG !== /* Ok */0) {
      return Curry._3(notifier.add, {
                  TAG: /* Error */1,
                  _0: "Something went wrong"
                }, undefined, undefined);
    }
    var match = promotionCampaign.period;
    Future.tapError(TriggerDownload$Pos.fromBlob(csvBlob._0, PromotionSheet$Pos.makeCsvFilename(promotionCampaign.name)(match !== undefined ? match[0] : new Date())), (function (param) {
            Curry._3(notifier.add, {
                  TAG: /* Error */1,
                  _0: "Something went wrong"
                }, undefined, undefined);
          }));
  };
  var onRequestExcelDownload = function (promotionCampaign) {
    var discounts = Belt_Array.map(promotionCampaign.discountedProducts, (function (product) {
            return {
                    value: product.discount.amount,
                    kind: product.discount.kind,
                    variantId: product.id,
                    variantStockKeepingUnit: product.stockKeepingUnit
                  };
          }));
    Future.get(PromotionSheet$Pos.makeExcelBlob(discounts), (function (result) {
            if (result.TAG !== /* Ok */0) {
              return Curry._3(notifier.add, {
                          TAG: /* Error */1,
                          _0: "Something went wrong"
                        }, undefined, undefined);
            }
            var match = promotionCampaign.period;
            Future.tapError(TriggerDownload$Pos.fromBlob(result._0, PromotionSheet$Pos.makeExcelFilename(promotionCampaign.name)(match !== undefined ? match[0] : new Date())), (function (param) {
                    Curry._3(notifier.add, {
                          TAG: /* Error */1,
                          _0: "Something went wrong"
                        }, undefined, undefined);
                  }));
          }));
  };
  var renderPageActions = React.useCallback((function (param) {
          if (readOnly) {
            return null;
          } else {
            return React.createElement(PromotionEditFormPageActions$Pos.make, {
                        status: formattedStatus,
                        promotionCreated: promotionCreated,
                        editing: editing,
                        onRequestEditing: (function (value) {
                            Curry._1(setEditing, (function (param) {
                                    return value;
                                  }));
                          }),
                        onRequestCsvDownload: onRequestCsvDownload,
                        onRequestExcelDownload: onRequestExcelDownload
                      });
          }
        }), [
        readOnly,
        formattedStatus,
        promotionCreated,
        editing
      ]);
  var renderPageTitleEnd = React.useCallback((function (param) {
          if (typeof initialData === "number") {
            return null;
          }
          var match = initialData._0.query;
          if (match !== undefined && promotionCreated && !editing) {
            return Belt_Option.mapWithDefault(PromotionStatus$Pos.globalFromStatuses(Belt_Array.map(match.campaigns, (function (param) {
                                  return param.node.formattedStatus;
                                }))), null, (function (globalStatus) {
                          return React.createElement(PromotionStatusBadge$Pos.make, {
                                      status: globalStatus
                                    });
                        }));
          } else {
            return null;
          }
        }), [
        initialData,
        promotionCreated,
        editing
      ]);
  var formattedTitle = promotionCreated ? (
      editing ? (
          typeof initialData === "number" ? "" : Intl$Pos.t("Edition") + " " + Intl$Pos.t("Campaign") + " " + initialData._0.form[0].initialValues.name
        ) : (
          typeof initialData === "number" ? "" : initialData._0.form[0].initialValues.name
        )
    ) : Intl$Pos.t("New promotional campaign");
  var editable = formattedStatus === "DRAFT" ? !readOnly : (
      editing && (formattedStatus === "NOT_PROGRAMMED" || formattedStatus === "PROGRAMMED") ? !readOnly : false
    );
  if (typeof initialData === "number") {
    if (initialData !== 0) {
      return React.createElement(Placeholder$Pos.make, {
                  status: /* Error */2
                });
    } else {
      return React.createElement(Placeholder$Pos.make, {
                  status: /* Loading */0
                });
    }
  }
  var match$4 = initialData._0.form;
  var state = match$4[0];
  var tmp = {
    editing: editing,
    promotionCreated: promotionCreated,
    campaigns: state.values.rootCampaigns,
    selectedPriceName: Belt_Option.getWithDefault(state.values.priceName, ""),
    onRequestShopsUpdate: onRequestShopsUpdate
  };
  if (state.values.priceName !== undefined) {
    tmp.key = state.values.priceName;
  }
  return React.createElement(PromotionEditForm$Pos.Core.Provider.make, {
              children: React.createElement(Page$Pos.make, {
                    children: null,
                    title: formattedTitle,
                    renderTitleEnd: renderPageTitleEnd,
                    renderActions: renderPageActions
                  }, React.createElement(Stack$Pos.make, {
                        children: null,
                        space: "medium"
                      }, React.createElement(Notifier$Pos.Banner.make, {
                            notifier: notifier
                          }), React.createElement(PromotionEditDiscountedProductTableCard$Pos.make, {
                            editable: editable,
                            products: state.values.discountedProducts,
                            selectedPriceName: state.values.priceName,
                            renderPageActions: renderPageActions,
                            onRequestProductsUpdate: onRequestProductsUpdate
                          }), React.createElement(Columns$Pos.make, {
                            children: null,
                            space: "medium"
                          }, React.createElement(Column$Pos.make, {
                                children: React.createElement(PromotionEditFormInformationCard$Pos.make, {
                                      editable: editable,
                                      promotionCreated: promotionCreated
                                    }),
                                width: "one_third"
                              }), React.createElement(Column$Pos.make, {
                                children: React.createElement(PromotionEditFormManagementTableCard$Pos.make, tmp),
                                width: "three_quarter"
                              }))), null),
              propState: [
                state,
                match$4[1]
              ]
            });
}

var make = React.memo(PromotionEditPage);

export {
  Data ,
  make ,
}
/* make Not a pure module */
