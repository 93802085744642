// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Form$Pos from "../../bundles/Form/Form.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Group$Pos from "../../resources/layout-and-structure/Group.bs.js";
import * as Button$Pos from "../../resources/actions/Button.bs.js";
import * as Scalar$Pos from "../../core/Scalar.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Request$Pos from "../../core/Request.bs.js";
import * as Client from "@apollo/client";
import * as Navigation$Pos from "../../primitives/Navigation.bs.js";
import * as AsyncResult$Pos from "../../primitives/AsyncResult.bs.js";
import * as Placeholder$Pos from "../../resources/layout-and-structure/Placeholder.bs.js";
import * as Form__Status$Pos from "../../bundles/Form/Form__Status.bs.js";
import * as ApolloHelpers$Pos from "../../helpers/ApolloHelpers.bs.js";
import * as FieldsetLayoutPanel$Pos from "../../resources/layout-and-structure/FieldsetLayoutPanel.bs.js";
import * as ResourceDetailsPage$Pos from "../../core/ResourceDetailsPage.bs.js";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";
import * as ApolloClient__React_Hooks_UseMutation from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseMutation.bs.js";

function stripEmptyString(string) {
  switch (string) {
    case "" :
    case " " :
        return ;
    default:
      return string;
  }
}

function orEmptyString(value) {
  return Belt_Option.getWithDefault(value, "");
}

var Raw = {};

var query = Client.gql(["fragment SupplierFragment on Supplier   {\n__typename  \nid  \nupdatedAt  \narchivedAt  \ncompanyName  \nintraCommunityVat  \nphoneNumber  \nmobileNumber  \nsiretNumber  \ninternalCode  \nemail  \nnote  \n}\n"]);

function parse(value) {
  var value$1 = value.archivedAt;
  var value$2 = value.intraCommunityVat;
  var value$3 = value.phoneNumber;
  var value$4 = value.mobileNumber;
  var value$5 = value.siretNumber;
  var value$6 = value.internalCode;
  var value$7 = value.email;
  var value$8 = value.note;
  return {
          __typename: value.__typename,
          id: value.id,
          updatedAt: Scalar$Pos.Datetime.parse(value.updatedAt),
          archivedAt: !(value$1 == null) ? Caml_option.some(Scalar$Pos.Datetime.parse(value$1)) : undefined,
          companyName: value.companyName,
          intraCommunityVat: !(value$2 == null) ? value$2 : undefined,
          phoneNumber: !(value$3 == null) ? value$3 : undefined,
          mobileNumber: !(value$4 == null) ? value$4 : undefined,
          siretNumber: !(value$5 == null) ? value$5 : undefined,
          internalCode: !(value$6 == null) ? value$6 : undefined,
          email: !(value$7 == null) ? value$7 : undefined,
          note: !(value$8 == null) ? Scalar$Pos.$$Text.parse(value$8) : undefined
        };
}

function serialize(value) {
  var value$1 = value.note;
  var note = value$1 !== undefined ? Scalar$Pos.$$Text.serialize(value$1) : null;
  var value$2 = value.email;
  var email = value$2 !== undefined ? value$2 : null;
  var value$3 = value.internalCode;
  var internalCode = value$3 !== undefined ? value$3 : null;
  var value$4 = value.siretNumber;
  var siretNumber = value$4 !== undefined ? value$4 : null;
  var value$5 = value.mobileNumber;
  var mobileNumber = value$5 !== undefined ? value$5 : null;
  var value$6 = value.phoneNumber;
  var phoneNumber = value$6 !== undefined ? value$6 : null;
  var value$7 = value.intraCommunityVat;
  var intraCommunityVat = value$7 !== undefined ? value$7 : null;
  var value$8 = value.companyName;
  var value$9 = value.archivedAt;
  var archivedAt = value$9 !== undefined ? Scalar$Pos.Datetime.serialize(Caml_option.valFromOption(value$9)) : null;
  var value$10 = value.updatedAt;
  var value$11 = Scalar$Pos.Datetime.serialize(value$10);
  var value$12 = value.id;
  var value$13 = value.__typename;
  return {
          __typename: value$13,
          id: value$12,
          updatedAt: value$11,
          archivedAt: archivedAt,
          companyName: value$8,
          intraCommunityVat: intraCommunityVat,
          phoneNumber: phoneNumber,
          mobileNumber: mobileNumber,
          siretNumber: siretNumber,
          internalCode: internalCode,
          email: email,
          note: note
        };
}

function verifyArgsAndParse(_SupplierFragment, value) {
  return parse(value);
}

function verifyName(param) {
  
}

var SupplierFragment = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  verifyArgsAndParse: verifyArgsAndParse,
  verifyName: verifyName
};

var Raw$1 = {};

var query$1 = Client.gql([
      "query SupplierQuery($id: ID!)  {\nsupplier(id: $id)  {\n...SupplierFragment   \n}\n\n}\n",
      ""
    ], query);

function parse$1(value) {
  var value$1 = value.supplier;
  return {
          supplier: !(value$1 == null) ? parse(value$1) : undefined
        };
}

function serialize$1(value) {
  var value$1 = value.supplier;
  var supplier = value$1 !== undefined ? serialize(value$1) : null;
  return {
          supplier: supplier
        };
}

function serializeVariables(inp) {
  return {
          id: inp.id
        };
}

function makeVariables(id, param) {
  return {
          id: id
        };
}

var SupplierQuery_inner = {
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query$1,
      Raw: Raw$1,
      parse: parse$1,
      serialize: serialize$1,
      serializeVariables: serializeVariables
    });

var use = include.use;

var SupplierQuery_refetchQueryDescription = include.refetchQueryDescription;

var SupplierQuery_useLazy = include.useLazy;

var SupplierQuery_useLazyWithVariables = include.useLazyWithVariables;

var SupplierQuery = {
  SupplierQuery_inner: SupplierQuery_inner,
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  refetchQueryDescription: SupplierQuery_refetchQueryDescription,
  use: use,
  useLazy: SupplierQuery_useLazy,
  useLazyWithVariables: SupplierQuery_useLazyWithVariables
};

var Raw$2 = {};

var query$2 = Client.gql([
      "mutation updateSupplier($id: ID!, $supplierInput: InputUpdateSupplier!)  {\nupdateSupplier(id: $id, supplierInput: $supplierInput)  {\n...SupplierFragment   \n}\n\n}\n",
      ""
    ], query);

function parse$2(value) {
  return {
          updateSupplier: parse(value.updateSupplier)
        };
}

function serialize$2(value) {
  var value$1 = value.updateSupplier;
  var updateSupplier = serialize(value$1);
  return {
          updateSupplier: updateSupplier
        };
}

function serializeInputObjectInputUpdateSupplier(inp) {
  var a = inp.companyName;
  var a$1 = inp.intraCommunityVat;
  var a$2 = inp.siretNumber;
  var a$3 = inp.phoneNumber;
  var a$4 = inp.mobileNumber;
  var a$5 = inp.email;
  var a$6 = inp.note;
  var a$7 = inp.internalCode;
  return {
          companyName: a !== undefined ? a : undefined,
          intraCommunityVat: a$1 !== undefined ? a$1 : undefined,
          siretNumber: a$2 !== undefined ? a$2 : undefined,
          phoneNumber: a$3 !== undefined ? a$3 : undefined,
          mobileNumber: a$4 !== undefined ? a$4 : undefined,
          email: a$5 !== undefined ? a$5 : undefined,
          note: a$6 !== undefined ? a$6 : undefined,
          internalCode: a$7 !== undefined ? a$7 : undefined
        };
}

function serializeVariables$1(inp) {
  return {
          id: inp.id,
          supplierInput: serializeInputObjectInputUpdateSupplier(inp.supplierInput)
        };
}

function makeVariables$1(id, supplierInput, param) {
  return {
          id: id,
          supplierInput: supplierInput
        };
}

function makeInputObjectInputUpdateSupplier(companyName, intraCommunityVat, siretNumber, phoneNumber, mobileNumber, email, note, internalCode, param) {
  return {
          companyName: companyName,
          intraCommunityVat: intraCommunityVat,
          siretNumber: siretNumber,
          phoneNumber: phoneNumber,
          mobileNumber: mobileNumber,
          email: email,
          note: note,
          internalCode: internalCode
        };
}

var SupplierUpdateMutation_inner = {
  Raw: Raw$2,
  query: query$2,
  parse: parse$2,
  serialize: serialize$2,
  serializeVariables: serializeVariables$1,
  serializeInputObjectInputUpdateSupplier: serializeInputObjectInputUpdateSupplier,
  makeVariables: makeVariables$1,
  makeInputObjectInputUpdateSupplier: makeInputObjectInputUpdateSupplier
};

var include$1 = ApolloClient__React_Hooks_UseMutation.Extend({
      query: query$2,
      Raw: Raw$2,
      parse: parse$2,
      serialize: serialize$2,
      serializeVariables: serializeVariables$1
    });

var use$1 = include$1.use;

var SupplierUpdateMutation_useWithVariables = include$1.useWithVariables;

var SupplierUpdateMutation = {
  SupplierUpdateMutation_inner: SupplierUpdateMutation_inner,
  Raw: Raw$2,
  query: query$2,
  parse: parse$2,
  serialize: serialize$2,
  serializeVariables: serializeVariables$1,
  serializeInputObjectInputUpdateSupplier: serializeInputObjectInputUpdateSupplier,
  makeVariables: makeVariables$1,
  makeInputObjectInputUpdateSupplier: makeInputObjectInputUpdateSupplier,
  use: use$1,
  useWithVariables: SupplierUpdateMutation_useWithVariables
};

function get(values, field) {
  switch (field) {
    case /* CompanyName */0 :
        return values.companyName;
    case /* Email */1 :
        return values.email;
    case /* PhoneNumber */2 :
        return values.phoneNumber;
    case /* MobileNumber */3 :
        return values.mobileNumber;
    case /* IntraCommunityVat */4 :
        return values.intraCommunityVat;
    case /* SiretNumber */5 :
        return values.siretNumber;
    case /* InternalCode */6 :
        return values.internalCode;
    case /* Note */7 :
        return values.note;
    
  }
}

function set(values, field, value) {
  switch (field) {
    case /* CompanyName */0 :
        return {
                companyName: value,
                email: values.email,
                phoneNumber: values.phoneNumber,
                mobileNumber: values.mobileNumber,
                intraCommunityVat: values.intraCommunityVat,
                siretNumber: values.siretNumber,
                internalCode: values.internalCode,
                note: values.note
              };
    case /* Email */1 :
        return {
                companyName: values.companyName,
                email: value,
                phoneNumber: values.phoneNumber,
                mobileNumber: values.mobileNumber,
                intraCommunityVat: values.intraCommunityVat,
                siretNumber: values.siretNumber,
                internalCode: values.internalCode,
                note: values.note
              };
    case /* PhoneNumber */2 :
        return {
                companyName: values.companyName,
                email: values.email,
                phoneNumber: value,
                mobileNumber: values.mobileNumber,
                intraCommunityVat: values.intraCommunityVat,
                siretNumber: values.siretNumber,
                internalCode: values.internalCode,
                note: values.note
              };
    case /* MobileNumber */3 :
        return {
                companyName: values.companyName,
                email: values.email,
                phoneNumber: values.phoneNumber,
                mobileNumber: value,
                intraCommunityVat: values.intraCommunityVat,
                siretNumber: values.siretNumber,
                internalCode: values.internalCode,
                note: values.note
              };
    case /* IntraCommunityVat */4 :
        return {
                companyName: values.companyName,
                email: values.email,
                phoneNumber: values.phoneNumber,
                mobileNumber: values.mobileNumber,
                intraCommunityVat: value,
                siretNumber: values.siretNumber,
                internalCode: values.internalCode,
                note: values.note
              };
    case /* SiretNumber */5 :
        return {
                companyName: values.companyName,
                email: values.email,
                phoneNumber: values.phoneNumber,
                mobileNumber: values.mobileNumber,
                intraCommunityVat: values.intraCommunityVat,
                siretNumber: value,
                internalCode: values.internalCode,
                note: values.note
              };
    case /* InternalCode */6 :
        return {
                companyName: values.companyName,
                email: values.email,
                phoneNumber: values.phoneNumber,
                mobileNumber: values.mobileNumber,
                intraCommunityVat: values.intraCommunityVat,
                siretNumber: values.siretNumber,
                internalCode: value,
                note: values.note
              };
    case /* Note */7 :
        return {
                companyName: values.companyName,
                email: values.email,
                phoneNumber: values.phoneNumber,
                mobileNumber: values.mobileNumber,
                intraCommunityVat: values.intraCommunityVat,
                siretNumber: values.siretNumber,
                internalCode: values.internalCode,
                note: value
              };
    
  }
}

var SupplierEditFormLenses = {
  get: get,
  set: set
};

var SupplierEditForm = Form$Pos.Make(SupplierEditFormLenses);

var supplierEditFormInitialValues = {
  companyName: "",
  email: "",
  phoneNumber: "",
  mobileNumber: "",
  intraCommunityVat: "",
  siretNumber: "",
  internalCode: "",
  note: ""
};

var supplierEditFormSchema = [{
    TAG: /* StringNotEmpty */1,
    _0: /* CompanyName */0
  }];

function supplierEditFormInitialValuesFromQueryResult(queryResult) {
  return {
          companyName: queryResult.companyName,
          email: Belt_Option.getWithDefault(queryResult.email, ""),
          phoneNumber: Belt_Option.getWithDefault(queryResult.phoneNumber, ""),
          mobileNumber: Belt_Option.getWithDefault(queryResult.mobileNumber, ""),
          intraCommunityVat: Belt_Option.getWithDefault(queryResult.intraCommunityVat, ""),
          siretNumber: Belt_Option.getWithDefault(queryResult.siretNumber, ""),
          internalCode: Belt_Option.getWithDefault(queryResult.internalCode, ""),
          note: Belt_Option.getWithDefault(queryResult.note, "")
        };
}

function mutationVariablesFromIdAndFormValues(id, formValues) {
  return {
          id: id,
          supplierInput: makeInputObjectInputUpdateSupplier(formValues.companyName, stripEmptyString(formValues.intraCommunityVat), stripEmptyString(formValues.siretNumber), stripEmptyString(formValues.phoneNumber), stripEmptyString(formValues.mobileNumber), stripEmptyString(formValues.email), stripEmptyString(formValues.note), stripEmptyString(formValues.internalCode), undefined)
        };
}

function SupplierEditPage$SupplierEditFormInformationFieldset(Props) {
  return React.createElement(FieldsetLayoutPanel$Pos.make, {
              title: Intl$Pos.t("General information"),
              description: Intl$Pos.t("Complete the essential information of your new supplier."),
              children: null
            }, React.createElement(SupplierEditForm.InputText.make, {
                  field: /* CompanyName */0,
                  label: Intl$Pos.t("Company name"),
                  placeholder: Intl$Pos.t("Company name")
                }), React.createElement(SupplierEditForm.InputText.make, {
                  field: /* Email */1,
                  label: Intl$Pos.t("Email"),
                  placeholder: Intl$Pos.t("contact@company.com")
                }), React.createElement(Group$Pos.make, {
                  children: null
                }, React.createElement(SupplierEditForm.InputPhone.make, {
                      label: Intl$Pos.t("Home phone"),
                      field: /* PhoneNumber */2,
                      placeholder: "02 11 22 33 44"
                    }), React.createElement(SupplierEditForm.InputPhone.make, {
                      label: Intl$Pos.t("Mobile phone"),
                      field: /* MobileNumber */3,
                      placeholder: "06 11 22 33 44"
                    })), React.createElement(Group$Pos.make, {
                  children: null
                }, React.createElement(SupplierEditForm.InputText.make, {
                      field: /* IntraCommunityVat */4,
                      label: Intl$Pos.t("TVA number"),
                      placeholder: "FR 40 123456789"
                    }), React.createElement(SupplierEditForm.InputText.make, {
                      field: /* SiretNumber */5,
                      label: Intl$Pos.t("Siret"),
                      placeholder: "123 456 789"
                    })), React.createElement(SupplierEditForm.InputText.make, {
                  field: /* InternalCode */6,
                  label: Intl$Pos.t("Internal code"),
                  placeholder: Intl$Pos.t("Internal code")
                }));
}

var SupplierEditFormInformationFieldset = {
  make: SupplierEditPage$SupplierEditFormInformationFieldset
};

function SupplierEditPage$SuppplierEditFormActionsBar(Props) {
  var id = Props.id;
  var match = Curry.app(use$1, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var mutate = match[0];
  var formState = Curry._1(SupplierEditForm.useFormState, undefined);
  var match$1 = Navigation$Pos.useGoBack(undefined);
  var onGoBack = match$1[1];
  return React.createElement(ResourceDetailsPage$Pos.ActionsBar.make, {
              items: [
                Form__Status$Pos.isPristine(formState.status) && match$1[0] ? React.createElement(Button$Pos.make, Button$Pos.makeProps(Intl$Pos.t("Discard"), undefined, "neutral", undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (param) {
                              Curry._1(onGoBack, undefined);
                            }), undefined, undefined, undefined)) : React.createElement(SupplierEditForm.CancelButton.make, {
                        text: Intl$Pos.t("Discard"),
                        size: "medium"
                      }),
                React.createElement(SupplierEditForm.SubmitButton.make, {
                      variation: "success",
                      size: "medium",
                      onSubmit: (function (param, values) {
                          return Future.mapOk(ApolloHelpers$Pos.mutationPromiseToFutureResult(Curry._8(mutate, undefined, undefined, undefined, undefined, undefined, undefined, undefined, mutationVariablesFromIdAndFormValues(id, values))), undefined, (function (param) {
                                        return param.updateSupplier.id;
                                      }));
                        }),
                      text: Intl$Pos.t("Save")
                    })
              ]
            });
}

var SuppplierEditFormActionsBar = {
  make: SupplierEditPage$SuppplierEditFormActionsBar
};

function SupplierEditPage(Props) {
  var id = Props.id;
  var supplierShowRoute = Props.supplierShowRoute;
  var navigate = Navigation$Pos.useNavigate(undefined);
  var asyncQueryResult = AsyncResult$Pos.map(ApolloHelpers$Pos.queryResultToAsyncResult(Curry.app(use, [
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                {
                  id: id
                }
              ])), (function (result) {
          if (result.TAG !== /* Ok */0) {
            return {
                    TAG: /* Error */1,
                    _0: undefined
                  };
          }
          var supplier = result._0.supplier;
          if (supplier !== undefined) {
            return {
                    TAG: /* Ok */0,
                    _0: supplier
                  };
          } else {
            return {
                    TAG: /* Error */1,
                    _0: undefined
                  };
          }
        }));
  var match = React.useState(function () {
        
      });
  var setNotificationError = match[1];
  var notificationError = match[0];
  var onSubmitFailure = function (error) {
    Curry._1(setNotificationError, (function (param) {
            return error;
          }));
  };
  var onSubmitSuccess = function (response) {
    if (response !== undefined) {
      return Curry._3(navigate, true, undefined, Curry._1(supplierShowRoute, response));
    } else {
      return Curry._1(setNotificationError, (function (param) {
                    return Request$Pos.serverErrorMessage;
                  }));
    }
  };
  var formId;
  if (typeof asyncQueryResult === "number") {
    formId = undefined;
  } else if (asyncQueryResult.TAG === /* Reloading */0) {
    var queryResult = asyncQueryResult._0;
    formId = queryResult.TAG === /* Ok */0 ? queryResult._0.id : undefined;
  } else {
    var queryResult$1 = asyncQueryResult._0;
    formId = queryResult$1.TAG === /* Ok */0 ? queryResult$1._0.id : undefined;
  }
  var formInitialValues;
  if (typeof asyncQueryResult === "number") {
    formInitialValues = supplierEditFormInitialValues;
  } else if (asyncQueryResult.TAG === /* Reloading */0) {
    var queryResult$2 = asyncQueryResult._0;
    formInitialValues = queryResult$2.TAG === /* Ok */0 ? supplierEditFormInitialValuesFromQueryResult(queryResult$2._0) : supplierEditFormInitialValues;
  } else {
    var queryResult$3 = asyncQueryResult._0;
    formInitialValues = queryResult$3.TAG === /* Ok */0 ? supplierEditFormInitialValuesFromQueryResult(queryResult$3._0) : supplierEditFormInitialValues;
  }
  var formPropState = Curry._1(SupplierEditForm.useFormPropState, {
        id: formId,
        initialValues: formInitialValues,
        schema: supplierEditFormSchema,
        onSubmitFailure: onSubmitFailure,
        onSubmitSuccess: onSubmitSuccess
      });
  var notificationBanner;
  if (notificationError !== undefined) {
    var onRequestClose = function (param) {
      Curry._1(setNotificationError, (function (param) {
              
            }));
    };
    notificationBanner = React.createElement(ResourceDetailsPage$Pos.NotificationBanner.make, {
          value: {
            TAG: /* Error */1,
            _0: notificationError
          },
          onRequestClose: onRequestClose
        });
  } else {
    notificationBanner = null;
  }
  if (typeof asyncQueryResult !== "number" && asyncQueryResult.TAG === /* Done */1) {
    var queryResult$4 = asyncQueryResult._0;
    if (queryResult$4.TAG !== /* Ok */0) {
      return React.createElement(Placeholder$Pos.make, {
                  status: /* Error */2
                });
    }
    var actionsBar = React.createElement(SupplierEditPage$SuppplierEditFormActionsBar, {
          id: queryResult$4._0.id
        });
    return React.createElement(SupplierEditForm.Core.Provider.make, {
                children: React.createElement(ResourceDetailsPage$Pos.make, {
                      title: Intl$Pos.t("Edition supplier"),
                      subtitle: formPropState[0].values.companyName,
                      actionsBar: actionsBar,
                      notificationBanner: notificationBanner,
                      children: React.createElement(SupplierEditPage$SupplierEditFormInformationFieldset, {})
                    }),
                propState: formPropState
              });
  }
  return React.createElement(Placeholder$Pos.make, {
              status: /* Loading */0
            });
}

var make = SupplierEditPage;

export {
  stripEmptyString ,
  orEmptyString ,
  SupplierFragment ,
  SupplierQuery ,
  SupplierUpdateMutation ,
  SupplierEditFormLenses ,
  SupplierEditForm ,
  supplierEditFormInitialValues ,
  supplierEditFormSchema ,
  supplierEditFormInitialValuesFromQueryResult ,
  mutationVariablesFromIdAndFormValues ,
  SupplierEditFormInformationFieldset ,
  SuppplierEditFormActionsBar ,
  make ,
}
/* query Not a pure module */
