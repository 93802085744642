// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Box$Pos from "../../resources/layout-and-structure/Box.bs.js";
import * as Env$Pos from "../../core/Env.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Auth$Pos from "../../bundles/Auth/Auth.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Json$Pos from "../../primitives/Json.bs.js";
import * as View$Pos from "../../primitives/View.bs.js";
import * as Style$Pos from "../../primitives/Style.bs.js";
import * as Title$Pos from "../../resources/typography/Title.bs.js";
import * as Banner$Pos from "../../resources/feedback-indicators/Banner.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Button$Pos from "../../resources/actions/Button.bs.js";
import * as Spaces$Pos from "../../resources/theme/Spaces.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Request$Pos from "../../core/Request.bs.js";
import * as Spinner$Pos from "../../resources/feedback-indicators/Spinner.bs.js";
import * as AuthRoutes$Pos from "./AuthRoutes.bs.js";
import * as Navigation$Pos from "../../primitives/Navigation.bs.js";
import * as StyleSheet$Pos from "../../primitives/StyleSheet.bs.js";

var styles = StyleSheet$Pos.create({
      container: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, 1, undefined, "column", undefined, undefined, undefined, undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.pct(-10)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(465)), undefined, undefined),
      title: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(Spaces$Pos.large)), undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.pct(100)), undefined, undefined)
    });

var endpoint = Env$Pos.gatewayUrl(undefined) + "/auth/username";

function encodeBody(tokenId, token, userId, username) {
  return Json$Pos.encodeDict(Js_dict.fromArray([
                  [
                    "token",
                    Json$Pos.encodeString(token)
                  ],
                  [
                    "tokenId",
                    Json$Pos.encodeString(tokenId)
                  ],
                  [
                    "userId",
                    Json$Pos.encodeString(userId)
                  ],
                  [
                    "username",
                    Json$Pos.encodeString(username)
                  ]
                ]));
}

function decodeInvalidRequestFailure(error) {
  switch (error.kind) {
    case "DuplicateUserUsername" :
        return /* DuplicateUserUsername */2;
    case "ExpiredOneTimeToken" :
        return /* ExpiredOneTimeToken */0;
    case "NotFoundUsernameUpdateOneTimeToken" :
        return /* NotFoundUsernameUpdateOneTimeToken */1;
    default:
      return /* UnknownServerFailure */3;
  }
}

function make(token, tokenId, userId, username) {
  return Future.mapError(Request$Pos.make("PATCH", Caml_option.some(encodeBody(tokenId, token, userId, username)), false, undefined, endpoint), undefined, (function (error) {
                if (typeof error === "number" || error.TAG !== /* InvalidRequestFailures */2) {
                  return ;
                } else {
                  return Belt_Option.map(Belt_Array.get(error._0, 0), decodeInvalidRequestFailure);
                }
              }));
}

var AuthUsernameUpdateRequest = {
  endpoint: endpoint,
  encodeBody: encodeBody,
  decodeInvalidRequestFailure: decodeInvalidRequestFailure,
  make: make
};

function AuthUsernameUpdatePage$NotificationBanner(Props) {
  var notification = Props.notification;
  var onRequestClose = Props.onRequestClose;
  return React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(Banner$Pos.make, {
                          textStatus: notification,
                          onRequestClose: onRequestClose
                        })), undefined, undefined, "medium", undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.pct(100)), undefined, undefined)), undefined, undefined, undefined));
}

var make$1 = React.memo(AuthUsernameUpdatePage$NotificationBanner);

var NotificationBanner = {
  make: make$1
};

function AuthUsernameUpdatePage(Props) {
  var userId = Props.userId;
  var tokenId = Props.tokenId;
  var token = Props.token;
  var username = Props.username;
  var usernameUpdateRequest = Props.usernameUpdateRequest;
  var navigate = Navigation$Pos.useNavigate(undefined);
  var auth = Auth$Pos.useState(undefined);
  var unlogUser = Auth$Pos.useUnlogUser(undefined);
  var match = React.useState(function () {
        
      });
  var setNotfication = match[1];
  var notification = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setRedirectToLogin = match$1[1];
  var redirectToLogin = match$1[0];
  React.useEffect((function () {
          Future.get(Curry._4(usernameUpdateRequest, token, tokenId, userId, username), (function (result) {
                  if (result.TAG === /* Ok */0) {
                    return Curry._1(setRedirectToLogin, (function (param) {
                                  return true;
                                }));
                  }
                  var match = result._0;
                  if (match !== undefined) {
                    if (match === 2) {
                      return Curry._1(setNotfication, (function (param) {
                                    return {
                                            TAG: /* Danger */1,
                                            _0: Intl$Pos.t("This email address is already used.")
                                          };
                                  }));
                    }
                    if (match < 3) {
                      return Curry._1(setNotfication, (function (param) {
                                    return {
                                            TAG: /* Danger */1,
                                            _0: Intl$Pos.t("The link has expired")
                                          };
                                  }));
                    }
                    
                  }
                  var danger = Intl$Pos.t("An unexpected error occured. Please try again or contact the support.");
                  Curry._1(setNotfication, (function (param) {
                          return {
                                  TAG: /* Danger */1,
                                  _0: danger
                                };
                        }));
                }));
        }), []);
  React.useEffect((function () {
          if (redirectToLogin) {
            if (typeof auth === "number") {
              Curry._3(navigate, undefined, undefined, AuthRoutes$Pos.loginUsernameUpdateSuccessRoute + "/" + username + "/");
            } else if (auth.TAG !== /* Logging */0) {
              Curry._1(unlogUser, undefined);
              Curry._3(navigate, undefined, undefined, AuthRoutes$Pos.loginUsernameUpdateSuccessRoute + "/" + username);
            }
            
          }
          
        }), [
        redirectToLogin,
        auth
      ]);
  var onRequestCloseNotificationBanner = function (param) {
    Curry._1(setNotfication, (function (param) {
            
          }));
  };
  var onPressBackButton = function (param) {
    Curry._3(navigate, undefined, undefined, AuthRoutes$Pos.baseRoute);
  };
  return React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(null), Caml_option.some(styles.container), undefined, undefined, undefined), React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(React.createElement(Title$Pos.make, {
                              children: Intl$Pos.t("Email update"),
                              level: 1
                            })), Caml_option.some(styles.title), undefined, undefined, undefined)), notification !== undefined ? React.createElement(make$1, {
                    notification: notification,
                    onRequestClose: onRequestCloseNotificationBanner
                  }) : React.createElement(Spinner$Pos.make, {
                    size: 18
                  }), React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(Button$Pos.make, Button$Pos.makeProps(Intl$Pos.t("Back"), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, onPressBackButton, undefined, undefined, undefined))), undefined, undefined, "xxlarge", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)));
}

var usernameUpdateRequest = make;

var make$2 = AuthUsernameUpdatePage;

export {
  styles ,
  AuthUsernameUpdateRequest ,
  usernameUpdateRequest ,
  NotificationBanner ,
  make$2 as make,
}
/* styles Not a pure module */
